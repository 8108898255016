import {useState} from 'react';
import {Drawer, Backdrop, Box, Card, TextField, Button, Divider, Alert, Typography, FormHelperText, styled} from '@mui/material';
import {DeleteForeverRounded} from '@mui/icons-material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as yup from 'yup';
import {date} from 'yup'
import {Formik} from 'formik';
import dayjs from "dayjs";
import {useTranslation} from 'react-i18next';

import LoadingIcon from 'src/components/Loading/loadingIcon';
import {putHolidayCalendars} from 'src/api';


const CardActionsWrapper = styled(Card)(
  ({ theme }) => `
     box-shadow: none;
     margin: 0 ${theme.spacing(3)};
`
);

const DatePickerWrapper = styled(Box)(
  ({ theme }) => `
  .error fieldset, .MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    border: 1px solid ${theme.palette.error.main} !important;
  }
`
);

const validationSchema = yup.object().shape({
  allDay: yup.bool(),
  description: yup.string().max(5000),
  start: yup.lazy(() => date('Must be a valid date')
    .min(new Date(), "Date entered should be after today's date.").required().nullable()
  ),
  title: yup.string().max(30).required('The title field is required')
});

function EventDrawer({carrierId, calendarId, calendarName, event, events, isOpen, close, showAlert, refetchCalendar}) {
  const [isLoading, setIsLoading] = useState(false);
  const [genericError, setGenericError] = useState(false);

  const getInitialValues = (event) => {
    if (event?.start) {
      const eventDate = new Date(event.start).toString();
      return _.merge(
        {},
        {
          allDay: true,
          start: eventDate,
          title: '',
          submit: null
        },
        event
      );
    }

    if (event?.date) {
      return _.merge(
        {},
        {
          allDay: true,
          start: event.date,
          title: event.title,
          submit: null
        },
        event
      );
    }

    return {
      allDay: true,
      start: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      title: '',
      submit: null
    };
  };

  const putCalendar = async function (carrierId, payload, message) {
    try {
      setGenericError(false);
      setIsLoading(true);
      const results = await putHolidayCalendars(carrierId, payload);
      if (results.error) {
        setGenericError(true);
      }
      if (results?.calendar) {
        await refetchCalendar();
        close();
        showAlert(message);
      }
      setIsLoading(false);
    }
    catch (e) {
      setGenericError(true);
      setIsLoading(false);
    }
  };

  const reformatCalendar = (calendar) => {
    const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });

    const _reformattedCalendar = Object.fromEntries(
      calendar?.map(item => {
        const _date = formatter.format(item?.start);
        return [_date, item?.title]
      })
    );
    return _reformattedCalendar;
  }

  const createEvent = (data) => {
    const newDate = new Date(dayjs(data?.start));
    const newLocalCalendar = [...events, { id: data?.id, start: newDate, title: data?.title }];
    const reformattedCalendar = reformatCalendar(newLocalCalendar);
    const payload = {id: calendarId, name: calendarName, calendar: reformattedCalendar};

    const date = data?.start?.$d || data.start;
    const displayDate = date.toLocaleDateString();
    const message = `${data?.title} on ${displayDate} added successfully!`;

    putCalendar(carrierId, payload, message);
  }

  const updateEvent = (data) => {
    const date = data?.start?.$d || data.start;
    let modifiedEvents = [...events];
    modifiedEvents[event.id] = {id: Number(event?.id), start: date, title: data?.title};
    const reformattedCalendar = reformatCalendar(modifiedEvents);
    const payload = {id: calendarId, name: calendarName, calendar: reformattedCalendar};

    const displayDate = date.toLocaleDateString();
    const message = `${data?.title} on ${displayDate} modified successfully!`;
    
    putCalendar(carrierId, payload, message);
  }

  const deleteEvent = () => {
    const filteredCalendar = events.filter((item) => (item.id.toString() !== event.id));
    const reformattedCalendar = reformatCalendar(filteredCalendar);
    const payload = {id: calendarId, name: calendarName, calendar: reformattedCalendar};

    const date = event.date.toLocaleDateString();
    const message = `${event?.title} on ${date} deleted successfully!`;

    putCalendar(carrierId, payload, message);
  }

  async function onSubmit(values, {setErrors, setStatus, setSubmitting}) {
    try {
      const data = {
        id: (events?.length || 0),
        start: values?.start,
        title: values?.title
      };

      if (event?.title) {
        updateEvent(data);
      } else {
        createEvent(data);
      }
      setStatus({ success: true });
      setSubmitting(false);
    } catch (err) {
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  }

  const isCreating = !event?.title;

  const {t} = useTranslation();

  return (
    <>
      <Backdrop
        sx={{color: '#fff', zIndex: 999, opacity: '0.5 !important'}}
        open={isOpen}
      />
      <Drawer
        anchor='right'
        onClose={close}
        open={isOpen}
        elevation={9}
      >
        <Formik
          initialValues={getInitialValues(event)}
          validationSchema={validationSchema}
          validateOnMount
          validateOnChange
          onSubmit={onSubmit}
        >
          {({errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values}) => (
            <form onSubmit={handleSubmit}>
              <Box p={3}>
                <Typography variant="h4">
                  {isCreating
                    ? t('Add New Event')
                    : t('Edit Calendar Event')}
                </Typography>
              </Box>
              <Divider />
              <Box px={3} py={2} sx={{ minWidth: '660px' }}>
                <Alert className={genericError ? 'show' : 'hide'} severity="error" sx={{ mb: 2, maxWidth: '600px' }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
                {isLoading ? <LoadingIcon contained /> :
                  <>
                    <TextField
                      sx={{ width: '600px' }}
                      error={Boolean(touched.title && values.title.length < 1)}
                      helperText={touched.title && values.title.length < 1 ? errors.title : '30 character limit'}
                      label={t('Event Title')}
                      name="title"
                      margin="normal"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      inputProps={{ maxLength: 30 }}
                      variant="outlined"
                    />
                    <DatePickerWrapper>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{ width: '600px', borderRadius: '12px' }}
                          className={errors.start ? 'error' : ''}
                          value={dayjs(values.start)}
                          onChange={(date) => setFieldValue('start', date)}
                          label={t('Date')}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="normal"
                              variant="outlined"
                              fullWidth
                              name="start"
                            />
                          )}
                        />
                        {errors.start && <FormHelperText error>{errors.start !== "start must be a `date` type, but the final value was: `Invalid Date` (cast from the value `null`)." ? errors.start : 'This field is required.'}</FormHelperText>}
                      </LocalizationProvider>
                    </DatePickerWrapper>

                    {Boolean(touched.end && errors.end) && (
                      <Alert
                        sx={{
                          mt: 2,
                          mb: 1
                        }}
                        severity="error"
                      >
                        {errors.end}
                      </Alert>
                    )}
                  </>
                }
              </Box>
              <CardActionsWrapper
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  py: 2
                }}
              >
                <Box>
                  {!isCreating && !isLoading && (
                    <Button
                      variant="outlined"
                      sx={{
                        mr: 1
                      }}
                      color="error"
                      onClick={() => deleteEvent()}
                      startIcon={<DeleteForeverRounded />}
                    >Delete Event</Button>
                  )}
                </Box>
                {!isLoading &&
                  <Box>
                    <Button
                      variant="outlined"
                      sx={{
                        mr: 2
                      }}
                      color="secondary"
                      onClick={close}
                    >
                      {t('Cancel')}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                    >
                      {isCreating ? t('Add event') : t('Update Event')}
                    </Button>
                  </Box>
                }
              </CardActionsWrapper>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

EventDrawer.propTypes = {
  carrierId: PropTypes.string,
  calendarId: PropTypes.string,
  calendarName: PropTypes.string,
  event: PropTypes.object,
  events: PropTypes.array,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  showAlert: PropTypes.func,
  refetchCalendar: PropTypes.func
};

export default EventDrawer;
