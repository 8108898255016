import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';

import {switchFreqDisplay} from 'src/utilities/switchFrequencyDisplay';
import NotificationEmails from './notificationEmails';


function DeathListCollapsibleContent({rowData}) {
  return (
    <Box p={2}>
      <Typography variant='subtitle1' mb={1}>
        Verified Deaths List Details
      </Typography>
      <Typography variant='caption'>
        Added {rowData?.auditDate} by {rowData?.auditUser}
      </Typography>
      <Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', mt: 0.5, mb: 0}}>
        <Typography variant='subtitle1'>
          Transfer Schedule: <Typography sx={{textTransform: 'capitalize'}} display='inline'>{switchFreqDisplay(rowData?.transfer?.frequency)} {rowData?.frequencyDay}</Typography>
        </Typography>
      </Box>
      <NotificationEmails emails={rowData?.notificationEmails}/>
    </Box>
  );
}

DeathListCollapsibleContent.propTypes = {
  rowData: PropTypes.object
};

export default DeathListCollapsibleContent;
