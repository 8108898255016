import {useState} from 'react';
import {Grid, Typography, Tooltip, Button, Card, styled, Chip} from '@mui/material';
import {ArrowBackRounded, EditRounded} from '@mui/icons-material';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {sourceDisplayMap} from 'src/utilities/mapSourceDisplay';
import DisplayAccountId from 'src/components/DisplayAccountId';
import DateModifiedCard from 'src/components/DateModifiedCard';
import DisplayActivationStatus from 'src/components/DisplayActivationStatus';
import EditSources from './editSources';
import EditCategories from './editCategories';
import EditTransferSchedule from './editTransferSchedule';
import DetailsCard from './detailsCard';
import EditEmails from './editEmails';


const CardWrapper = styled(Card)(
  ({ theme }) => `
    .card-header {
      border-bottom: 1px solid ${theme.palette.text.primary}22;
      padding-bottom: 15px;
    }
  `
);

const LinkWrapper = styled(Typography)(
  ({ theme }) => `
    a:-webkit-any-link {
      color: ${theme.colors.primary.main}
    }
  `
);

function ManageMatchResults({cancel, data, reload, frequencies, matchConfigs, customerConfigs}) {
  const [editingSources, setEditingSources] = useState(false);
  const [editingCategories, setEditingCategories] = useState(false);
  const [updatedSources, setUpdatedSources] = useState(null);
  const [updatedCats, setUpdatedCats] = useState(null);

  function cancelEditSources() {
    setEditingSources(false);
  }

  function updateSources(sources) {
    setUpdatedSources(sources);
  }

  function editSources() {
    setEditingSources(true);
  }

  function cancelEditCategories() {
    setEditingCategories(false);
  }

  function updateCats(cats) {
    setUpdatedCats(cats);
  }

  function editCategories() {
    setEditingCategories(true);
  }

  return (
    <Grid container spacing={3}>
      <Grid className="customer-accounts-title-trans" item xs={12}>
        <Typography variant="h4" color="primary.dark"><Tooltip arrow title="Go Back"><ArrowBackRounded className="back-arrow" onClick={cancel} sx={{ mr: 2 }} /></Tooltip> Manage Match Results</Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        {data?.transfer?.awsS3 && <DisplayAccountId data={data} />}
        <DetailsCard data={data} reload={reload}/>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateModifiedCard auditDate={data?.auditDate} auditUser={data?.auditUser}/>
        <DisplayActivationStatus configType='matches' disabled={data?.disabled}/>
      </Grid>
      <Grid item xs={12}>
        <CardWrapper>
          <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pt: 2 }}>
            <Grid item xs={6}>
              <Typography className="trans-subtitle" variant="subtitle1">Sources</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {!editingSources ? <Button onClick={editSources} className="edit-sources" startIcon={<EditRounded />} sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Edit</Button> : <Button onClick={cancelEditSources} className="cancel-edit-sources" sx={{ mt: '-10px', mb: '-10px' }} variant="text">Cancel</Button>}
            </Grid>
          </Grid>
          <Grid className={editingSources ? 'hide' : 'show'} container spacing={3} sx={{ p: 3 }}>
            <Grid item xs={12}>
              {!updatedSources && (data?.sources.length === 0 ?
                <Typography variant='body1'>There are currently no sources assigned. Add sources to match your customer records against.</Typography>
                :
                <><Typography className="trans-subtitle" variant="subtitle1">Sources your customer records will be matched against:</Typography>
                  <Typography variant='body1'>{data?.sources.map((source) => (<div key={source}>{sourceDisplayMap[source]}<br /></div>))}</Typography></>
              )}
              {updatedSources && (updatedSources.length === 0 ?
                <Typography variant='body1'>There are currently no sources assigned. Add sources to match your customer records against.</Typography>
                :
                <><Typography className="trans-subtitle" variant="subtitle1">Sources your customer records will be matched against:</Typography>
                  <Typography variant='body1'>{updatedSources.map((source) => (<div key={source}>{sourceDisplayMap[source]}<br /></div>))}</Typography></>
              )}
            </Grid>
          </Grid>
          {editingSources && <EditSources data={data} reload={reload} setEditingSources={setEditingSources} updateSources={updateSources} updatedSources={updatedSources} />}
        </CardWrapper>
      </Grid>
      <Grid item xs={12}>
        <CardWrapper>
          <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pt: 2 }}>
            <Grid item xs={6}>
              <Typography className="trans-subtitle" variant="subtitle1">Match Categories</Typography>
            </Grid>

            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              {!editingCategories ? <Button onClick={editCategories} className="edit-cats" startIcon={<EditRounded />} sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Edit</Button> : <Button onClick={cancelEditCategories} className="cancel-edit-cats" sx={{ mt: '-10px', mb: '-10px' }} variant="text">Cancel</Button>}
            </Grid>
          </Grid>
          <Grid className={editingCategories ? 'hide' : 'show'} container spacing={3} sx={{ p: 3 }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={8}>
                  <Typography className="trans-subtitle" variant="subtitle1">These categories will appear in your match results:</Typography>
                </Grid>
                <Grid item xs={4} textAlign="right">
                  <LinkWrapper variant="body2" mt={0.5}><Link target="_blank" to="/evadatas-match-categories">View Match Category Definitions</Link></LinkWrapper>
                </Grid>
              </Grid>
              <Typography variant='body1'>
                {updatedCats ? updatedCats?.map((cat) => {
                  return <Chip key={cat} sx={{ mr: 1, pl: '3px', pr: '3px' }} size="small" label={cat} />
                }) : data?.categories.map((cat) => {
                  return <Chip key={cat} sx={{ mr: 1, pl: '3px', pr: '3px' }} size="small" label={cat} />
                })}
              </Typography>
            </Grid>
          </Grid>
          {editingCategories && <EditCategories data={data} reload={reload} setEditingCategories={setEditingCategories} updateCats={updateCats} updatedCats={updatedCats} />}
        </CardWrapper>
      </Grid>
      <Grid item xs={6}>
        <EditTransferSchedule data={data} reload={reload} frequencies={frequencies} matchConfigs={matchConfigs} customerConfigs={customerConfigs} availableFrequencies={frequencies?.scanFrequencies} removedFrequencies={frequencies?.removed || []} />
      </Grid>
      <Grid item xs={6}>
        <EditEmails data={data} reload={reload} />
      </Grid>
    </Grid>
  )
}

ManageMatchResults.propTypes = {
  cancel: PropTypes.func,
  data: PropTypes.object,
  reload: PropTypes.func,
  frequencies: PropTypes.object,
  matchConfigs: PropTypes.array,
  customerConfigs: PropTypes.array
};

export default ManageMatchResults;
