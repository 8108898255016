import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import DataTable from 'src/components/DataTable';
import {useQuery} from 'src/hooks';
import {getCustomerFileConfigurations, getDeathFileConfigurations, getMatchFileConfigurations} from 'src/api';
import customerListColumns from './customerListColumns';
import deathListColumns from './deathListColumns';
import CustomerListCollapsibleContent from './CustomerListCollapsibleContent';
import DeathListCollapsibleContent from './deathListCollapsibleContent';


const noRowsText = 'No lists to display.';

function ActiveListTable({carrierId, showDeathLists}) {
  const [customerListsTableData, setCustomerListsTableData] = useState(null);
  const [customerListsLoading, setCustomerListsLoading] = useState(true);

  const {data: customerLists, isError: customerError} = useQuery(['customerConfigs', carrierId], (() => getCustomerFileConfigurations(carrierId)));
  const {data: matchLists, isError: matchError} = useQuery(['matchConfigs', carrierId], (() => getMatchFileConfigurations(carrierId)));
  const {data: deathListsTableData, isLoading: deathListsLoading, isError: deathError} = useQuery('deathFileConfigurations', () => getDeathFileConfigurations(carrierId));

  useEffect(() => {
    if (customerLists && matchLists) {
      const arrayMap = matchLists.map((matchList) => {
        const customerList = customerLists.find((customerList) => (customerList?.file?.prefix === matchList?.customerFilePrefix));
        const customerFileName = `${matchList?.customerFilePrefix}.${customerList?.file?.type}`;
        const matchFileNames = matchList?.file?.type === 'both' ? [`${matchList?.file?.prefix}.json`, `${matchList?.file?.prefix}.xlsx`] : [`${matchList?.file?.prefix}.${matchList?.file?.type}`];
        let dmfAnnualMatchFileNames = [];
        if (matchList?.transfer?.annualDmf) {
          dmfAnnualMatchFileNames = matchList?.file?.type === 'both' ? [`${matchList?.file?.prefix}_dmf_annual.json`, `${matchList?.file?.prefix}_dmf_annual.xlsx`] : [`${matchList?.file?.prefix}_dmf_annual.${matchList?.file?.type}`];
        }
        matchFileNames.push(...dmfAnnualMatchFileNames);
        return {matchList, customerList, customerFileName, matchFileNames};
      })
      setCustomerListsTableData(arrayMap);
      setCustomerListsLoading(false);
    }
  }, [customerLists, matchLists]);

  return (
    showDeathLists ?
      <DataTable rows={deathListsTableData} columns={deathListColumns} loading={deathListsLoading} apiError={deathError} noRowsText={noRowsText} CollapsibleContent={DeathListCollapsibleContent}/>
      :
      <DataTable rows={customerListsTableData} columns={customerListColumns} loading={customerListsLoading} apiError={customerError || matchError} noRowsText={noRowsText} CollapsibleContent={CustomerListCollapsibleContent}/>
  );
}

ActiveListTable.propTypes = {
  carrierId: PropTypes.string,
  showDeathLists: PropTypes.bool
}

export default ActiveListTable;
