import {Typography, Paper, Chip} from '@mui/material';
import {HistoryRounded} from '@mui/icons-material';

import {getAuditConfigEntries} from 'src/api';
import {useQuery} from 'src/hooks';
import {useAppContext} from 'src/AppContext';
import {sourceDisplayMap} from 'src/utilities/mapSourceDisplay';
import PageHeader from 'src/components/PageHeader';
import ActivityLogTable from './ActivityLogTable';


const displayAttributes = [
  'sources',
  'categories'
];

const dateOptions = {
  weekday: 'long', 
  year: 'numeric', 
  month: 'long', 
  day: 'numeric', 
  hour: '2-digit', 
  minute: '2-digit', 
  second: '2-digit',
  timeZoneName: 'short'
};

const transformAuditEntries = (auditEntries) => {
  const filteredEntries = auditEntries.reduce(function(result, item) {
    if(displayAttributes?.includes(item?.attributeChanged) || item?.operation === 'INSERT'){
      result.push(item)
    }
    return result;
  }, []);

  let arrayMap = filteredEntries.map((item, index) => {
    const container = {};
    container.id = index;
    container.fileName = item?.extension === 'both' ? `${item?.prefix}.json\n${item?.prefix}.xlsx` : `${item?.prefix}.${item?.extension}`; 
    container.time = new Date(item?.auditDate).toLocaleDateString('en-US', dateOptions );
    container.sortableTime = item?.auditDate;
    container.sources = item?.sources.length === 0 ? '-' : item?.sources.map((source) => sourceDisplayMap[source]).join(',\n');
    container.categories = item?.categories.map((cat) => {
      return <Chip key={cat} sx={{mr: 1, pl: '3px', pr: '3px', mb: '3px', width: '50px'}} size="small" label={cat} />
    });
    container.enteredBy = item?.auditUser;
    container.fileNameNickname = item?.nickname;

    return container;
  });

  return arrayMap;
}

function ActivityLog() {
  const {carrierConfig} = useAppContext();
  const carrierId = carrierConfig?.carrierId;

  const {isLoading, isError, data: auditEntries} = useQuery(['auditConfigEntries', carrierId], () => getAuditConfigEntries(carrierId, 'matches'), {select: transformAuditEntries, enabled: Boolean(carrierId)});

  return (
    <>
      <PageHeader titlePaddingTop='15px' title="Activity Log" icon={<HistoryRounded/>}/>
      <Typography variant='h5' mb={4}>Source & Match Category Activity</Typography>
      {!isLoading && auditEntries && auditEntries?.length === 0 ?
        <Paper item sx={{p: 4}}>
          <Typography variant='h6' fontSize='medium'>No activity to display.</Typography>
        </Paper>
        :
        <ActivityLogTable
          loading={isLoading || isError || !carrierId}
          rows={auditEntries || []}
          isError={isError}
        />
      }
    </>
  );
}

export default ActivityLog;
