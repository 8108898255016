import {useState} from 'react';
import {Link} from 'react-router-dom';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';
import {Card, CardContent, Container, Button, Typography, Grid, styled, Alert, IconButton, Tooltip, Switch, Divider, Skeleton} from '@mui/material';
import {Edit, VpnKeyRounded, Close} from '@mui/icons-material';

import {patchMatchFileConfig} from 'src/api';
import {useAppContext} from 'src/AppContext.js';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import GeneralErrorAlert from 'src/components/GeneralErrorAlert';
import EditNickname from '../editNickname';
import ExpandableContent from '../expandableContent';
import PrefixName from '../prefixName';
import MatchConfigDetails from './matchConfigDetails';
import ConfigStatus from '../configStatus';


const CardWrapper = styled(Grid)(
  ({ theme }) => `
    .MuiCard-root {
      padding: 0px !important;
      margin-bottom: 27px;
    }
    .child-accordion .MuiCard-root {
      margin-bottom: 0px;
    }
    .key-icons {
      width: 16px;
      float: left;
      margin-right: 4px;
      margin-top: 3px;
    }
    .success {
      color: ${theme.palette.success.main};
    }
    .error {
      color: ${theme.palette.error.main};
    }
    .pgp-button {
      font-weight:500;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .copy-click-wrapper {
      float: left;
    }
    .date-line {
      background-color: ${theme.palette.background.default};
      border-top: 1px solid ${theme.palette.text.primary}22;
    }
  `
);

const AlertWrapper = styled(Container)(
  ({ theme }) => `
  width: 100%;
  max-width: 100% !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-top: 10px !important;
  margin-bottom: -20px;
    .alert-close {
      font-size: 13px;
    }
    .close-icon {
      margin-left: 3px;
      font-size: 20px;
    }
    @media screen and (max-width: 1279px) {
      .completed-alert {
        border-radius: 4px;
      }
    }
  a:-webkit-any-link {
    color: ${theme.colors.success.main}
  }
    `
);

function MatchConfigCard({config, customerConfigs, matchConfigs, setMatchConfigs, frequencies, manageMatches, testFile, setFileType, setFilePrefix, setCustomerFilePrefix, testCustomerFilePrefix, showTestFileSuccess, setShowTestFileSuccess}) {  
  const {carrierConfig} = useAppContext();
  const carrierId = carrierConfig?.carrierId;

  const [editingNickname, setEditingNickname] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);
  const [updatedNickname, setUpdatedNickname] = useState(config?.nickname);
  const [configDisabled, setConfigDisabled] = useState(config?.disabled);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [toggleError, setToggleError] = useState(false);

  const handleConfigToggle = async () => {
    setToggleLoading(true);
    setToggleError(false);
    try {
      const result = await patchMatchFileConfig(carrierId, config?.customerFilePrefix, {disabled: !configDisabled});

      if (result.statusCode === 400 || result.statusCode === 500) {
        setToggleError(true);
      }

      if ('disabled' in result) {
        const _matchConfigs = matchConfigs.filter((_config) => _config.customerFilePrefix !== config.customerFilePrefix);
        const _matchConfig = config;
        _matchConfig.disabled = result?.disabled;
        setMatchConfigs([..._matchConfigs, _matchConfig]);
        setConfigDisabled(result?.disabled);
      }

      setToggleLoading(false);
    } catch (error) {
      setToggleError(true);
      setToggleLoading(false);
    }
  };

  function onTestFileClicked() {
    setFileType(config?.file?.type);
    setFilePrefix(config?.file?.prefix);
    setCustomerFilePrefix(config?.customerFilePrefix);
    testFile('Match Results');
  }

  return (
    <CardWrapper>
      {toggleError && <GeneralErrorAlert sx={{width: '100%'}}/>}
      <Card variant='outlined' sx={{minWidth: 275, textAlign: 'left', boxShadow: 'none'}}>
        <Grid container spacing={2}>
          {generalizedError &&
            <Grid item xs={12} sx={{ mt: 2 }}>
              <GeneralErrorAlert />
            </Grid>
          }
          {showTestFileSuccess && (testCustomerFilePrefix === config?.customerFilePrefix) &&
            <AlertWrapper>
              <Grid item xs={12} sx={{ ml: 2, mt: 2 }}>
                <Alert
                  className="completed-alert"
                  severity="success"
                  action={
                    <IconButton className="alert-close"
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => setShowTestFileSuccess(false)}
                    >
                      CLOSE <Close className='close-icon' fontSize="inherit"/>
                    </IconButton>
                  }>Match results test file requested {DateTime.now().toFormat('MM/dd/yyyy')}. Track file status on <Link to="/matches-file">Match Results Activity</Link> page</Alert>
              </Grid>
            </AlertWrapper>
          }
          <Grid item xs={7} display='inline-flex'>
            <Tooltip arrow placement='right' title={configDisabled ? 'Activate Match File' : 'Deactivate Match File'}>
              <Switch sx={{ml: 2, mt: 2}} checked={!configDisabled} onChange={handleConfigToggle} disabled={toggleLoading}/>
            </Tooltip>
            <Divider sx={{height: '50% !important', mt: 2, mx: 2}} orientation="vertical" variant="middle" flexItem/>
            {editingNickname ?
              <EditNickname data={config} nickname={updatedNickname || 'Match Results'} cancelEditNickname={() => setEditingNickname(false)} setGeneralizedError={setGeneralizedError} setUpdatedNickname={setUpdatedNickname}/>
              :
              <Typography variant="subtitle1" sx={{mb: 1, pt: 2}} gutterBottom>
                {updatedNickname || 'Match Results'} <Tooltip arrow title="Rename"><IconButton aria-label="edit" onClick={() => setEditingNickname(true)} disabled={toggleLoading}>
                  <Edit color={toggleLoading ? "disabled" : "primary"} sx={{ width: '18px', pb: '5px' }} />
                </IconButton></Tooltip>
              </Typography>
            }
          </Grid>
          <Grid item xs={5} sx={{ textAlign: 'right', pr: 2 }}>
            <Button onClick={onTestFileClicked} sx={{ mt: '12px', mr: 1 }} variant="outlined" disabled={toggleLoading}>Test File</Button>
            <Button onClick={() => manageMatches({...config, disabled: configDisabled})} sx={{ mt: '12px' }} variant="contained" disabled={toggleLoading}>Manage</Button>
          </Grid>
        </Grid>
        <Grid className="date-line" container sx={{ padding: 0 }}>
          <Grid item xs={6} sx={{ px: 2, py: '3px' }}>
            {toggleLoading ? <Skeleton width='150px'/> :
              <ConfigStatus disabled={configDisabled}/>
            }
          </Grid>
          <Grid item xs={6} sx={{ px: 2, py: '3px', textAlign: 'right' }}>
            {toggleLoading ? <Skeleton width='300px' sx={{display: 'inline-flex'}}/> :
              <Typography variant="caption">Modified {config?.auditDate} by {config?.auditUser}</Typography>
            }
          </Grid>
        </Grid>
        <CardContent sx={{ padding: '8px 16px 0px' }}>
          {toggleLoading ? <LoadingIcon marginTop='20px' contained/> :
            <>
              <Grid container justifyContent='right'>
                <Typography variant="caption" className='success'><VpnKeyRounded className="key-icons" sx={{mt: '-1px !important'}}/>{config?.transfer?.sftp ? 'PGP' : 'KMS'} Key Encryption On</Typography>
              </Grid>
              <Grid container sx={{ pl: { xs: 0, sm: 0 }, pt: 2 }}>
                <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: 4, mb: { xs: 0, sm: 5 } }}>
                  <Typography variant="subtitle1" color='text.secondary'>Prefix Name:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <PrefixName filePrefix={config?.file?.prefix} fileType={config?.file?.type}/>
                </Grid>
                <Grid item xs={12} sm={3}></Grid>
                <ExpandableContent>
                  <MatchConfigDetails config={config} matchConfigs={matchConfigs} customerConfigs={customerConfigs} frequencies={frequencies}/>
                </ExpandableContent>
              </Grid>
            </>
          }
        </CardContent>
      </Card>
    </CardWrapper>
  );
}

MatchConfigCard.propTypes = {
  config: PropTypes.object,
  customerConfigs: PropTypes.array,
  matchConfigs: PropTypes.array,
  setMatchConfigs: PropTypes.func,
  frequencies: PropTypes.object,
  manageMatches: PropTypes.func,
  testFile: PropTypes.func,
  setFileType: PropTypes.func,
  setFilePrefix: PropTypes.func,
  setCustomerFilePrefix: PropTypes.func,
  showTestFileSuccess: PropTypes.bool,
  setShowTestFileSuccess: PropTypes.func,
  testCustomerFilePrefix: PropTypes.string
};

export default MatchConfigCard;
