import {Alert} from '@mui/material';


function GeneralErrorAlert(props) {
  return (
    <Alert severity="error" sx={{ mx: 2 }} {...props}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
  );
}

export default GeneralErrorAlert;
