import {Box, Table, TableHead, TableRow, Skeleton, TableBody, TableCell, Typography} from '@mui/material';


function CalendarSkeleton() {
  return (
    <Box m={2} border='1px solid rgba(0, 0, 0, 0.23)'>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><Skeleton width='150px'/></TableCell>
            <TableCell><Typography sx={{display: 'flex', justifyContent: 'flex-end'}}><Skeleton width='72px'/></Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Skeleton width='400px'/>
            </TableCell>
            <TableCell/>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

export default CalendarSkeleton;
