import {Typography, FormHelperText, Box, FormControl, Checkbox, Skeleton, FormControlLabel} from '@mui/material';
import {Field} from 'formik';
import PropTypes from 'prop-types';

import {getSources} from 'src/api';
import {useQuery} from 'src/hooks';


const sourcesMapping = {
  'carrier': 'Carrier-to-Carrier',
  'dmf': 'DMF',
  'funeral-home-obituary': 'Obit Funeral Home',
  'newspaper-obituary': 'Obit Newspaper',
  'state': 'State'
};

function SourceSelection({carrierId, formik, field}) {
  const showHelperText = formik.touched[field] && formik.errors[field];

  const {data: availableSources} = useQuery(['availableSources'], () => getSources(carrierId), {enabled: Boolean(carrierId)});

  return (
    <>
      <Typography variant='h6' color='text.secondary' sx={{mb: 4, mt: 4}}>SOURCE SELECTION</Typography>
      <Typography variant='subtitle1' color='text.primary' sx={{mb: 2}}>Specify sources your customer records should be matched against.</Typography>
      <FormHelperText sx={{mb: 0, mt: 1}} error={showHelperText}>Minimum of one source required.</FormHelperText>
      <Box sx={{mb: 4}} role='optgroup' aria-labelledby='checkbox-group'>
        <FormControl component='fieldset'>
          {availableSources?.length > 0 ?
            availableSources?.sort().map((source) => {
              return <Field as={FormControlLabel} key={source} type='checkbox' name={field} label={sourcesMapping[source]} value={source} control={<Checkbox/>} sx={{ml: 1}}/>
            })
            :
            <Box display='inline-flex' ml={1}>
              <Checkbox disabled/>
              <Skeleton width='200px'/>
            </Box>
          }
          {showHelperText && <FormHelperText sx={{mt: 1}} error>At least one source selection is required.</FormHelperText>}
        </FormControl>
      </Box>
    </>
  );
}

SourceSelection.propTypes = {
  carrierId: PropTypes.string,
  formik: PropTypes.object,
  field: PropTypes.string
};

export default SourceSelection;
