import PropTypes from 'prop-types';
import {Box, styled} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';

import SkeletonRows from './skeletonRows';
import activityLogColumns from './columns';
import LoadError from 'src/components/LoadError';


const TableWrapper = styled(Box)(
  ({ theme }) => `
    .wrap-cell {
      white-space: break-spaces;
      width: 100%;
      display: block;
      overflow-wrap: anywhere;
    }
    .MuiDataGrid-columnSeparator {
      opacity: 0 !important;
    }
    .MuiDataGrid-root {
      background-color: ${theme.palette.background.paper} !important;
    }
    .MuiDataGrid-root .MuiDataGrid-row:hover, .MuiDataGrid-root .MuiDataGrid-row.Mui-hovered, .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
      background-color: ${theme.palette.background.paper} !important;
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-cell:focus {
      outline: none;
    }
    .MuiDataGrid-footerContainer {
      border-top: 0;
    }
  `
)

function ActivityLogTable({...props}) {
  return (
    <TableWrapper sx={{height: props?.loading ? 402 : 'auto', width: '100%'}}>
      <DataGrid
        {...props}
        initialState={{
          pagination: {paginationModel: {pageSize: 5}}
        }}
        pageSizeOptions={[5, 10, 25]}
        getEstimatedRowHeight={() => 88}
        getRowHeight={() => 'auto'}
        disableColumnMenu={true}
        columns={activityLogColumns}
        slots={{
          loadingOverlay: props?.isError ? LoadError : SkeletonRows,
        }}
        slotProps={{
          loadingOverlay: props?.isError ? {sx: {minHeight: '290px'}} : {}
        }}
        sx={{
          '& .MuiDataGrid-cell': {
            display: 'flex',
            alignContent: 'start',
            flexWrap: 'wrap'
          },
        }}
      />
    </TableWrapper>
  );
}

ActivityLogTable.propTypes = {
  skeleton: PropTypes.element,
  loading: PropTypes.bool,
  isError: PropTypes.bool
};

export default ActivityLogTable;
