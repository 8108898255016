import {useState} from 'react';
import {Button, ButtonGroup} from '@mui/material';
import {ListAltRounded} from '@mui/icons-material';

import PageHeader from 'src/components/PageHeader';
import {useAppContext} from 'src/AppContext';
import ActiveListTable from './ActiveListTable';


function ActiveListSummary() {
  const {carrierConfig} = useAppContext();
  const carrierId = carrierConfig?.carrierId;

  const [showDeathLists, setShowDeathLists] = useState(false);

  return (
    <>
      <PageHeader titlePaddingTop={'15px'} title="Active List Summary" icon={<ListAltRounded/>}/>
      <ButtonGroup sx={{ mb: 4 }}>
        <Button onClick={() => setShowDeathLists(false)} variant={showDeathLists ? "outlined" : "contained"}>Customer Lists</Button>
        <Button onClick={() => setShowDeathLists(true)} variant={showDeathLists ? "contained" : "outlined"}>Verified Death Lists</Button>
      </ButtonGroup>
      <ActiveListTable carrierId={carrierId} showDeathLists={showDeathLists}/>
    </>
  );
}

export default ActiveListSummary;
