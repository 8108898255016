import {useState} from 'react';
import {Button} from '@mui/material';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import PropTypes from 'prop-types';


function ExpandableContent({children}) {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button className="pgp-button show-hide" variant="text" size="medium" onClick={() => setShow(!show)}>Show {show ? <>Less <ExpandLess /></> : <>More <ExpandMore /></>}</Button>
      {show && children}
    </>
  );
}

ExpandableContent.propTypes = {
  children: PropTypes.element
};

export default ExpandableContent;
