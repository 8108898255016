import {Divider, Grid, Skeleton, styled} from '@mui/material';
import {times} from 'lodash-es';


const DividerWrapper = styled(Grid)(
  () => `
    hr {
      background-color: rgba(0, 0, 0, 0.12);
    }
  `
);

const ActivityLogSkeletonRow = () => (
  times(5, () => <Skeleton sx={{mt: 2, mb: 2, ml: 1, mr: 1, width: '18%', float: 'left'}}/>)
);

function SkeletonRows() {
  return times(5, () =>
    <Grid container>
      <Grid item xs={12}>
        <ActivityLogSkeletonRow/>
      </Grid>
      <DividerWrapper item xs={12}>
        <Divider/>
      </DividerWrapper>
    </Grid>
  );
}

export default SkeletonRows;
