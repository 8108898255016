import {useState} from 'react';
import {useFormik, FormikProvider, Form} from 'formik';
import PropTypes from 'prop-types';
import {Grid, Typography, Card, styled, Avatar, Button, Box} from '@mui/material';
import {CompareRounded, PeopleRounded, VerifiedRounded, EditRounded} from '@mui/icons-material';

import {useAppContext} from 'src/AppContext';
import {editFileType} from 'src/api';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import GeneralErrorAlert from 'src/components/GeneralErrorAlert';
import FileTypeField from 'src/components/FormFields/fileType';
import FileNameExample from 'src/components/FormFields/fileNameExample';


const CardWrapper = styled(Card)(
  ({ theme }) => `
  .card-header {
    border-bottom: 1px solid ${theme.palette.text.primary}22;
    padding-bottom: 15px;
  }
  .nav-card-circle {
    margin: 0 auto 20px;
    width: 60px;
    height: 60px;
    background-color: transparent;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
    border: 2px solid ${theme.palette.primary.dark};
    .icon {
      color: ${theme.palette.primary.dark};
    }
  }
  `
);

function DetailsCard({data, reload}) {
  const { carrierConfig } = useAppContext();
  const [fileType, setFileType] = useState(data?.file?.type);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);

  const formik = useFormik({
    initialValues: {
      fileType: data?.file?.type
    },
    onSubmit: async (formValues) => {
      try {
        setLoading(true);

        let payload = {
          'type': data?.configurationType,
          'filePrefix': data?.configurationType === 'matches' ? data?.customerFilePrefix : data?.file?.prefix,
          'fileType': formValues?.fileType
        };
        
        const result = await editFileType(carrierConfig?.carrierId, payload);
        if (result.statusCode === 400 || result.statusCode === 500) {
          setLoading(false);
          window.scrollTo(0, 0);
          setGeneralizedError(true);
        }
        if (result.fileType) {
          setFileType(result.fileType);
          setLoading(false);
          setEditing(false);
          await reload();
        }
      }
      catch (e) {
        setLoading(false);
        throw (e);
      }
    }
  });

  const cancelEditing = () => {
    setEditing(false);
    setGeneralizedError(false);
    formik.setFieldValue('fileType', fileType);
  }

  return (
    <CardWrapper sx={{height: editing ? 'inherit' : '374px'}}>
      <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
        <Grid item xs={6}>
          <Typography className="encrypt-subtitle" variant="subtitle1">Details</Typography>
        </Grid>  
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            {editing ?
              !loading && <Button onClick={cancelEditing} className="cancel-add-key" sx={{ mt: '-10px', mb: '-10px' }} variant="text">Cancel</Button> : 
              <Button onClick={() => setEditing(true)} className="edit-trans-locations" startIcon={<EditRounded />} sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Edit</Button>
            }
          </Grid>
      </Grid>
      {loading &&
        <Box sx={{ minHeight: '349px' }}>
          <LoadingIcon contained={true} />
        </Box>
      }
      {!loading &&
        <>
          <Grid container spacing={3} sx={{ p: 3 }}>
            {generalizedError &&
              <Grid item xs={12}>
                <GeneralErrorAlert />
              </Grid>
            }
            <Grid item sm={12} sx={{ textAlign: 'center' }}>
              <Avatar className="nav-card-circle">
                {data?.configurationType === 'customer_records' && <PeopleRounded className="icon" /> }
                {data?.configurationType === 'death_records' && <VerifiedRounded className="icon" /> }
                {data?.configurationType === 'matches' && <CompareRounded className="icon" /> }
              </Avatar>
              <Typography variant="h5" sx={{mb: 3}}>{data?.file?.prefix}</Typography>
              {!editing &&
                <>
                  {fileType === 'both' ?
                    <>
                      <Typography variant="body1" >{data?.file?.prefix}_XXXXXX.json</Typography>
                      <Typography variant="body1" sx={{ mb: 4 }}>{data?.file?.prefix}_XXXXXX.xlsx</Typography>
                    </>
                    :

                    <Typography variant="body1" sx={{ mb: 3 }}>{data?.file?.prefix}_XXXXXX.{fileType}</Typography>
                  }
                  <Typography variant="body2"> {data?.configurationType === 'matches' ? 'Prefix is used by LENS to create your matches.' : 'Prefix and file type are used by LENS to identify your list.'}</Typography>
                </>
              }
            </Grid>
          </Grid>
          {editing &&
            <FormikProvider value={formik}>
              <Form>
                <Box sx={{ px: 3 }}>
                  {data?.configurationType === "matches" ? <FileTypeField mt={-1} formik={formik} name="fileType" formLabel2='XLSX' formValue2='xlsx' formLabel3='Both JSON & XLSX' formValue3='both' /> : <FileTypeField formik={formik} name="fileType" />}
                  <FileNameExample prefix={data?.file?.prefix} configurationType={data?.configurationType} date="XXXXXX" fileType={formik.values.fileType}/>
                </Box>
                <hr />
                <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
                  <Button type="submit" variant="contained">Save Changes</Button>
                </Box>
              </Form>
            </FormikProvider>
          }
        </>
      }
    </CardWrapper>
  )
}

DetailsCard.propTypes = {
  data: PropTypes.object, 
  reload: PropTypes.func
};

export default DetailsCard;
