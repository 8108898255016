import {format} from 'date-fns';
import PropTypes from 'prop-types';
import {Button, ButtonGroup, Grid, IconButton, Tooltip, Typography, styled, Box, Skeleton} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CalendarViewMonthRounded, ViewListRounded, ArrowBackTwoTone, ArrowForwardTwoTone} from '@mui/icons-material';


const ActionsWrapper = styled(Box)(
  ({ theme }) => `
  .calendar-btn-group {
    border: 1px solid ${theme.palette.text.disabled}
  }
  .inactive-view {
    color: ${theme.palette.text.disabled}
  }
`);

const viewOptions = [
  {
    label: 'Year',
    value: 'listYear',
    icon: ViewListRounded
  },
  {
    label: 'Month',
    value: 'dayGridMonth',
    icon: CalendarViewMonthRounded
  }
];

const Actions = ({date, onNext, onPrevious, onToday, changeView, view, loading, apiError}) => {
  const { t } = useTranslation();

  const dateHeading = format(date, view === 'listYear' ? 'yyyy' : 'MMMM yyyy');

  return (
    <ActionsWrapper>
      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Tooltip arrow placement="top" title={t('Previous')}>
            <IconButton color="primary" onClick={onPrevious} disabled={loading || apiError}>
              <ArrowBackTwoTone/>
            </IconButton>
          </Tooltip>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              mx: 1
            }}
            onClick={onToday}
            disabled={loading || apiError}
          >
            Today
          </Button>
          <Tooltip arrow placement="top" title={t('Next')}>
            <IconButton color="primary" onClick={onNext} disabled={loading || apiError}>
              <ArrowForwardTwoTone/>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
          <Typography variant="h6" color="text.primary" sx={{ fontWeight: 'bold', mr: 10 }}>
            {loading ? <Skeleton width='120px'/> : dateHeading}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: { xs: 'none', sm: 'inline-block' }
          }}
        >
          <ButtonGroup variant="contained" aria-label="outlined primary button group" className="calendar-btn-group">

            {viewOptions.map((viewOption) => {
              const Icon = viewOption.icon;

              return (
                <Tooltip
                  key={viewOption.value}
                  arrow
                  placement="top"
                  title={t(viewOption.label)}
                >
                  <Button sx={{ borderRadius: '6px', width: '20px' }}
                    className={viewOption.value === view ? 'active-view' : 'inactive-view'}
                    color={viewOption.value === view ? 'primary' : 'action'}
                    onClick={() => changeView(viewOption.value)}
                    disabled={(viewOption.value === 'dayGridMonth' && loading) || apiError}
                  >
                    <Icon />
                  </Button>
                </Tooltip>
              );
            })}
          </ButtonGroup>
        </Grid>
      </Grid>
    </ActionsWrapper>
  );
};

Actions.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onToday: PropTypes.func,
  changeView: PropTypes.func,
  view: PropTypes.oneOf([
    'dayGridMonth',
    'timeGridWeek',
    'timeGridDay',
    'listYear'
  ]),
  loading: PropTypes.bool,
  apiError: PropTypes.bool
};

export default Actions;
