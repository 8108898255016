import {Typography} from "@mui/material";


const deathListColumns = [
  { 
    field: 'deathList', 
    headerName: 'Verified Death List', 
    renderCell: (params) => {
      return (
        <>
          {params.row.nickname && <Typography variant='body2'>{params.row.nickname}</Typography>}
          <Typography variant={params.row.nickname ? 'caption' : 'body2'}>{`${params.row.file.prefix}.${params.row.file.type}`}</Typography>
        </>
      );
    }
  },
];

export default deathListColumns;
