import {Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {split} from 'lodash-es';

import {months} from 'src/utilities/months';


function DisplayQuarterlyDates({formik=null, reportDates=null}) {
  return(
    <>
      <Typography variant='subtitle2' color='text.primary' sx={{mt: 0.5, mb: 0.5}}>Quarterly delivery dates, scheduled to deliver repeatedly year after year:</Typography>
      {
        (formik ? [formik.values.q1Month, formik.values.q2Month, formik.values.q3Month, formik.values.q4Month] : reportDates).map((date, i) => {
          return(
            <>
              <Typography variant='subtitle1' sx={{display: 'inline', mb: 0.5}}>Q{i+1}: </Typography>
              <Typography sx={{display: 'inline'}}>{months[Number(formik ? date : split(date, '/')[0])-1]} {Number(formik ? formik?.values[`q${i+1}Day`] : split(date, '/')[1])}</Typography>
              <br/>
            </>
          )
        })
      }
    </>
  );
}

DisplayQuarterlyDates.propTypes = {
  formik: PropTypes.object,
  reportDates: PropTypes.array
};

export default DisplayQuarterlyDates;
