import React, { useRef, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import { Button, Grid, FormControlLabel, Radio, RadioGroup, TextField, Typography, Box, FormHelperText, Alert } from '@mui/material';
import { ArrowBack, ErrorOutline, OpenInNewRounded } from '@mui/icons-material';

import { useAppContext } from 'src/AppContext.js';
import { setupDeathFile } from 'src/api';
import { getCurrentDate } from 'src/utilities/getDate';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import { useCurrentUserEmail } from 'src/utilities/getCurrentUser';
import AwsFields from 'src/components/AwsFields';
import PgpRotationField from 'src/components/FormFields/pgpRotation';
import ErrorAlert from 'src/components/ErrorAlert';
import PrefixField from 'src/components/FormFields/filePrefix';
import FileTypeField from 'src/components/FormFields/fileType';
import FileNameExample from 'src/components/FormFields/fileNameExample';
import TransferFreqDisplayOnly from 'src/components/FormFields/transferFreqDisplayOnly';
import TransferLocationMethodDisplayOnly from 'src/components/FormFields/transferLocationMethodDisplayOnly';
import SftpServerDisplayOnly from 'src/components/FormFields/sftpServerDisplayOnly';
import { prefixRestrictionText, prefixRestrictionRegex } from 'src/utilities/filePrefixRestriction';
import NotificationPreferences from 'src/components/FormFields/notificationPreferences';
import LoadError from 'src/components/LoadError';


yup.addMethod(yup.array, 'uniqueVFD', function (payload, message, mapper = a => a) {
  return this.test('uniqueVFD', message, function (list) {
    const masterEmailList = list.filter(x => x).concat(payload)
    return masterEmailList.length === new Set(masterEmailList.map(mapper)).size;
  });
});

yup.addMethod(yup.string, 'containsId', function (id, message) {
  return this.test('containsId', message, function (value) {
    if (value && (value.length > 0)) {
      return value.includes(id);
    }
    else {
      return true;
    }
  });
});

const validationSchema = yup.object().shape({
  prefix: yup
    .string()
    .matches(prefixRestrictionRegex, prefixRestrictionText)
    .max(24, 'A maximum of 24 characters are allowed')
    .required('Field is required'),
  fileType: yup
    .string()
    .required(''),
  encryptDeathFile: yup
    .string()
    .required('Field is required'),
  pgpKeyRotation: yup
    .string()
    .when('transMethod', (transMethod) => {
      if (transMethod === 'sftp') {
        return yup.string().required()
      } else {
        return yup.string()
      }
    }
    ),
  transMethod: yup
    .string()
    .required(),
  useExistingTransferUser: yup
    .boolean()
    .required('Field is required'),
  usernameSFTP: yup
    .string()
    .max(100, 'A maximum of 100 characters are allowed')
    .matches(/^[^.@-].*$/, "Username can’t start with hyphen –, @ symbol or period")
    .min(3, 'A minimum of 3 characters are allowed')
    .when("useExistingTransferUser", {
      is: false,
      then: yup.string().required('Field is required')
    }),
  SSHRSAKey: yup
    .string()
    .when("useExistingTransferUser", {
      is: false,
      then: yup.string().required('Field is required')
    }),
  ARNofS3Bucket: yup
    .string()
    .when('transMethod', (transMethod) => {
      if (transMethod === 'awsS3') {
        return yup.string().required('Field is required')
          .matches(/^arn:aws:s3:::/, "Incorrect format entered. ARN should begin with: arn:aws:s3:::")
          .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
      } else {
        return yup.string()
      }
    }
    ),
  KMSKeyARN: yup
    .string()
    .when('accountId', (accountId) => {
      return yup
        .string()
        .matches(/arn:aws:kms:/, "Incorrect format entered. ARN should begin with: arn:aws:kms:")
        .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
        .containsId(accountId, 'ARN entered is incorrect. ARN should include account ID: arn:aws:kms:<AWSRegion>:<AWSAccountID>')
    }
    ),
  IAMRoleARN: yup
    .string()
    .when(['transMethod', 'accountId'], (transMethod, accountId) => {
      if (transMethod === 'awsS3') {
        return yup.string()
          .matches(/^arn:aws:iam::/, "Incorrect format entered. ARN should begin with: arn:aws:iam::")
          .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
          .containsId(accountId, 'ARN entered is incorrect. ARN should include account ID: arn:aws:iam::<AWSAccountID>')
          .required('Field is required')
      } else {
        return yup.string()
      }
    }
    ),
  preExistingEmails: yup
    .array()
    .nullable()
    .when("newEmails", (newEmails) => {
      if (newEmails.length < 1) {
        return yup.array().min(1, "Must enter email address").nullable();
      }
      if (!newEmails[0]) {
        return yup.array().min(1, "Must enter email address").nullable();
      }
      return yup.array().nullable();
    }),
  newEmails: yup
    .array()
    .when("preExistingEmails", (preExistingEmails) => {
      if (preExistingEmails) {
        return yup.array().uniqueVFD(preExistingEmails, '').of(yup.string().email())
      }
      else {
        return yup.array().of(yup.string().email())
      }
    }),
}, ['newEmails', 'preExistingEmails']);

function VerifiedDeathsFileSetupForm({setResult, availableEmailAddresses, availablePgpKeys, transferType, existingTransferUser, evadataLambdaArn, evadataLambdaRoleArn, accountId, sftpServer, apiError}) {
  const { carrierConfig } = useAppContext();
  const {userEmail} = useCurrentUserEmail();

  const setupVerifiedDeathFileApi = async function (values, emails) {
    const payload = {
      file: {
        prefix: values.prefix,
        type: values.fileType
      },
      transfer: {
        type: values.transMethod,
        frequency: 'daily'
      },
      notificationEmails: emails
    };
    if (transferType === 'sftp') {
      payload.transfer.sftp = {
        username: values.usernameSFTP,
        sshKey: values.SSHRSAKey,
      }
      if (values.useExistingTransferUser === true || values.useExistingTransferUser === 'true') {
        payload.transfer.sftp.username = existingTransferUser;
        payload.transfer.sftp.sshKey = undefined;
      }
      if (values.encryptDeathFile === true || values.encryptDeathFile === 'true') { // even though yup def is boolean, this can be a string depending on the validation flow
        payload.transfer.sftp.pgpKeyRotationInYears = values.pgpKeyRotation;
      } else if (values.encryptDeathFile === false || values.encryptDeathFile === 'false') {
        // do nothing
      } else {
        payload.pgpId = values.encryptDeathFile
      }
    }
    if (transferType === 'awsS3') {
      payload.transfer.awsS3 = {
        s3Bucket: values.ARNofS3Bucket,
        iamRole: values.IAMRoleARN
      }
      if (values.KMSKeyARN.length > 0) {
        payload.transfer.awsS3.kmsArn = values.KMSKeyARN
      }
    }

    return setupDeathFile(carrierConfig.carrierId, payload);
  };

  const date = getCurrentDate();

  const inputRef = useRef();
  const sshRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [usernameInUseError, setUsernameInUseError] = useState(false);
  const [sshKeyError, setSshKeyError] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [badUsers, setBadUsers] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const formik = useFormik({
    initialValues: {
      prefix: 'verified_deaths',
      fileType: 'json',
      pgpKeyRotation: '2',
      transMethod: '',
      encryptDeathFile: true,
      useExistingTransferUser: true,
      usernameSFTP: '',
      SSHRSAKey: '',
      accountId: '',
      ARNofS3Bucket: '',
      KMSKeyARN: '',
      IAMRoleARN: '',
      preExistingEmails: null,
      newEmails: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        setGeneralError(false);
        let _allEmails
        if (values.preExistingEmails.length > 0 && values.newEmails.length > 0) {
          _allEmails = values.preExistingEmails.concat(values.newEmails.filter(x => x));
        } else if (values.preExistingEmails.length > 0) {
          _allEmails = values.preExistingEmails;
        } else {
          _allEmails = values.newEmails;
        }
        for (let i = 0; i < _allEmails.length; i++) {
          if (_allEmails[i] === '') {
            _allEmails.splice(i, 1);
          }
        }
        const result = await setupVerifiedDeathFileApi(values, _allEmails);
        if (result.statusCode === 400) {
          setIsLoading(false);
          if (result.message === 'User already exists') {
            setUsernameInUseError(true);
            setBadUsers([...badUsers, formik.values.usernameSFTP]);
            inputRef.current.scrollIntoView();
          } else if (result.message === 'Unsupported SSH key format') {
            setSshKeyError(true);
            sshRef.current.scrollIntoView();
          } else {
            window.scrollTo(0, 0);
            setGeneralError(true);
          }
        } else if (result.statusCode === 500) {
          setIsLoading(false);
          window.scrollTo(0, 0);
          setGeneralError(true);
        } else {
          setResult(result);
        }
      }
      catch (e) {
        setIsLoading(false);
        setGeneralError(true);
      }
    },
  });

  const [userEmailInit, setUserEmailInit] = useState(false);
  const [formInitIsSet, setFormInitIsSet] = useState(false);

  useEffect(() => {
    if (!formik?.values?.preExistingEmails && (userEmailInit === false)) {
      if (userEmail) {
        formik.setFieldValue("preExistingEmails", [userEmail]);
        setUserEmailInit(true);
      }
      if (userEmailInit === true && formInitIsSet === false) {
        setFormInitIsSet(true);
      }
    }
  }, [formik, userEmailInit, userEmail, formInitIsSet]);

  const [accountIdInit, setAccountIdInit] = useState(false);

  useEffect(() => {
    if (!accountIdInit && accountId) {
      formik.setFieldValue("accountId", accountId);
      setAccountIdInit(true);
    }
  }, [formik, accountIdInit, accountId]);

  useEffect(() => {
    if (badUsers?.includes(formik.values.usernameSFTP)) {
      setUsernameInUseError(true);
    } else {
      setUsernameInUseError(false);
    }
  }, [badUsers, formik.values.usernameSFTP]);

  useEffect(() => {
    if (formik.values.transMethod === '') {
      if (transferType === 'sftp') {
        formik.setFieldValue("transMethod", 'sftp');
      }
      if (transferType === 'awsS3') {
        formik.setFieldValue("transMethod", 'awsS3');
      }
    }
  }, [formik, transferType]);

  const validateErrors = () => {
    if (formik.errors) {
      setShowErrorAlert(true);
      window.scroll(0, 0);
    }
  }

  return (
    <>
      {isLoading && <LoadingIcon />}
      {<ErrorAlert formik={formik} showErrorAlert={showErrorAlert} />}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          {apiError ? <LoadError/> :
            <Box sx={{ p: 5 }}>
              <Alert className={generalError ? 'show' : 'hide'} severity="error" sx={{ mb: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
              <Box>
                <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>FILE DETAILS</Typography>

                <PrefixField name="prefix" label="File Prefix" formik={formik} helperText={'24 character limit. Exclude file extension.'} />

                <FileTypeField name="fileType" formik={formik} />

                <FileNameExample toolTip="Name your file as shown. Including the date (optional) the file is sent to LENS helps when transferring files daily." prefix={formik.values.prefix} date={date} fileType={formik.values.fileType} />

                <TransferFreqDisplayOnly frequency="Daily (Monday-Friday)" />

                <hr />

                <Typography className="trans-location-vdf" variant="h6" color="text.secondary" sx={{ mb: 4, mt: 4 }}>TRANSFER LOCATION & ENCRYPTION</Typography>

                <TransferLocationMethodDisplayOnly transferType={transferType} />

                {transferType === 'sftp' &&
                  <>
                    <SftpServerDisplayOnly sftpServer={sftpServer} subdirectory='/death_files' />

                    <Typography variant="subtitle1" color="text.primary" sx={{ mb: 2 }}>What credentials would you like to use when accessing LENS SFTP to transfer your Verified Deaths list?</Typography>

                    <RadioGroup ref={inputRef}
                      id="useExistingTransferUser"
                      name="useExistingTransferUser"
                      value={formik.values.useExistingTransferUser}
                      onChange={formik.handleChange}
                      sx={{ mb: 3 }}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Use same username and SSH-RSA key from Customer list." />
                      <div className={(formik.values.useExistingTransferUser === true || formik.values.useExistingTransferUser === 'true') ? 'show' : 'hide'}>
                        <Typography variant="body1" color="text.secondary" sx={{ mt: 1, ml: 4 }}>SFTP Username</Typography>
                        <Typography variant="body1" color="text.primary" sx={{ mb: 1, ml: 4 }}>{existingTransferUser}</Typography>
                        <Typography variant="body1" color="text.primary" sx={{ mb: 1, ml: 4 }}>For security reasons, the public SSH-RSA key provided is hidden.</Typography>
                      </div>
                      <FormControlLabel value={false} control={<Radio />} label="Provide new username and SSH-RSA key for Verified Deaths list." />
                    </RadioGroup>

                    <div className={(formik.values.useExistingTransferUser === 'true' || formik.values.useExistingTransferUser === true) ? 'hide' : 'show'}>
                      <TextField ref={sshRef}
                        className="username"
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autoComplete: 'off',
                          },
                        }}
                        type='text'
                        id="usernameSFTP"
                        name="usernameSFTP"
                        label="SFTP Username"
                        value={formik.values.usernameSFTP}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.usernameSFTP && Boolean(formik.errors.usernameSFTP)}
                        helperText={formik.touched.usernameSFTP ? formik.errors.usernameSFTP : 'Minimum 3 characters. Username can’t start with hyphen –, @ symbol or period.'}
                        sx={{ mb: 3, ml: 4 }}
                      />

                      {usernameInUseError && <FormHelperText sx={{ mt: '-20px', mb: 4 }} error={true}><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} /> Username entered is already in use, try a different name.</FormHelperText>}

                      <Typography variant="body1" color="text.secondary" sx={{ mb: 4, ml: 4 }}>Create SSH-RSA key to authenticate to access LENS virtual server instance. <a className="ssh-link" href="https://docs.aws.amazon.com/transfer/latest/userguide/key-management.html#sshkeygen" target="blank">Learn More <OpenInNewRounded sx={{ fontSize: '18px', mb: '-4px', ml: '4px' }} /></a></Typography>


                      <TextField
                        fullWidth
                        multiline
                        rows={6}
                        id="SSHRSAKey"
                        name="SSHRSAKey"
                        label="Enter Public SSH-RSA Key"
                        value={formik.values.SSHRSAKey}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.SSHRSAKey && Boolean(formik.errors.SSHRSAKey)}
                        helperText={(formik.touched.SSHRSAKey && Boolean(formik.errors.SSHRSAKey)) ? formik.errors.SSHRSAKey : 'For security reasons, the public SSH-RSA key provided will be hidden after saving.'}
                        sx={{ mb: 3, ml: 4 }}
                      />

                      {sshKeyError ? <FormHelperText sx={{ mt: '-20px', mb: 4, ml: 3 }} error={true}><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} /> Unsupported ssh key format. RSA, ECDSA and ED25519 are supported formats.</FormHelperText> : ''}
                    </div>

                    <Typography variant="subtitle1" color="text.primary" sx={{ mb: 2 }}>Select File Encryption Preference.</Typography>

                    <RadioGroup
                      id="encryptDeathFile"
                      name="encryptDeathFile"
                      value={formik.values.encryptDeathFile}
                      onChange={formik.handleChange}
                      sx={{ mb: 3 }}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Encrypt list with new public PGP key created by LENS (Public key will be available to copy or download on summary page)." />

                      {(formik.values.encryptDeathFile === 'true' || formik.values.encryptDeathFile === true) &&
                        <PgpRotationField name="pgpKeyRotation" label="PGP Key Rotation" formik={formik} />
                      }

                      {availablePgpKeys && <FormControlLabel value={availablePgpKeys[0].id} control={<Radio />} label="Encrypt list with same PGP key used for Customer list." />}
                      <FormControlLabel value={false} control={<Radio />} label="Do not encrypt list with PGP key." />
                    </RadioGroup>
                  </>
                }

                {transferType === 'awsS3' &&
                  <>
                    <AwsFields
                      showLambdaArn
                      instructionText={'Provide output values from the Cloud Formation Template for LENS to access your Verified Deaths list.'}
                      accountId={formik.values.accountId}
                      evadataLambdaArn={evadataLambdaArn}
                      evadataLambdaRoleArn={evadataLambdaRoleArn}
                      cfTemplateFile="carrier-aws-vdf-option.yml"
                      cfTemplateFileName={"lens-carrier-aws-" + formik.values.prefix + "-files-option.yml"}
                      cfTemplateLabel="Verified Deaths Template" />

                    <TextField
                      fullWidth
                      id="ARNofS3Bucket"
                      name="ARNofS3Bucket"
                      label="S3 Bucket ARN"
                      value={formik.values.ARNofS3Bucket}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.ARNofS3Bucket && Boolean(formik.errors.ARNofS3Bucket)}
                      helperText={formik.touched.ARNofS3Bucket ? formik.errors.ARNofS3Bucket : 'ARN should begin with: arn:aws:s3:::'}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      id="KMSKeyARN"
                      name="KMSKeyARN"
                      label="KMS Key ARN (Provide if encrypting)"
                      value={formik.values.KMSKeyARN}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.ARNofS3Bucket && Boolean(formik.errors.KMSKeyARN)}
                      helperText={formik.errors.CustFileKmsKeyArn || 'ARN should begin with: arn:aws:kms:'}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      id="IAMRoleARN"
                      name="IAMRoleARN"
                      label="IAM Role ARN"
                      value={formik.values.IAMRoleARN}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.IAMRoleARN && Boolean(formik.errors.IAMRoleARN)}
                      helperText={formik.touched.IAMRoleARN ? formik.errors.IAMRoleARN : 'ARN should begin with: arn:aws:iam::'}
                      sx={{ mb: 3 }}
                    />

                    <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>LENS uses the provided Role to retrieve your list from the S3 bucket shown. If encrypting list, the KMS Key ARN must be provided for LENS to decrypt.</Typography>

                  </>
                }
                <hr />
                <NotificationPreferences formik={formik} availableEmailAddresses={availableEmailAddresses} existingEmails='preExistingEmails' newEmails='newEmails'/>
              </Box>
            </Box>
          }

          <hr />
          
          <Grid container sx={{ px: 5, pb: 4 }}>
            <Grid item sm={6}>
              <Button LinkComponent={Link} to="/customer-file-setup" sx={{ mt: 3 }} variant="text" startIcon={<ArrowBack />} disabled={!(transferType && availableEmailAddresses) || apiError}>
                Previous Step
              </Button>
            </Grid>
            <Grid item sm={6} sx={{ textAlign: 'right' }}>
              <Button onClick={validateErrors} type="submit" sx={{ mt: 3 }} variant="outlined" disabled={!(transferType && availableEmailAddresses) || apiError}>SAVE & View Summary
              </Button>
            </Grid>
            <Grid item sm={12} sx={{ textAlign: 'center', mt: 2 }}>
              <Typography variant="body2">Would you like to set up your Verified Deaths list later? <Link to="/match-results-file-setup">Skip to Step 3</Link></Typography>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </>
  );
}

VerifiedDeathsFileSetupForm.propTypes = {
  setResult: PropTypes.func.isRequired,
  availableEmailAddresses: PropTypes.arrayOf(PropTypes.object),
  availablePgpKeys: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    keyRotationInYears: PropTypes.number.isRequired,
    publicKey: PropTypes.string.isRequired
  })),
  existingTransferUser: PropTypes.string,
  transferType: PropTypes.string,
  evadataLambdaArn: PropTypes.string,
  evadataLambdaRoleArn: PropTypes.string,
  accountId: PropTypes.string,
  sftpServer: PropTypes.string,
  apiError: PropTypes.bool
};

export default VerifiedDeathsFileSetupForm;