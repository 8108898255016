import {Typography, Link, Skeleton} from '@mui/material';

import {useQuery} from 'src/hooks';
import {getReleaseNotesAuth} from 'src/api';
import PageHeader from 'src/components/PageHeader';
import LoadError from 'src/components/LoadError';


function ReleaseNotes() {
  const {data = [], isLoading, isError} = useQuery(['releaseNotes'], () => getReleaseNotesAuth());

  return (
    <>
      <PageHeader title={"Release Notes"} subtitle={"Click the Link below to view and authenticate to LENS release notes"} />
      {isError ? <LoadError/> :
        <Typography variant="body2" color="text.secondary">
          {isLoading ? <Skeleton width='150px'/> : <Link target="_blank" href={data.url}>{"Release Notes Here"}</Link>}
        </Typography>
      }
    </>
  );
}


export default ReleaseNotes;
