import {QueryClient, QueryClientProvider} from 'react-query';
import {Security} from '@okta/okta-react';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';

import {AppProvider} from 'src/contexts/AppContext';
import AppRoutes from './AppRoutes';
import config from './config/okta';


function App() {
  const queryClient = new QueryClient();
  const oktaAuth = new OktaAuth(config.oidc);

  const restoreOriginalUri = (_oktaAuth,  originalUri) => {
    window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <QueryClientProvider client={queryClient}>  
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <AppProvider>
          <AppRoutes/>
        </AppProvider>
      </Security>
    </QueryClientProvider>  
  );
}

export default App;
