import {useEffect} from 'react';
import {useLocation, Outlet} from 'react-router-dom';
import {useOktaAuth} from '@okta/okta-react';

import {authorize} from 'src/api';
import {useQuery} from 'src/hooks';
import {useAppContext} from 'src/AppContext'
import LoadingIcon from './loadingIcon';


const InitialLoader = () => {
  const {setCarrierConfig, setLensRoles, setOktaUser} = useAppContext();
  const {oktaAuth, authState} = useOktaAuth();

  const location = useLocation();

  const carrierConfig = useQuery('carrierConfig', (() => authorize(oktaAuth)));
  const user = useQuery('userInfo', (() => oktaAuth.getUser()));

  useEffect(() => {
    if(!authState?.isAuthenticated) {
      return;
    }

    if(!user.isLoading) {
      setOktaUser(user.data);
      setLensRoles(user.data?.lens_roles);
    }

    if(!carrierConfig.isLoading) {
      setCarrierConfig(carrierConfig.data);
    }

  }, [carrierConfig, user, setCarrierConfig, setLensRoles, setOktaUser, authState]);

  if (location.pathname === '/' && (carrierConfig.isLoading || user.isLoading)) {
    return <LoadingIcon/>
  }
  
  return (
    <Outlet/>
  );
};

export default InitialLoader;
