import {Grid, Divider} from '@mui/material';
import PropTypes from 'prop-types';

import CustomerListDetails from './customerListDetails';
import MatchResultsDetails from './matchResultsDetails';


function CustomerListCollapsibleContent({rowData}) {
  return (
    <Grid container sx={{p: 2}}>
      <Grid item xs={5}>
        <CustomerListDetails
          auditDate={rowData?.customerList?.auditDate}
          auditUser={rowData?.customerList?.auditUser}
          frequency={rowData?.customerList?.transfer?.frequency}
          frequencyDay={rowData?.customerList?.transfer?.frequencyDay}
          notificationEmails={rowData?.customerList?.notificationEmails}
        />
      </Grid>
      <Grid item xs={1}>
        <Divider sx={{height: '105% !important', mt: '-4px'}} orientation='vertical' variant='middle' display='flex'/>
      </Grid>
      <Grid item xs={5}>
        <MatchResultsDetails
          auditDate={rowData?.matchList?.auditDate}
          auditUser={rowData?.matchList?.auditUser}
          frequency={rowData?.matchList?.transfer?.frequency}
          frequencyDay={rowData?.matchList?.transfer?.frequencyDay}
          reportDates={rowData?.matchList?.transfer?.reportDates}
          holidayCalendarId={rowData?.matchList?.holidayCalendarId}
          annualDmf={rowData?.matchList?.transfer?.annualDmf}
          notificationEmails={rowData?.matchList?.notificationEmails}
        />
      </Grid>
    </Grid>
  );
}

CustomerListCollapsibleContent.propTypes = {
  rowData: PropTypes.object
};

export default CustomerListCollapsibleContent;
