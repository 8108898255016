import {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Box, Alert, Grid, Typography, Card, Switch, styled, FormControlLabel, Button, Tooltip, Divider} from '@mui/material';
import {FileDownloadRounded, InfoRounded} from '@mui/icons-material';
import PropTypes from 'prop-types';

import {editEncryption} from 'src/api';
import {useAppContext} from 'src/AppContext';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import CopyButton from 'src/components/CopyButton';
import {downloadTxtFile} from 'src/utilities/downloadTextFile';
import AddKmsKey from './addKmsKey';
import AddPgpKey from './addPgpKey';


const CardWrapper = styled(Card)(
  ({ theme }) => `
    .card-header {
      border-bottom: 1px solid ${theme.palette.text.primary}22;
      padding-bottom: 15px;
    }
    .copy-click-wrapper {
      float: left;
    }
  `
);

function Loading({ height, marginTop }) {
  return (
    <LoadingIcon contained={true} marginTop={marginTop} height={height} />
  );
}

Loading.propTypes = {
  height: PropTypes.string,
  marginTop: PropTypes.string
};

function ToggleEncryptionKey({data, reload}) {
  const { carrierConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);
  const [keyEnabled, setKeyEnabled] = useState(false);
  const [hasKey, setHasKey] = useState(false);
  const [addingKey, setAddingKey] = useState(false);
  const [newKey, setNewKey] = useState(null);
  const [newKeyId, setNewKeyId] = useState(null);
  const [newRotation, setNewRotation] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const setValue = async function () {
      if (data?.transfer?.sftp?.pgp || data?.transfer?.awsS3?.kmsArn) {
        setHasKey(true);
      }
      if (data?.transfer?.encryptionEnabled === true) {
        setKeyEnabled(true);
      }
      setInitialized(true);
    }
    if (!initialized) {
      setValue().catch((error) => {
        navigate('/500?page=manage-configurations&error=' + error);
      });
    }
  }, [setInitialized, initialized, data?.encryptionEnabled, navigate, data?.transfer?.encryptionEnabled, data?.transfer?.awsS3, data?.transfer?.sftp?.pgp]);

  async function handleToggle() {
    setIsLoading(true);
    let payload = {
      "type": data?.configurationType,
      "filePrefix": data?.file?.prefix,
      "enableEncryption": !keyEnabled
    }
    const result = await editEncryption(carrierConfig?.carrierId, payload);
    if (result?.statusCode === 400 || result?.statusCode === 500) {
      setIsLoading(false);
      window.scrollTo(0, 0);
      setGeneralizedError(true);
    }
    if (result?.type) {
      await reload();
      setKeyEnabled(result?.enableEncryption);
      setIsLoading(false);
    }
  }

  function downloadPGPKey() {
    if (newKey) {
      downloadTxtFile(newKey, 'pgp-' + data?.file?.prefix + '.txt')
    } else {
      downloadTxtFile(data?.transfer?.sftp?.pgp?.publicKey, 'pgp-' + data?.file?.prefix + '.txt')
    }
  }

  function addKey() {
    setAddingKey(true);
  }

  function cancelAddKey() {
    setAddingKey(false);
  }

  return (
    <Grid item xs={12}>
      <CardWrapper>
        <Alert className={generalizedError ? 'show' : 'hide'} severity="error" sx={{ mx: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
        <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pt: 2 }}>
          <Grid item xs={6} sx={{ display: 'flex' }}>
            {hasKey &&
              <>
                <Tooltip sx={{ ml: 1 }} title={keyEnabled ? 'Turn OFF Encryption' : 'Turn ON Encryption'}>
                  <FormControlLabel
                    control={
                      <Switch
                        display="inline"
                        checked={keyEnabled}
                        onChange={handleToggle}
                        name="keyEnabled"
                      />
                    }
                  />
                </Tooltip>
                <Divider orientation="vertical" sx={{ height: '30px', display: 'inline-flex', mr: 3 }} />
                <Typography variant="subtitle1">Encryption Key</Typography>
              </>
            }
            {!hasKey && <Typography variant="subtitle1">Encryption Key</Typography>}
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'right' }}>
            {(!addingKey && !hasKey) && <Button onClick={addKey} className="add-key" sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Add Key</Button>}
            {(addingKey && !hasKey) && <Button onClick={cancelAddKey} className="cancel-add-key" sx={{ mt: '-10px', mb: '-10px' }} variant="text">Cancel</Button>}
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ px: 3, pb: 3 }}>
          <Grid item xs={12}>
            {(!isLoading && hasKey) &&
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 1, mt: 4 }}>
                <Typography variant="body1"><strong>Key's Status:</strong> {keyEnabled ? 'Enabled' : 'Disabled'} </Typography>
                <Tooltip sx={{ ml: 1 }} title='To enable or disable the key shown. Toggle ON or OFF encryption for this list.'><InfoRounded color="primary" /></Tooltip>
              </Box>
            }
            {(!isLoading && hasKey && data?.transfer?.sftp) &&
              <>
                <Typography sx={{ mb: 1 }} variant="body1"><strong>PGP Key ID: </strong> {newKeyId || data?.transfer?.sftp?.pgp?.keyId}</Typography>
                <Typography variant="body1"><strong>Key Rotation: </strong> {newRotation || data?.transfer?.sftp?.pgp?.keyRotationInYears} Years <Typography display="inline" variation="body2" color="text.secondary">(Expires {data?.transfer?.sftp?.pgp?.keyExpiration} )</Typography></Typography>
                <Grid item xs={12} sx={{ pt: 1 }}>
                  <div className='copy-click-wrapper'>
                    <CopyButton paddingLeft={0} buttonText="Copy Public Key" toolTipText="Copy Public Key" textToCopy={newKey || data?.transfer?.sftp?.pgp?.publicKey}></CopyButton>
                  </div>
                  <Button className="pgp-button" onClick={downloadPGPKey} sx={{ marginTop: '-3px', marginRight: '-10px' }}>Download Public Key<FileDownloadRounded sx={{ ml: 1 }} /></Button>
                </Grid>
                <Typography variant="body2" sx={{ mt: 1 }}>When encryption is turned on, use the public key to encrypt your list. Visit <Link to="/encryption-keys">Encryptions Keys</Link> page to change key.</Typography>
              </>
            }
            {(!isLoading && hasKey && data?.transfer?.awsS3) &&
              <>
                <Typography sx={{ mb: 1 }} variant="body1"><strong>KMS Key ARN: </strong> {newKey || data?.transfer?.awsS3?.kmsArn}</Typography>
                <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>When encryption is turned on, key ARN is used by LENS to decrypt your list. Visit <Link to="/encryption-keys">Encryptions Keys</Link> page to change key.</Typography>
              </>
            }
            {(!hasKey && !addingKey) && <Typography variant="body1" sx={{ mt: 1, px: 3, pt: 2 }}>This list is currently not encrypted with a {data?.transfer?.sftp ? 'PGP' : 'KMS'} key. Add key and start encrypting your list.</Typography>
            }
            {(isLoading && data?.transfer?.sftp) &&
              <Box>
                <Loading height="221px" marginTop="20px" />
              </Box>
            }
            {(isLoading && data?.transfer?.awsS3) &&
              <Box>
                <Loading height="120px" marginTop="10px" />
              </Box>
            }
          </Grid>
        </Grid>

        {(addingKey && data?.transfer?.awsS3) && <AddKmsKey data={data} reload={reload} setAddingKey={setAddingKey} setNewKey={setNewKey} setHasKey={setHasKey} setKeyEnabled={setKeyEnabled} />}

        {(addingKey && data?.transfer?.sftp) && <AddPgpKey data={data} reload={reload} setAddingKey={setAddingKey} setNewKey={setNewKey} setHasKey={setHasKey} setKeyEnabled={setKeyEnabled} setNewKeyId={setNewKeyId} setNewRotation={setNewRotation} />}

      </CardWrapper>
    </Grid>
  )
}

ToggleEncryptionKey.propTypes = {
  data: PropTypes.object,
  reload: PropTypes.func
};

export default ToggleEncryptionKey;
