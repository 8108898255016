import { useAppContext } from "src/AppContext";
import { useEffect, useState } from "react";
import { filterItems } from "../../filterItems";

function DocumentationItems() {

  const { carrierConfig, lensRoles } = useAppContext();
  const [processOverviewLink, setProcessOverviewLink] = useState('/process-overview');
  const [displayItems, setDisplayItems] = useState([]);

  useEffect(() => {
    if (carrierConfig?.status === 'prospect' || (carrierConfig?.status === 'on-boarding' && lensRoles && !lensRoles?.includes('administrator'))) {
      setProcessOverviewLink('/');
    }
  }, [processOverviewLink, carrierConfig?.status, lensRoles])

  useEffect(() => {
    const menuItems = [
      {
        name: 'Process Overview',
        link: processOverviewLink,
        statuses: ['live', 'on-boarding', 'prospect'],
        roles: ['administrator', 'collaborator']
      },
      {
        name: 'Getting Started',
        link: '/getting-started',
        statuses: ['live', 'on-boarding', 'prospect'],
        roles: ['administrator', 'collaborator']
      },
      {
        name: 'Guides & Templates',
        statuses: ['live', 'on-boarding', 'prospect'],
        roles: ['administrator', 'collaborator'],
        items: [
          {
            name: 'Creating Customer List',
            link: '/customer-file',
          },
          {
            name: 'Creating Verified Deaths List',
            link: '/verified-deaths-file',
          },
          {
            name: 'Receiving Match Results',
            link: '/match-results-file',
          }
        ]
      },
      {
        name: "Evadata's Match Categories",
        statuses: ['live', 'on-boarding', 'prospect'],
        roles: ['administrator', 'collaborator'],
        link: '/evadatas-match-categories',
      },
      {
        name: 'Helpful Guidance',
        statuses: ['live', 'on-boarding', 'prospect'],
        roles: ['administrator', 'collaborator'],
        link: '/helpful-guidance',
      },
      {
        name: 'Release Notes',
        statuses: ['live', 'on-boarding', 'prospect'],
        roles: ['administrator', 'collaborator'],
        link: '/release-notes'
      }
    ];

    if (lensRoles && carrierConfig?.status) {
      let filteredItems = menuItems.filter((item) => filterItems(item, lensRoles, carrierConfig?.status));

      const _displayDocumentationItems = [
        {
          heading: 'Documentation',
          items: filteredItems
        }
      ];

      if (filteredItems.length) {
        setDisplayItems(_displayDocumentationItems);
      }

    }

  }, [carrierConfig?.status, lensRoles, processOverviewLink])

  return displayItems;

}

export default DocumentationItems;
