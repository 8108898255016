import {useOktaAuth} from '@okta/okta-react';

import {useQuery} from 'src/hooks';


export function useCurrentUserEmail(){
  const {oktaAuth, authState} = useOktaAuth();

  const {data: userEmail, isError} = useQuery(['user'], () => oktaAuth.getUser(), {enabled: authState?.isAuthenticated, select: (user) => user.email});

  return {userEmail, isError};
}
