import {useEffect} from 'react';
import {Typography, styled, Box, Paper} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import { useQuery } from 'src/hooks';
import {useAppContext} from 'src/AppContext.js';
import {getCustomerFileConfigurations, getDeathFileConfigurations, getEmailAddresses, getMatchFileConfigurations} from 'src/api';
import OnboardingStepper from 'src/components/Stepper';
import CustomerFileSetupSummary from './customerFileSetupSummary';
import CustomerFileSetupForm from './customerfileSetupForm';


const FormWrapper = styled(Box)(
  ({ theme }) => `
    .clear-icon {
      cursor: pointer;
    }
    .ssh-link {
      color: ${theme.palette.primary.main}
    }
    .recommended {
      font-size: 14px; 
      color: #666;
    }

    .username {
      width: 90%;
    }
    .MuiTypography-h6 {
      font-weight: 700;
    }
  `
);

function CustomerFileSetup() {
  const { carrierConfig } = useAppContext();
  const navigate = useNavigate();
  const {lensRoles } = useAppContext();

  const showSummary = carrierConfig?.onBoarding?.customer_file_completed;

  useEffect(() => {
    if (lensRoles && !lensRoles?.includes('administrator')) {
      navigate('/400');
    }
  }, [lensRoles, navigate])

  const {data: config, isError: configsError, refetch: refetchConfigs} = useQuery(['getCustomerConfigs', carrierConfig?.carrierId], () => getCustomerFileConfigurations(carrierConfig?.carrierId), {select: (configs) => configs?.[0]});
  const {data: deathConfig, isError: deathConfigsError} = useQuery(['getDeathConfigs', carrierConfig?.carrierId], () => getDeathFileConfigurations(carrierConfig?.carrierId), {select: (configs) => configs?.[0]});
  const {data: matchConfig, isError: matchConfigsError} = useQuery(['getMatchConfigs', carrierConfig?.carrierId], () => getMatchFileConfigurations(carrierConfig?.carrierId), {select: (configs) => configs?.[0]});
  const {data: availableEmailAddresses, isError: emailsError} = useQuery(['getEmails', carrierConfig?.carrierId], () => getEmailAddresses(carrierConfig?.carrierId));

  const apiError = configsError || deathConfigsError || matchConfigsError || emailsError;

  return (
    <FormWrapper>
      <OnboardingStepper step={0} customerResult={config} deathResult={deathConfig} matchResult={matchConfig} />
      <Paper sx={{ maxWidth: '720px', margin: '30px auto 0', p: 0 }}>
        <Box sx={{ textAlign: 'center', pt: 3, pb: 4, backgroundColor: 'background.default' }}>
          <Typography variant="overline" color="text.primary">Step 1</Typography>
          <Typography variant="h5" color="primary.text">{showSummary || config ? 'Summary of Customer List' : 'Customer List Setup'}</Typography>
        </Box>
        {showSummary || config ? <CustomerFileSetupSummary
          file={config?.file} 
          transfer={config?.transfer}
          notificationEmails={config?.notificationEmails}
          pgp={config?.pgp}
          sftpServer={config?.sftpServer}
          apiError={configsError}/>
          :
          <CustomerFileSetupForm refetchConfigs={refetchConfigs} availableEmailAddresses={availableEmailAddresses} apiError={apiError}/>
        }
      </Paper>
    </FormWrapper>
  );
}

export default CustomerFileSetup;
