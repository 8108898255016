import {useEffect, useState} from 'react';
import {Field, FormikProvider, useFormik} from 'formik';
import * as yup from 'yup';
import {Typography, Button, Box, FormControl, Checkbox, FormControlLabel, FormHelperText, Alert, Skeleton} from '@mui/material';
import PropTypes from 'prop-types';

import {editSources, getSources} from 'src/api';
import {useAppContext} from 'src/AppContext';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import LoadError from 'src/components/LoadError';
import {useQuery} from 'src/hooks';


function Loading() {
  return (
    <LoadingIcon contained={true} />
  );
}

const validationSchema = yup.object().shape({
  sources: yup
    .array()
    .min(1, 'Field is required')
})

function EditSources({data, reload, setEditingSources, updateSources, updatedSources}) {
  const { carrierConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);

  const {data: availableSources, isLoading: isSourcesLoading, isError} = useQuery(['availableSources', carrierConfig?.carrierId], () => getSources(carrierConfig?.carrierId));

  const sourcesMapping = {
    "carrier" : "Carrier-to-Carrier",
    "dmf" : "DMF",
    "funeral-home-obituary" : "Obit Funeral Home",
    "newspaper-obituary" : "Obit Newspaper",
    "state" : "State"
  }

  const formik = useFormik({
    initialValues: {
      sources: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        let customerFilePrefix = data?.customerFilePrefix;
        let payload = { 'sources': values.sources };
        setIsLoading(true);
        const result = await editSources(carrierConfig?.carrierId, customerFilePrefix, payload);
        if (result.statusCode === 400 || result.statusCode === 500) {
          setIsLoading(false);
          window.scrollTo(0, 0);
          setGeneralizedError(true);
        }
        if (result.sources) {
          await reload();
          updateSources(result.sources);
          setIsLoading(false);
          setEditingSources(false);
        }
      }
      catch (e) {
        setIsLoading(false);
        throw (e);
      }
    },
  });

  useEffect(() => {
    const setValue = async function () {
      if (updatedSources) {
        await formik.setFieldValue('sources', updatedSources);
        setGeneralizedError(false);
      } else {
        await formik.setFieldValue('sources', data?.sources);
      }
      setInitialized(true);
    }
    if (!initialized && (formik?.values?.sources?.length === 0) && data?.sources) {
      setValue().catch((error) => setGeneralizedError(true));
    }
  }, [formik, setInitialized, data?.sources, initialized, updatedSources])

  return (
    <>
      {isLoading &&
        <Box sx={{ minHeight: '339px' }}>
          <Loading />
        </Box>
      }
      
        <Box className={isLoading ? 'hide' : 'show'}>
          <Alert className={generalizedError ? 'show' : 'hide'} severity="error" sx={{ mx: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              {isError ? <LoadError sx={{minHeight: '250px'}}/> :
                <Box sx={{ textAlign: 'left', width: '100%', px: 3 }}>
                  <Typography variant="subtitle1" color="text.primary" sx={{ mt: 4, mb: 2 }}>Sources your customer records will be matched against:</Typography>
                  <FormHelperText sx={{ mb: 2, mt: 1 }} error={formik.errors.sources && formik.touched.sources}>Minimum of one source required.*</FormHelperText>
                  <Box sx={{ mb: 4 }} role="optgroup" aria-labelledby="checkbox-group">
                    <FormControl component="fieldset">
                      {isSourcesLoading ?
                        <Typography display='inline-flex' ml={2}><Skeleton width='18px'/><Skeleton sx={{ml: 1}} width='232px'/></Typography>
                        :
                        availableSources?.sort().map((source) => {
                          return <Field as={FormControlLabel} key={source} type="checkbox" name='sources' label={sourcesMapping[source]} value={source} control={<Checkbox />} sx={{ ml: 1 }} />
                        })
                      }
                    </FormControl>
                    {formik.errors.sources && formik.touched.sources && <FormHelperText sx={{ mb: 2, ml: 2 }} error={formik.errors.sources && formik.touched.sources}>*One source selection is required.</FormHelperText>}
                  </Box>
                </Box>
              }
              <hr />
              <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
                <Button type="submit" variant="contained" disabled={isSourcesLoading || isError}>
                  Save Changes
                </Button>
              </Box>
            </form>
          </FormikProvider>
        </Box>
    </>
  )
}

EditSources.propTypes = {
  data: PropTypes.object, 
  reload: PropTypes.func,
  setEditingSources: PropTypes.func,
  updateSources: PropTypes.func, 
  updatedSources: PropTypes.array
};

export default EditSources;
