import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Typography, styled, Box, Paper} from '@mui/material';

import {useQuery} from 'src/hooks';
import {getEmailAddresses, getDeathFileConfigurations, getCustomerFileConfigurations, getMatchFileConfigurations} from 'src/api';
import {useAppContext} from 'src/AppContext.js';
import OnboardingStepper from 'src/components/Stepper';
import VerifiedDeathsFileSetupForm from './verifiedDeathsFileSetupForm';
import VerifiedDeathsFileSetupSummary from './verifiedDeathsFileSetupSummary';


const FormWrapper = styled(Box)(
  ({ theme }) => `
    .clear-icon {
      cursor: pointer;
    }
    .ssh-link {
      color: ${theme.palette.primary.main}
    }
    .recommended {
      font-size: 14px; 
      color: #666;
    }
    .username {
      width: 90%;
    }
    .MuiTypography-h6 {
      font-weight: 700;
    }
  `
);

function VerifiedDeathsFileSetup() {
  const [result, setResult] = useState(null);
  const { carrierConfig } = useAppContext();
  const navigate = useNavigate();
  const {lensRoles } = useAppContext();

  const showSummary = carrierConfig?.onBoarding?.death_file_completed;

  useEffect(() => {
    if (lensRoles && !lensRoles?.includes('administrator')) {
      navigate('/400');
    }
  }, [lensRoles, navigate])

  const {data: customerConfig, isError: configsError} = useQuery(['getCustomerConfigs', carrierConfig?.carrierId], () => getCustomerFileConfigurations(carrierConfig?.carrierId), {select: (configs) => configs?.[0]});
  const {data: config, isError: deathConfigsError} = useQuery(['getDeathConfigs', carrierConfig?.carrierId], () => getDeathFileConfigurations(carrierConfig?.carrierId), {select: (configs) => configs?.[0]});
  const {data: matchConfig, isError: matchConfigsError} = useQuery(['getMatchConfigs', carrierConfig?.carrierId], () => getMatchFileConfigurations(carrierConfig?.carrierId), {select: (configs) => configs?.[0]});
  const {data: availableEmailAddresses, isError: emailsError} = useQuery(['getEmails', carrierConfig?.carrierId], () => getEmailAddresses(carrierConfig?.carrierId));

  const availablePgpKeys = customerConfig?.pgp;
  const apiError = configsError || deathConfigsError || matchConfigsError || emailsError;

  useEffect(() => {
    if (config) {
      setResult(config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  // determine if the pgpKey used in the death file is the same as the customer file
  // if the keys are the same, then only send the key id. The summary and form components handle
  // rendering appropriately
  useEffect(() => {
    if(customerConfig && result) {
      const customerFilePgpKey = customerConfig?.pgp?.id;
      const deathFilePgpKey = result?.pgp?.id;

      if(customerFilePgpKey && deathFilePgpKey) {
        if(customerFilePgpKey === deathFilePgpKey) {
          const {pgp, ..._result} = result;
          _result.pgp = {
            id: pgp.id
          };
          setResult(_result);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerConfig, result]);

  return (
    <FormWrapper>
      <OnboardingStepper step={1} customerResult={customerConfig} deathResult={result} matchResult={matchConfig} />
      <Paper sx={{ maxWidth: '720px', margin: '30px auto 0' }}>
        <Box sx={{ textAlign: 'center', pt: 3, pb: 4, backgroundColor: 'background.default' }}>
          <Typography variant="overline" color="text.primary">Step 2</Typography>
          <Typography variant="h5" color="primary.text">{result ? 'Summary of Verified Deaths List' : 'Verified Deaths List Setup'}</Typography>
        </Box>
        {showSummary || result ? 
          <VerifiedDeathsFileSetupSummary 
            result={result}
            file={result?.file}
            transfer={result?.transfer}
            notificationEmails={result?.notificationEmails}
            sftpServer={result?.sftpServer}
            pgp={result?.pgp}
            accountId={customerConfig?.transfer?.awsS3?.carrierIntegrationAccountId}
            customerFileUser={customerConfig?.transfer?.sftp?.username}
            setResult={setResult}
            apiError={deathConfigsError || configsError}/>
          :
          <VerifiedDeathsFileSetupForm 
            setResult={setResult} 
            availableEmailAddresses={availableEmailAddresses} 
            availablePgpKeys={availablePgpKeys}
            transferType={customerConfig?.transfer?.type}
            existingTransferUser={customerConfig?.transfer?.sftp?.username}
            sftpServer={customerConfig?.transfer?.sftp?.sftpServer}
            existingTransferFrequency={customerConfig?.transfer?.frequency}
            accountId={customerConfig?.transfer?.awsS3?.carrierIntegrationAccountId}
            evadataLambdaArn={customerConfig?.transfer?.awsS3?.evadataLambdaArn}
            evadataLambdaRoleArn={customerConfig?.transfer?.awsS3?.evadataLambdaRoleArn}
            apiError={apiError}/>
        }
      </Paper>
    </FormWrapper>
  );
}

export default VerifiedDeathsFileSetup;
