import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {LockRounded} from '@mui/icons-material';
import {Typography, Skeleton} from '@mui/material';

import PageHeader from 'src/components/PageHeader';
import PgpTable from './PgpTable';
import AwsTable from './AwsTable';
import SkeletonTable from '../skeletonTable';


function EncryptionTable({uniqueKeyGroups, viewDetails, viewAwsDetails, isLoading, apiError}) {
  const [uniqueKeyCount, setUniqueKeyCount] = useState(0);

  useEffect(() => {
    setUniqueKeyCount(uniqueKeyGroups?.length);
  }, [uniqueKeyGroups]);

  return (
    <>
      <PageHeader className="encryption-title" title="Encryption Keys" subtitle="View encryption keys used to encrypt and decrypt lists." icon={<LockRounded />} />
      {isLoading || apiError ?
        <>
          {!apiError && <Typography variant='h5'><Skeleton width='200px'/></Typography>}
          <SkeletonTable apiError={apiError}/>
          <SkeletonTable apiError={apiError}/>
        </>
        :
        <>
          <Typography variant="h5">{(uniqueKeyGroups?.[0]?.record?.transfer?.sftp ? 'PGP Keys' : 'KMS Keys') + ` (${uniqueKeyCount})`}</Typography>
          {uniqueKeyGroups?.[0]?.record?.transfer?.sftp ?
            <PgpTable uniqueKeyGroups={uniqueKeyGroups} viewDetails={viewDetails} /> :
            <AwsTable uniqueKeyGroups={uniqueKeyGroups} viewAwsDetails={viewAwsDetails} />
          }
        </>
      }
    </>
  );
}

EncryptionTable.propTypes = {
  uniqueKeyGroups: PropTypes.array,
  viewDetails: PropTypes.func, 
  viewAwsDetails: PropTypes.func,
  apiError: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default EncryptionTable;