import {Typography} from '@mui/material';
import PropTypes from 'prop-types';


function AwsDetails({aws}) {
  return (
    <>
      <Typography color="text.secondary" variant="overline">PROVIDED BY MY ORGANIZATION</Typography>
      <Typography sx={{mb: 1}}><strong>AWS Account ID:</strong> {aws?.carrierIntegrationAccountId}</Typography>
      <Typography sx={{mb: 1}}><strong>S3 Bucket ARN:</strong> {aws?.s3Bucket}</Typography>
      {aws?.path && aws?.path !== '/' &&
        <Typography sx={{mb: 1, mt: 1}}><strong>Folder Path:</strong> {aws?.path}</Typography>
      }
      <Typography sx={{mb: 1}}><strong>IAM Role ARN:</strong> {aws?.iamRole}</Typography>
    </>
  );
}

AwsDetails.propTypes = {
  aws: PropTypes.object
};

export default AwsDetails;
