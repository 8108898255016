import {Typography, styled, Button, Box} from '@mui/material';


const ErrorWrapper = styled(Box)(
  () => `
    min-height: calc(60vh);
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-content: center;
  `
);

function LoadError({...props}) {
  function refreshPage() {
    window.location.reload();
  }

  return (
    <ErrorWrapper {...props}>
      <Box sx={{width: '100%', alignSelf: 'center'}}>
        <Typography color='text.secondary' sx={{mb: 2}}>We're having trouble loading the content, please try again.</Typography>
        <Button size='small' variant='contained' onClick={refreshPage}>Retry</Button>
      </Box>
    </ErrorWrapper>
  );
}

export default LoadError;
