import {useState} from 'react';
import {Box, styled, Tooltip, IconButton} from '@mui/material';
import {FileDownloadRounded, HourglassTopRounded, ErrorOutline} from '@mui/icons-material';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';

import {getErrorFileSignedUrl} from 'src/api';
import {useAppContext} from 'src/AppContext.js';


const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} componentsProps={{ tooltip: { className: className } }} />
  ))(() => (`
    color: #D9000B;
    background-color: #fbe5e7;
    width: 110px;
    border-bottom: 2px solid #D9000B;
    .MuiTooltip-arrow { 
      color: #fbe5e7;
    }
  `)
);

const DownloadsWrapper = styled(Box)(
  ({ theme }) => `
    .error{
      border: 2px solid ${theme.colors.error.main};
      z-index: 1
    }
    MuiTooltip-tooltip.downloads-tooltip {
      color: ${theme.colors.error.main} !important;
      background-color: red !important;
    }
  `
);

function ErrorFileDownload({fileType, dataLoadId, handleDownloadError}) {
  const { carrierConfig } = useAppContext();

  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { carrierId } = carrierConfig;

  function handleErrors() {
    setDownloadInProgress(false);
      setHasError(true);
      handleDownloadError();
  }

  async function handleDownload() {
    try {
      setDownloadInProgress(true);
      setHasError(false);
      const { url, filename } = await getErrorFileSignedUrl(carrierId, fileType, dataLoadId);
      const result = await fetch(url);
      if (!filename || !url) {
        setHasError(true);
      }
      const data = await result.blob();
      fileDownload(data, filename);
      setDownloadInProgress(false);
    } catch (error) {
      handleErrors();
    }
  };

  return (
    <DownloadsWrapper>
      <Box className={hasError && 'error'}>
        {downloadInProgress ?
          <HourglassTopRounded color='primary'/>
          :
          <IconButton size='small' color='primary'>
            <Tooltip arrow title='Download Errors'>
              <FileDownloadRounded onClick={handleDownload} key={dataLoadId}/>
            </Tooltip>
          </IconButton>
        }
        {hasError &&
          <CustomTooltip className="downloads-tooltip" title="Error retrieving file, try again.">
            <ErrorOutline color="error" sx={{ ml: 2, textAlign: 'right' }} />
          </CustomTooltip>
        }
      </Box>
    </DownloadsWrapper>
  );
}

ErrorFileDownload.propTypes = {
  fileType: PropTypes.string, 
  dataLoadId: PropTypes.number, 
  handleDownloadError: PropTypes.func
};

export default ErrorFileDownload;
