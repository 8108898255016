import {Typography, Chip, Box} from '@mui/material';
import {UnpublishedRounded, CheckCircleRounded} from '@mui/icons-material';

import {sourceDisplayMap} from 'src/utilities/mapSourceDisplay';


const customerListColumns = [
  { 
    field: 'customerList', 
    headerName: 'Customer List', 
    renderCell: (params) => {
      return (
        <>
          {params?.row?.customerList?.nickname && <Typography variant='body2'>{params.row.customerList.nickname}</Typography>}
          <Typography variant='caption'>{params.row.customerFileName}</Typography>
        </>
      );
    }
  },
  {
    field: 'matchWorkflow',
    headerName: 'Match Workflow',
    renderCell: (params) => params?.row?.matchFileNames?.map((fileName) => {
      return (
        <>
          {params?.row?.matchList?.nickname && <Typography variant='body2'>{params.row.matchList.nickname}</Typography>}
          <Typography variant='caption'>{fileName}</Typography>
        </>
      );
    })
  },
  {
    field: 'sources',
    headerName: 'Sources',
    renderCell: (params) => params?.row?.matchList?.sources?.map((source) => {
      return (
        <Typography key={source} variant='body2'>{sourceDisplayMap[source]}</Typography>
      );
    })
  },
  { 
    field: 'matchCategories', 
    headerName: 'Match Categories',
    renderCell: (params) => {
      return (
        <div className="wrap-cell">
          {params?.row?.matchList?.categories?.map((cat) => <Chip key={cat} sx={{mr: 1, pl: '3px', pr: '3px', mb: '3px', width: '50px'}} size="small" label={cat}/>)}
        </div>
      );
    }
  },
  {
    field: 'matchFileStatus',
    headerName: 'Match File Status',
    renderCell: (params) => {
      return (
        <Box sx={{whiteSpace: 'nowrap', mr: 6}}>
          <Typography variant='caption'>
            {params?.row?.matchList?.disabled ?
              <>
                <UnpublishedRounded color='error' sx={{float: 'left', mt: '-1px', mr: '5px', width: '16px'}}/> Deactivated
              </>
              :
              <>
                <CheckCircleRounded color='success' sx={{float: 'left', mt: '-1px', mr: '5px', width: '16px'}}/> Activated
              </>
            }
          </Typography>
        </Box>
      );
    }
  }
];

export default customerListColumns;
