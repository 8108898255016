import { React, useEffect, useState } from 'react';
import _ from 'lodash';

import { Grid, Typography, Tooltip, Button, Card, Table, TableHead, TableRow, TableCell, TableBody, Box, Alert, TextField, styled } from '@mui/material';
import { ArrowBackRounded, CheckRounded, EditRounded } from '@mui/icons-material';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import transLocationsIcon from 'src/images/transLocationsIcon.svg';
import SFTPSecureIcon from 'src/images/SFTPSecure.png';
import CopyButton from 'src/components/CopyButton';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import DateModifiedCard from 'src/components/DateModifiedCard';
import { editSftpLocations, editAwsLocations } from 'src/api';
import { useAppContext } from 'src/AppContext';
import CloudTemplates from '../CloudTemplates';
import TooltipInline from '../TooltipInline';
import DisplayAccountId from '../DisplayAccountId';
import DisplayEditSubdirectory from './displayEditSubdirectory';
import DisplayEditSftpCredentials from './displayEditSftpCredentials';
import DisplayEditFrequency from './displayEditFrequency';
import PropTypes from 'prop-types';
import DisplayTableRow from './displayTableRow';

import * as Constants from 'src/constants';
import DisplayFileSample from 'src/components/DisplayFileSample';

const CardWrapper = styled(Card)(
  ({ theme }) => `
    .card-header {
      border-bottom: 1px solid ${theme.palette.text.primary}22;
      padding-bottom: 15px;
    }
    .cloud-template-button {
      margin-left: -10px;
      font-weight: 00;
      &:hover {
        background-color: transparent !important;
      }
    }
    .pgp-button {
      margin-left: -10px;
      font-weight:500;
      color: grey;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent !important;
      }
    }
  `
);

const LoadingWrapper = styled(Box)(
  () => `
    .aws-loader {
      min-height: 651px;
    }
    .sftp-loader {
      min-height: 576px;
    }
  `
);

function Loading() {
  return (
    <LoadingIcon contained={true} />
  );
}

yup.addMethod(yup.string, 'containsId', function (id, message) {
  return this.test('containsId', message, function (value) {
    if (value && (value.length > 0)) {
      return value.includes(id);
    }
    else {
      return true;
    }
  });
});

const validationSchema = yup.object().shape({
  newHostname: yup
    .string()
    .when('awsAccountID', (awsAccountID) => {
      if (!awsAccountID) {
        return yup.string()
          .matches(/^(?!https:\/\/).*$/, "Do not include https:// at the beginning of the SFTP address.")
          .matches(/^(?!http:\/\/).*$/, "Do not include http:// at the beginning of the SFTP address.")
          .matches(/^(?!^\d+$).*$/, "Incorrect format entered.") //do not allow hostname that is all numbers
          .matches(/^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))*$/, "Incorrect format entered.")
          .required('This field is required.')
      } else {
        return yup.string()
      }
    }),
  newPortNum: yup
    .string()
    .when('awsAccountID', (awsAccountID) => {
      if (awsAccountID === '') {
        return yup
          .string()
          .required('Field is required')
          .matches(/^\d+$/, "Numeric only")
          .max(5)
      } else {
        return yup.string()
      }
    }),
  newBucket: yup
    .string()
    .when('awsAccountID', (awsAccountID) => {
      if (awsAccountID?.length > 0) {
        return yup.string().required('Field is required')
          .matches(/^arn:aws:s3:::/, "Incorrect format entered. ARN should begin with: arn:aws:s3:::")
          .matches(/^\S*$/, "Incorrect format entered. ARN should not include spaces.")
      } else {
        return yup.string()
      }
    }),
  newRole: yup
    .string()
    .when(['awsAccountID'], (awsAccountID) => {
      if (awsAccountID?.length > 0) {
        return yup.string()
          .matches(/^arn:aws:iam::/, "Incorrect format entered. ARN should begin with: arn:aws:iam::")
          .matches(/^\S*$/, "Incorrect format entered. ARN should not include spaces.")
          .containsId(awsAccountID, 'ARN entered is incorrect. ARN should include account ID: arn:aws:iam::<AWSAccountID>')
          .required('Field is required')
      } else {
        return yup.string()
      }
    }),
  newFolderPath: yup
    .string()
    .when(['newSubdirectory'], () => {
      return yup.string()
    })
});

function TransferDetails({ hideDetails, data, matchConfigs, customerConfigs, transFreq, associatedData, availableFrequencies, removedFrequencies, reloadKeys }) {
  const { carrierConfig } = useAppContext();
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updatedHostname, setUpdatedHostname] = useState(data?.transfer?.sftp?.host);
  const [updatedPort, setUpdatedPort] = useState(data?.transfer?.sftp?.port);
  const [updatedBucket, setUpdatedBucket] = useState(data?.transfer?.awsS3?.s3Bucket);
  const [updatedRole, setUpdatedRole] = useState(data?.transfer?.awsS3?.iamRole);
  const [generalizedError, setGeneralizedError] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [showCompletedAlert, setShowCompletedAlert] = useState(false);
  const [formInitIsSet, setFormInitIsSet] = useState(false);
  const isMatchTransfer = data?.configurationType === 'matches';
  const [anchorEl, setAnchorEl] = useState(null);
  const [subdirectory, setSubdirectory] = useState(null);
  const [frequency, setFrequency] = useState(transFreq);
  const [freqDay, setFreqDay] = useState();
  const [reportDates, setReportDates] = useState();
  const [holidayCalendarId, setHolidayCalendarId] = useState();
  const [annualDmf, setAnnualDmf] = useState(data?.transfer?.annualDmf);
  const [prefix, setPrefix] = useState("");
  const [configType, setConfigType] = useState("");
  const [custFilePrefix, setCustFilePrefix] = useState(null);
  const [editForm, setEditForm] = useState(false);
  const [editingSubdirectory, setEditingSubdirectory] = useState(false);
  const [editingSftpCredentials, setEditingSftpCredentials] = useState(false);
  const [editingFrequency, setEditingFrequency] = useState(false);
  const [tempAssociatedData, setTempAssociatedData] = useState(null);
  const open = Boolean(anchorEl);

  function toggleEditForm() {
    setEditForm(!editForm);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function editSftpCredentials() {
    toggleEditForm();
    setEditingSftpCredentials(true);
    handleClose();
  };

  function editSubdirectory() {
    toggleEditForm();
    setEditingSubdirectory(true);
    handleClose();
  };

  function editFrequency() {
    toggleEditForm();
    setEditingFrequency(true);
    handleClose();
  }

  function handleEditClose() {
    setEditingSftpCredentials(false);
    setEditingSubdirectory(false);
    setEditingFrequency(false)
    toggleEditForm();
  };

  const handleClick = (event, data) => {
    if (data?.transfer?.sftp) {
      setSubdirectory(data?.transfer?.sftp?.path);
    } else {
      setSubdirectory(data?.transfer?.awsS3?.path);
    }
    setFrequency(data?.transfer?.frequency);
    setFreqDay(data?.transfer?.frequencyDay);
    setReportDates(data?.transfer?.reportDates);
    setAnnualDmf(data?.transfer?.annualDmf);
    setPrefix(data?.file?.prefix);
    setHolidayCalendarId(data?.holidayCalendarId);
    setConfigType(data?.configurationType);
    if (data?.customerFilePrefix) {
      setCustFilePrefix(data?.customerFilePrefix);
    }
    setAnchorEl(event.currentTarget);
  };
  

  const formik = useFormik({
    initialValues: {
      newHostname: data?.transfer?.sftp?.host,
      newPortNum: data?.transfer?.sftp?.port,
      awsAccountID: data?.transfer?.awsS3?.carrierIntegrationAccountId,
      newBucket: '',
      newRole: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let prefix = '';
      if (data?.configurationType === 'matches') {
        prefix = data?.customerFilePrefix;
      } else {
        prefix = data?.file?.prefix;
      }
      let payload = {
        "locations": []
      }
      if (associatedData && (data?.transfer?.type === 'sftp')) {
        associatedData.forEach((item) => {
          let _sftpPrefix = '';
          if (item?.configurationType === 'matches') {
            _sftpPrefix = item?.customerFilePrefix;
          } else {
            _sftpPrefix = item?.file?.prefix;
          }
          let location = {
            "type": item?.configurationType,
            "customerFilePrefix": _sftpPrefix,
            "host": values?.newHostname,
            "port": values?.newPortNum
          }
          payload.locations.push(location);
        })
      } else if ((associatedData.length > 1) && (data?.transfer?.type === 'awsS3')) {
        associatedData.forEach((item) => {
          let _awsPrefix = '';
          if (item?.configurationType === 'matches') {
            _awsPrefix = item?.customerFilePrefix;
          } else {
            _awsPrefix = item?.file?.prefix;
          }
          let location = {
            "type": item?.configurationType,
            "filePrefix": _awsPrefix,
            "bucket": values?.newBucket,
            "role": values?.newRole
          }
          payload.locations.push(location);
        })
      } else if (!associatedData && (data?.transfer?.type === 'sftp')) {
        let location = {
          "type": data?.configurationType,
          "customerFilePrefix": prefix,
          "host": values?.newHostname,
          "port": values?.newPortNum
        }
        payload.locations.push(location);
      } else if (data?.transfer?.type === 'awsS3') {
        let location = {
          "type": data?.configurationType,
          "filePrefix": prefix,
          "bucket": values?.newBucket,
          "role": values?.newRole
        }
        payload.locations.push(location);
      }
      try {
        if (data?.transfer?.sftp) {
          setIsLoading(true);
          setShowForm(false);
          const result = await editSftpLocations(carrierConfig?.carrierId, payload);
          if (result.statusCode === 400 || result.statusCode === 500) {
            setIsLoading(false);
            window.scrollTo(0, 0);
            setGeneralizedError(true);
          } else if (result?.locations) {
            setUpdatedHostname(result.locations[0]?.host);
            setUpdatedPort(result.locations[0]?.port);
            setAlertText('New SFTP server was provided. Start using this SFTP server to retrieve the match results shown below.');
            setShowCompletedAlert(true);
            setIsLoading(false);
          }
        } else if (data?.transfer?.awsS3) {
          setIsLoading(true);
          setShowForm(false);
          const result = await editAwsLocations(carrierConfig?.carrierId, payload);
          if (result.statusCode === 400 || result.statusCode === 500) {
            setIsLoading(false);
            window.scrollTo(0, 0);
            setGeneralizedError(true);
          }
          if (result?.locations) {
            setUpdatedBucket(result?.locations[0]?.bucket);
            setUpdatedRole(result?.locations[0]?.role);
            setAlertText('New ARN(s) provided. Start using the new S3 Bucket and/or IAM Role for list(s) shown.');
            setShowCompletedAlert(true);
            setIsLoading(false);
          }
        }
      }
      catch (e) {
        setIsLoading(false);
        throw (e);
      }
    },
  });

  function resetFormValues() {
    const formikInitializer = {
      'newHostname': updatedHostname,
      'newPortNum': updatedPort,
      'newBucket': updatedBucket,
      'newRole': updatedRole,
      'awsAccountID': data?.transfer?.awsS3?.carrierIntegrationAccountId,
    }
    formik.setValues(formikInitializer);
  }

  function toggleShowForm() {
    setShowForm(!showForm);
    setShowCompletedAlert(false);
    setGeneralizedError(false);
    resetFormValues();
  }

  const clearAlert = () => {
    setShowCompletedAlert(false);
  }

  function goBack() {
    reloadKeys();
    hideDetails();
  }

  useEffect(() => {
    if (!tempAssociatedData) {
      setTempAssociatedData(associatedData);
    }
    const setBucketAndRole = async function () {
      await formik.setFieldValue('newBucket', data?.transfer?.awsS3?.s3Bucket);
      await formik.setFieldValue('newRole', data?.transfer?.awsS3?.iamRole);
      setFormInitIsSet(true);
    }
    if (data?.transfer?.awsS3?.carrierIntegrationAccountId && (formInitIsSet === false)) {
      setBucketAndRole().catch((error) => setGeneralizedError(true));
    }
  }, [formik, data?.transfer?.awsS3?.carrierIntegrationAccountId, formInitIsSet, data?.transfer?.awsS3?.iamRole, data?.transfer?.awsS3?.s3Bucket, associatedData, tempAssociatedData, setTempAssociatedData]);

  return (
    <Grid container spacing={3}>
      <Grid className="customer-accounts-title-trans" item xs={12}>
        <Typography variant="h4" color="primary.dark"><Tooltip arrow title="Go Back"><ArrowBackRounded className="back-arrow" onClick={goBack} sx={{ mr: 2 }} /></Tooltip>{data?.transfer?.type === 'sftp' ? 'Manage SFTP Details' : 'Manage S3 Bucket and Role Details'}</Typography>
      </Grid>
      {data?.transfer?.awsS3 &&
        <Grid item xs={12}>
          <DisplayAccountId data={data} />
        </Grid>
      }
      <Grid item xs={12} sm={8}>
        <CardWrapper>
          <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
            <Grid item xs={6}>
              <Typography className="trans-subtitle" variant="subtitle1">Transfer Location</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Button className={(!showForm && (data?.configurationType === 'matches') && data?.transfer?.sftp) ? 'show' : 'hide'} onClick={toggleShowForm} sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Change Server</Button>
              <Button className={(!showForm && data?.transfer?.awsS3) ? 'show' : 'hide'} onClick={toggleShowForm} sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Change S3 Bucket & Role</Button>
              <Button className={showForm ? 'show' : 'hide'} onClick={toggleShowForm} variant="text" sx={{ mt: '-10px', mb: '-10px' }}>Cancel</Button>
            </Grid>
          </Grid>

          <Alert className={generalizedError ? 'show' : 'hide'} severity="error" sx={{ mx: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>

          {isLoading &&
            <LoadingWrapper>
              <Box className={data?.transfer?.awsS3 ? 'aws-loader' : 'sftp-loader'}>
                <Loading />
              </Box>
            </LoadingWrapper>
          }

          <Grid className={isLoading ? 'hide' : 'show'} container spacing={3} sx={{ p: 3 }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              {data?.transfer?.awsS3 ? <img src={transLocationsIcon} alt="transfer locations icon" /> : <img src={SFTPSecureIcon} alt="transfer locations icon" />}
              <Typography variant="h5" sx={{ mb: 2 }}>
                {data?.transfer?.awsS3 && "My Organization's S3 Bucket and Role"}
                {(data?.transfer?.sftp && data?.configurationType !== 'matches') && "LENS SFTP"}
                {(data?.transfer?.sftp && data?.configurationType === 'matches') && "My Organization's SFTP"}
              </Typography>
              {(showForm && data?.transfer?.awsS3) &&
                <CloudTemplates data={data} associatedData={associatedData} evadataLambdaArn={data?.transfer?.awsS3?.evadataLambdaArn} evadataLambdaRoleArn={data?.transfer?.awsS3?.evadataLambdaRoleArn ? data?.transfer?.awsS3?.evadataLambdaRoleArn : data?.transfer?.awsS3?.evadataMatchLambdaRoleArn} />
              }
              {data?.transfer?.sftp?.sftpServer &&
                <>
                  <Typography className={showForm ? 'hide' : 'show'} variant="subtitle1"><CopyButton toolTipText="Copy SFTP Server" textToCopy={data?.transfer.sftp.sftpServer} display="inline" /> {data?.transfer?.sftp?.sftpServer}</Typography>
                  <Typography className={showForm ? 'hide' : 'show'} variant="subtitle1" sx={{ mb:2 }}>Port: {Constants.lensSftpPort}</Typography>
                </>
              }
              {data?.transfer?.sftp?.host &&
                <><Typography className={showForm ? 'hide' : 'show'} variant="subtitle1" sx={{ mb: 2 }}>{updatedHostname}</Typography>
                  <Typography className={showForm ? 'hide' : 'show'} variant="subtitle1" sx={{ mb: 2 }}>Port: {updatedPort}</Typography>
                </>

              }
              {data?.transfer?.awsS3?.s3Bucket &&
                <Typography className={showForm ? 'hide' : 'show'} variant="subtitle1" sx={{ mb: 2 }}><Typography color="text.secondary" variant="subtitle1" display="inline">S3 Bucket ARN:</Typography> {updatedBucket}</Typography>
              }
              {data?.transfer?.awsS3?.iamRole &&
                <Typography className={showForm ? 'hide' : 'show'} variant="subtitle1" sx={{ mb: 2 }}><Typography color="text.secondary" variant="subtitle1" display="inline">Role ARN:</Typography> {updatedRole}</Typography>
              }
              <Typography className={showForm ? 'hide' : 'show'} variant="body2" sx={{ mb: 2, maxWidth: '350px', mx: 'auto' }}>
                {data?.transfer?.awsS3 &&
                  'LENS uses the provided Role to retrieve your list(s) and/or to transfer matches to your S3 bucket.'}
                {(data?.transfer?.sftp && !isMatchTransfer) &&
                  'Transfer your list(s) to LENS SFTP.'}
                {(data?.transfer?.sftp && isMatchTransfer) &&
                  'LENS uses this SFTP to transfer your matches.'}</Typography>
            </Grid>
          </Grid>
          {(showForm && !isLoading) &&
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Box sx={{ textAlign: 'left', width: '100%', px: 3, pb: 2 }}>
                  {data?.transfer?.sftp &&
                    <>
                      <TextField
                        fullWidth
                        id="newHostname"
                        name="newHostname"
                        label="SFTP Server Hostname"
                        value={formik.values.newHostname}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.newHostname && Boolean(formik.errors.newHostname)}
                        helperText={(formik.touched.newHostname && formik.errors.newHostname) ? formik.errors.newHostname : 'Do not include https:// or subdirectory path when entering the server hostname.'}
                        sx={{ mb: 3 }}
                      />
                      <TextField
                        id="newPortNum"
                        name="newPortNum"
                        label="Port"
                        value={formik.values.newPortNum}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.newPortNum && Boolean(formik.errors.newPortNum)}
                        helperText={(formik.touched.newPortNum && formik.errors.newPortNum) ? formik.errors.newPortNum : '5 character limit. Numeric only.'}
                        sx={{ mb: 3 }}
                        inputProps={{ maxLength: 5 }}
                      />
                      <Typography variant='body2'>LENS uses this SFTP to transfer your matches.</Typography>
                    </>
                  }

                  {data?.transfer?.awsS3 &&
                    <>
                      <TooltipInline color="text.primary" variant="subtitle1" text="Provide new S3 Bucket ARN and/or IAM Role ARN." tip="Utilize the AWS Cloud Formation templates and Lambdas to create S3 Bucket and IAM Role ARNs." />
                      <TextField
                        fullWidth
                        id="newBucket"
                        name="newBucket"
                        label="S3 Bucket ARN"
                        value={formik.values.newBucket}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.newBucket && Boolean(formik.errors.newBucket)}
                        helperText={(formik.touched.newBucket && formik.errors.newBucket) ? formik.errors.newBucket : 'ARN should begin with: arn:aws:s3:::'}
                        sx={{ mb: 3, mt: 3 }}
                      />
                      <TextField
                        fullWidth
                        id="newRole"
                        name="newRole"
                        label="IAM Role ARN"
                        value={formik.values.newRole}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.newRole && Boolean(formik.errors.newRole)}
                        helperText={(formik.touched.newRole && formik.errors.newRole) ? formik.errors.newRole : 'ARN should begin with: arn:aws:iam::'}
                        sx={{ mb: 3 }}
                      />
                      <Typography variant='body2'>
                        LENS uses the provided Role to retrieve your lists and/or to transfer matches to the S3 bucket.
                      </Typography>
                    </>
                  }

                  <Typography variant='body2' color="text.secondary" sx={{ mt: 4, mb: 1.5 }}>
                    {data?.transfer?.sftp ? 'Apply the SFTP and/or Port change to the following list(s):' : 'Apply the S3 Bucket and/or IAM Role change to the following list(s):'}
                  </Typography>

                  {associatedData?.length ? associatedData.map((data) => (
                    <div key={data?.file?.prefix + '-' + data?.configurationType}>
                      <DisplayFileSample variant='body' nickname={data?.nickname} annualDmfEnabled={Boolean(data?.transfer?.annualDmf)} prefix={data?.file?.prefix} date='' annualDmfDate='' type={data?.file?.type} />
                    </div>
                  )) :
                    <div key={data?.file?.prefix + '-' + data?.configurationType}>
                      <DisplayFileSample variant='body' nickname={data?.nickname} annualDmfEnabled={Boolean(data?.transfer?.annualDmf)} prefix={data?.file?.prefix} date='' annualDmfDate='' type={data?.file?.type} />
                    </div>
                  }
                </Box>
                <hr />
                <Box sx={{ textAlign: 'center', pt: 2, pb: 3 }}>
                  <Button type="submit" variant="contained">
                    Save Changes
                  </Button>
                </Box>
              </form>
            </FormikProvider>
          }
        </CardWrapper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateModifiedCard auditDate={data?.auditDate} auditUser={data?.auditUser} isOwnedByLens={!isMatchTransfer && !data?.transfer?.awsS3}/>
        <CardWrapper sx={{ mt: '40px' }}>
          <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Location's Status</Typography>
            </Grid>
          </Grid>
          <Grid className="transfer-active-container" container spacing={3} sx={{ p: 3, pl: 5 }}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <CheckRounded color="success" sx={{ display: 'block', float: 'left', mr: 1 }} /> Active
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: '11px' }}>
                Currently used for {associatedData?.length} list{associatedData?.length > 1 ? 's' : ''}.
              </Typography>
            </Grid>
          </Grid>
        </CardWrapper>
      </Grid>
      <Grid item sm={12}>
        <Grid container spacing={2}>
          {!editForm && <Grid item xs={12}>
            <CardWrapper>
              <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pb: 1, pt: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1">Location's Usage</Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  <Button className="hide" startIcon={<EditRounded />} sx={{ mt: '-10px', mb: '-10px' }} variant="outlined">Edit</Button>
                </Grid>
                <Grid item xs={12} className={showCompletedAlert ? 'show' : 'hide'}>
                  <Alert onClick={clearAlert} severity="info">{alertText}</Alert>
                </Grid>
              </Grid>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ py: '7px' }} align="center">Actions</TableCell>
                    <TableCell sx={data?.transfer?.awsS3 && { width: '40%' }}>Lists Using This Location</TableCell>
                    {data?.transfer?.sftp &&
                      <>
                        <TableCell sx={{ py: '7px' }} align="center">SFTP Username</TableCell>
                        <TableCell sx={{ py: '7px' }} align="center">Server Authentication</TableCell>
                      </>
                    }
                    <TableCell sx={data?.transfer?.sftp ? { py: '7px' } : { py: '7px', width: '20%' }} align={data?.transfer?.sftp ? 'center' : 'left'}>{data?.transfer?.type === 'sftp' ? 'Subdirectory' : 'Folder Path*'}</TableCell>
                    {data?.transfer?.type === 'sftp' ?
                      <TableCell className="sftp" sx={{ py: '7px' }} align="center">Transfer Schedule</TableCell> : <TableCell className="aws" sx={{ width: '63%', py: '7px' }} align="center">Transfer Schedule</TableCell>
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tempAssociatedData?.length > 0 ? tempAssociatedData?.map(data => (
                    <DisplayTableRow key={data?.file?.prefix + '-' + data?.configurationType + '-' + editForm} data={data} anchorEl={anchorEl} open={open} configType={configType} handleClick={handleClick} handleClose={handleClose} editForm={editForm} editSubdirectory={editSubdirectory} editSftpCredentials={editSftpCredentials} editFrequency={editFrequency} />
                  ))
                    :
                    <DisplayTableRow key={data?.file?.prefix + '-' + data?.configurationType + '-' + editForm} data={data} anchorEl={anchorEl} open={open} configType={configType} handleClick={handleClick} handleClose={handleClose} editForm={editForm} editSubdirectory={editSubdirectory} editSftpCredentials={editSftpCredentials} editFrequency={editFrequency} />
                  }
                </TableBody>
              </Table>
            </CardWrapper>
          </Grid>}

          {data?.transfer?.awsS3 && !editingSubdirectory && !editingFrequency &&
            <Grid item xs={12}>
              <Typography variant="subtitle2">* Folder path only applies to match results.</Typography>
            </Grid>
          }

          {editingSubdirectory &&
            <Grid item xs={12}>
              <DisplayEditSubdirectory data={data} custFilePrefix={custFilePrefix} editForm={editForm} prefix={prefix} handleEditClose={handleEditClose} carrierConfig={carrierConfig} toggleEditForm={toggleEditForm} subdirectory={subdirectory} tempAssociatedData={tempAssociatedData} setTempAssociatedData={setTempAssociatedData} />
            </Grid>
          }

          {editingSftpCredentials &&
            <Grid item xs={12}>
              <DisplayEditSftpCredentials data={data} custFilePrefix={custFilePrefix} editForm={editForm} prefix={prefix} configType={configType} handleEditClose={handleEditClose} carrierConfig={carrierConfig} toggleEditForm={toggleEditForm} tempAssociatedData={tempAssociatedData} setTempAssociatedData={setTempAssociatedData} />
            </Grid>
          }

          {editingFrequency &&
            <Grid item xs={12}>
              <DisplayEditFrequency 
                data={data}
                matchConfig={_.find(matchConfigs, (matchConfig) => matchConfig?.file?.prefix === prefix)}
                matchConfigs={matchConfigs}
                customerConfigs={customerConfigs}
                custFilePrefix={custFilePrefix} 
                editForm={editForm} 
                prefix={prefix} 
                configType={configType} 
                availableFrequencies={availableFrequencies} 
                removedFrequencies={removedFrequencies} 
                frequency={frequency} 
                freqDay={freqDay} 
                reportDates={reportDates} 
                holidayCalendarId={holidayCalendarId} 
                annualDmf={annualDmf}
                handleEditClose={handleEditClose} 
                carrierConfig={carrierConfig} 
                toggleEditForm={toggleEditForm} 
                tempAssociatedData={tempAssociatedData} 
                setTempAssociatedData={setTempAssociatedData} 
              />
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

TransferDetails.propTypes = {
  hideDetails: PropTypes.func,
  data: PropTypes.object,
  transFreq: PropTypes.string,
  associatedData: PropTypes.array,
  availableFrequencies: PropTypes.arrayOf(PropTypes.string),
  removedFrequencies: PropTypes.array,
  reloadKeys: PropTypes.func,
  matchConfigs: PropTypes.array,
  customerConfigs: PropTypes.array
};

export default TransferDetails;