import {TableRow, TableCell, Skeleton} from '@mui/material';
import {times} from 'lodash-es';
import PropTypes from 'prop-types';


function SkeletonRow({numCols}) {
  return (
    <TableRow>
      {times(numCols, (i) =>
        <TableCell key={i}>
          <Skeleton/>
        </TableCell>
      )}
    </TableRow>
  );
}

SkeletonRow.propTypes = {
  numCols: PropTypes.number
};

export default SkeletonRow;
