import {useState} from 'react';
import {Typography, Alert} from '@mui/material';
import PropTypes from 'prop-types';

import DataTable from 'src/components/DataTable';
import CalloutCardWithBtn from 'src/components/CalloutCardWithBtn';
import EmptyState from 'src/components/EmptyState/index.js';
import {ReactComponent as EmptyStateSvg} from 'src/images/EmptyStateDataSavedOn.svg';
import getColumns from 'src/components/DataLoadsColumns';


const fileStatusesWithCollapsibleRow = ['Successful', 'Errors Found'];

function DataLoadsBodyContent({data, isLoading, isError, CollapsibleContent}) {
  const [isDownloadError, setIsDownloadError] = useState(false);

  function handleDownloadError() {
    setIsDownloadError(true);
  }

  function clearDownloadError() {
    setIsDownloadError(false);
  }

  return (
    !isLoading && data && data?.length === 0 ? 
      <EmptyState icon={<EmptyStateSvg/>}/>
      :
      <>
        <Typography variant="h5" sx={{ mb: 1 }}>Submitted Lists</Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>LENS will process list against validation requirements upon intake.</Typography>
        {isDownloadError &&
          <Alert sx={{ mb: 2, mr: 3 }} onClick={clearDownloadError} severity="error">An error was encountered when retrieving the file, try downloading it again.</Alert>
        }
        <DataTable
          rows={data}
          columns={getColumns(handleDownloadError)}
          loading={isLoading}
          apiError={isError}
          CollapsibleContent={CollapsibleContent}
          getIsRowCollapsible={(row) => fileStatusesWithCollapsibleRow.includes(row.fileStatus)}
          paginated
        /> 
        <br/>
        <CalloutCardWithBtn/>
      </>
  );
}

DataLoadsBodyContent.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  CollapsibleContent: PropTypes.element
};

export default DataLoadsBodyContent;
