import {useState} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Card, CardContent, Box, Container, Button, Typography, Grid, styled, Alert, IconButton, Tooltip} from '@mui/material';
import {ArrowForward, Edit, FileDownloadRounded, VpnKeyRounded, Close} from '@mui/icons-material';

import GeneralErrorAlert from 'src/components/GeneralErrorAlert';
import DisplayQuarterlyDates from 'src/components/DisplayQuarterlyDates';
import CopyButton from 'src/components/CopyButton';
import {downloadTxtFile} from 'src/utilities/downloadTextFile';
import * as Constants from 'src/constants';
import EditNickname from './editNickname';
import NotificationEmails from './notificationEmails';
import ExpandableContent from './expandableContent';
import PrefixName from './prefixName';
import AwsDetails from './awsDetails';
import ConfigStatus from './configStatus';
import MatchConfigCard from './MatchConfigCard';


const CardWrapper = styled(Grid)(
  ({ theme }) => `
    .MuiCard-root {
      padding: 0px !important;
      margin-bottom: 27px;
    }
    .child-accordion .MuiCard-root {
      margin-bottom: 0px;
    }
    .key-icons {
      width: 16px;
      float: left;
      margin-right: 4px;
    }
    .success {
      color: ${theme.palette.success.main};
    }
    .error {
      color: ${theme.palette.error.main};
    }
    .pgp-button {
      font-weight:500;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .copy-click-wrapper {
      float: left;
    }
    .date-line {
      background-color: ${theme.palette.background.default};
      border-top: 1px solid ${theme.palette.text.primary}22;
    }
  `
);

const AlertWrapper = styled(Container)(
  ({ theme }) => `
  width: 100%;
  max-width: 100% !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  margin-top: 10px !important;
  margin-bottom: -20px;
    .alert-close {
      font-size: 13px;
    }
    .close-icon {
      margin-left: 3px;
      font-size: 20px;
    }
    @media screen and (max-width: 1279px) {
      .completed-alert {
        border-radius: 4px;
      }
    }
  a:-webkit-any-link {
    color: ${theme.colors.success.main}
  }
    `
);

function ConfigCard({title, config, associatedMatchConfig, customerConfigs, matchConfigs, setMatchConfigs, showMatchFileDropdown=false, frequencies, goToMatchSetup, justCompleted, setJustCompleted, manageMatches, manageCustVdf, testFile, setFileType, setFilePrefix, setCustomerFilePrefix, testCustomerFilePrefix, showTestFileSuccess=false, setShowTestFileSuccess}) {  
  const [editingNickname, setEditingNickname] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);
  const [updatedNickname, setUpdatedNickname] = useState(config?.nickname);

  function downloadPGPKey() {
    downloadTxtFile(config?.pgp?.publicKey, 'pgp-' + config?.file?.prefix + '.txt')
  }

  function onTestFileClicked() {
    setFileType(config?.file?.type);
    setFilePrefix(config?.file?.prefix);
    setCustomerFilePrefix(config?.customerFilePrefix);
    testFile(title);
  }

  return (
    <CardWrapper>
      <Card sx={{ minWidth: 275, textAlign: 'left' }}>
        <Grid container spacing={2}>
          {!associatedMatchConfig && showMatchFileDropdown && !justCompleted &&
            <Grid item xs={12}>
              <AlertWrapper>
                <Alert
                  className="completed-alert"
                  variant="standard"
                  severity="info"
                  action={
                    <Button onClick={goToMatchSetup} className="finish-setup-button" variant="outlined" color="primary" >FINISH SETUP</Button>
                  }>Setup in progress. Finish the Match Workflow setup to activate this configuration in LENS.</Alert>

              </AlertWrapper>
            </Grid>
          }
          {justCompleted && 
            <Grid item xs={12}>
              <AlertWrapper>
                <Alert
                  className="completed-alert"
                  variant="standard"
                  severity="success"
                  action={
                    <IconButton className="alert-close"
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => setJustCompleted(null)}
                    >
                      CLOSE <Close className='close-icon' fontSize="inherit"/>
                    </IconButton>
                  }>New configuration added successfully! Started submitting this list to LENS.</Alert>
              </AlertWrapper>
            </Grid>
          }
          {generalizedError &&
            <Grid item xs={12} sx={{ mt: 2 }}>
              <GeneralErrorAlert />
            </Grid>
          }
          <Grid item xs={7}>
            {editingNickname ?
              <EditNickname data={config} nickname={updatedNickname || title} cancelEditNickname={() => setEditingNickname(false)} setGeneralizedError={setGeneralizedError} setUpdatedNickname={setUpdatedNickname} /> :
              <Typography variant="subtitle1" sx={{ mb: 1, px: 3, pt: 2 }} gutterBottom>
                {updatedNickname || title} <Tooltip arrow title="Rename"><IconButton aria-label="edit" onClick={() => setEditingNickname(true)}>
                  <Edit color="primary" sx={{ width: '18px', pb: '5px' }} />
                </IconButton></Tooltip>
              </Typography>
            }
          </Grid>
          <Grid item xs={5} sx={{ textAlign: 'right', pr: 2 }}>
            <Button onClick={onTestFileClicked} sx={{ mt: '12px', mr: 1 }} variant="outlined">Test File</Button>
            <Button onClick={() => manageCustVdf(config)} sx={{ mt: '12px' }} variant="contained">Manage</Button>
          </Grid>
        </Grid>
        <Grid className="date-line" container sx={{ padding: 0 }}>
          <Grid item xs={6} sx={{ px: 2, py: '3px' }}>
            <ConfigStatus/>
          </Grid>
          <Grid item xs={6} sx={{ px: 2, py: '3px', textAlign: 'right' }}>
            <Typography variant="caption">Modified {config?.auditDate} by {config?.auditUser}</Typography>
          </Grid>
        </Grid>
        <CardContent sx={{ padding: '8px 16px 0px' }}>
          <Grid container justifyContent='right'>
            <Typography variant="caption" className={config?.transfer?.encryptionEnabled ? 'success' : 'error'}><VpnKeyRounded className="key-icons" />{config?.transfer?.sftp ? 'PGP ' : 'KMS '}{config?.transfer?.encryptionEnabled ? 'Key Encryption On' : 'Key Encryption Off'}</Typography>
          </Grid>
          <Grid container sx={{ pl: { xs: 0, sm: 0 }, pt: 2 }}>
            <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: 4, mb: { xs: 0, sm: 5 } }}>
              <Typography variant="subtitle1" color='text.secondary'>Prefix Name:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <PrefixName filePrefix={config?.file?.prefix} fileType={config?.file?.type}/>
            </Grid>
            <Grid item xs={12} sm={3}>
            </Grid>
            <ExpandableContent>
              <Grid container sx={{ pt: 0 }}>
                <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: { xs: 0, sm: 4 } }}>
                  <Typography variant="subtitle1" color='text.secondary'>Transfer Location:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>{config?.transfer?.type === 'sftp' ? 'SFTP' : 'AWS S3'}<Button component={Link} variant="text" sx={{ float: 'right' }} to="/transfer-locations" endIcon={<ArrowForward />}>VIEW ALL LOCATIONS</Button></Typography>
                  {config?.transfer?.type === 'sftp' &&
                    <>
                      <Typography color="text.secondary" variant="overline">PROVIDED BY LENS</Typography>
                      <Typography sx={{mb: 0}}><strong>LENS SFTP Server Hostname:</strong> <CopyButton display="inline" toolTipText="Copy SFTP Server" textToCopy={title === 'Match Results' ? config?.transfer?.sftp?.host : config?.transfer?.sftp?.sftpServer}/>
                        {config?.transfer?.sftp?.sftpServer}
                      </Typography>
                      <Typography sx={{mb: 0.5}}><strong>Subdirectory:</strong> {title === 'Customer List' ? '/customer_files' : '/death_files'} </Typography>
                      <Typography color="text.primary" sx={{mb: 1}}><strong>Port:</strong> {Constants.lensSftpPort}</Typography>
                      <Typography color="text.secondary" variant="overline">PROVIDED BY MY ORGANIZATION</Typography>
                      {config?.transfer?.sftp?.path && config?.transfer?.sftp?.path !== '/' &&
                        <Typography sx={{mb: 1, mt: 1}}><strong>Subdirectory Path:</strong> {config?.transfer?.sftp?.path}</Typography>
                      }
                      <Typography sx={{mb: 1}}><strong>Username:</strong> {config?.transfer?.sftp?.username}</Typography>
                      <Typography sx={{mb: 1}}><strong>SSH-RSA key: </strong>For security reasons, the SSH-RSA key is hidden.</Typography>
                    </>
                  }
                  {config?.transfer?.type === 'awsS3' &&
                    <AwsDetails aws={config?.transfer?.awsS3}/>
                  }
                  <Typography sx={{mb: 1}}><strong>Transfer Schedule: </strong>
                    {config?.transfer?.frequency === 'daily' ?
                      <Box sx={{ mb: 4, textTransform: 'capitalize', display: 'inline' }}>Daily (Monday-Friday)</Box> :
                      <Box sx={{ mb: 4, textTransform: 'capitalize', display: 'inline' }}>{config?.transfer?.frequency}</Box>}
                    <br/>
                    {config?.transfer?.reportDates &&
                      <DisplayQuarterlyDates reportDates={config?.transfer?.reportDates} />
                    }
                  </Typography>
                  {config?.transfer?.type === 'sftp' &&
                    <Typography variant="body2">{title === 'Customer List' && "At the transfer frequency, place your list in the SFTP subdirectory (/customer_files)"}
                      {title === 'Verified Deaths List' && " At the transfer frequency, place your list in the SFTP subdirectory (/death_files)"}.</Typography>
                  }
                  {config?.transfer?.type === 'awsS3' &&
                    <Typography variant="body2">At the transfer frequency, place your list in the S3 Bucket.</Typography>
                  }
                </Grid>
              </Grid>
              <Grid container sx={{ pt: 2 }}>
                {(config?.transfer?.sftp?.pgp || config?.transfer?.awsS3?.kmsArn || config?.transfer?.awsS3?.evadataMatchLambdaKmsArn) && config?.transfer?.encryptionEnabled &&
                  <>
                    <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: { xs: 0, sm: 4 } }}>
                      <Typography variant="subtitle1" color='text.secondary'>Encryption Key:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>{config?.transfer?.type === 'sftp' ? 'PGP' : 'AWS KMS'}<Button component={Link} variant="text" sx={{ float: 'right' }} to="/encryption-keys" endIcon={<ArrowForward />}>VIEW ALL KEYS</Button></Typography>
                      {config?.transfer?.type === 'sftp' &&
                        <>
                          <Typography color="text.secondary" variant="overline">PROVIDED BY LENS</Typography>
                          <Typography sx={{mb: 1}}><strong>PGP Key ID: </strong>{config?.transfer?.sftp?.pgp?.keyId}</Typography>
                          <Typography sx={{mb: 0}}><strong>Key Rotation:</strong> {config?.transfer?.sftp?.pgp?.keyRotationInYears + ' Years (Expires ' + config?.transfer?.sftp?.pgp?.keyExpiration + ')'}</Typography>
                        </>
                      }
                      {config?.transfer?.type === 'awsS3' &&
                        <>
                          <Typography color="text.secondary" variant="overline">PROVIDED BY MY ORGANIZATION</Typography>
                          <Typography sx={{mb: 1}}><strong>KMS Key ARN: </strong>{config?.transfer?.awsS3?.kmsArn}</Typography>
                          <Typography variant="body2">Encrypt list with the KMS key. LENS will use this KMS Key ARN to decrypt the transferred list.</Typography>
                        </>
                      }
                    </Grid>
                    {config?.transfer?.type === 'sftp' && config?.transfer?.sftp?.pgp?.publicKey &&
                      <Grid item xs={8} sx={{ pl: { xs: 0, sm: '25.4%' }, pt: 1, display: { xs: 'none', sm: 'block' } }}>
                        <div className='copy-click-wrapper'>
                          <CopyButton paddingLeft={0} buttonText="Copy Public Key" toolTipText="Copy Public Key" textToCopy={config?.transfer?.sftp?.pgp?.publicKey}></CopyButton>
                        </div>
                        <Button className="pgp-button" onClick={downloadPGPKey} sx={{ marginTop: '-3px', marginRight: '-10px' }}>Download Public Key<FileDownloadRounded sx={{ ml: 1 }} /></Button>
                        <br />
                        <Typography variant="body2" sx={{ marginLeft: { sm: '-5px', xs: 0 } }}>
                          {title === 'Customer List' && 'Use the public PGP key to encrypt your Customer list.'}
                          {title === 'Verified Deaths List' && 'Use the public PGP key to encrypt your Verified Deaths list.'}</Typography>
                      </Grid>
                    }
                  </>
                }
                <NotificationEmails emails={config?.notificationEmails}/>
              </Grid>
            </ExpandableContent>
          </Grid>
        </CardContent>
        {showMatchFileDropdown && (associatedMatchConfig || justCompleted) &&
          <Box m={2}>
            <MatchConfigCard
              config={associatedMatchConfig}
              frequencies={frequencies}
              customerConfigs={customerConfigs}
              matchConfigs={matchConfigs}
              setMatchConfigs={setMatchConfigs}
              manageMatches={manageMatches}
              testFile={testFile}
              setFilePrefix={setFilePrefix}
              setFileType={setFileType}
              setCustomerFilePrefix={setCustomerFilePrefix}
              testCustomerFilePrefix={testCustomerFilePrefix}
              showTestFileSuccess={showTestFileSuccess}
              setShowTestFileSuccess={setShowTestFileSuccess}
            />
          </Box>
        }
      </Card>
    </CardWrapper>
  );
}

ConfigCard.propTypes = {
  title: PropTypes.string,
  config: PropTypes.object,
  associatedMatchConfig: PropTypes.object,
  customerConfigs: PropTypes.array,
  matchConfigs: PropTypes.array,
  setMatchConfigs: PropTypes.func,
  showMatchFileDropdown: PropTypes.bool,
  frequencies: PropTypes.object,
  goToMatchSetup: PropTypes.func,
  justCompleted: PropTypes.bool,
  setJustCompleted: PropTypes.func,
  manageMatches: PropTypes.func,
  manageCustVdf: PropTypes.func,
  testFile: PropTypes.func,
  setFileType: PropTypes.func,
  setFilePrefix: PropTypes.func,
  setCustomerFilePrefix: PropTypes.func,
  showTestFileSuccess: PropTypes.bool,
  setShowTestFileSuccess: PropTypes.func,
  testCustomerFilePrefix: PropTypes.string
};

export default ConfigCard;
