import {Typography, Card, Grid, styled} from '@mui/material';
import {CheckCircleRounded, UnpublishedRounded} from '@mui/icons-material';
import PropTypes from 'prop-types';


const CardWrapper = styled(Card)(
  ({ theme }) => `
    .card-header {
      border-bottom: 1px solid ${theme.palette.text.primary}22;
      padding-bottom: 15px;
    }
  `
);

const activatedDescription = {
  customer_records: 'When the Match file is activated, match jobs run against this list.',
  matches: 'Match job will run at the specified transfer schedule.',
  death_records: 'Expands match results and shortens notification time.'
};

function DisplayActivationStatus({disabled=false, configType}) {
  return (
    <CardWrapper>
      <Grid className="card-header" container spacing={2} sx={{ pl: 2, pr: 2, pt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Status</Typography>
        </Grid>
      </Grid>
      <Grid className="transfer-active-container" container spacing={3} sx={{ px: 5, pt: 2, pb: 4 }}>
        <Grid item xs={12}>
          {disabled ?
            <>
              <Typography>
                <UnpublishedRounded color="error" sx={{display: 'block', float: 'left', mt: '1px', mr: 0.5}}/>Deactivated
              </Typography>
              <Typography variant='body2' color='text.secondary' mt={0.5}>Match job will not run at the specified transfer schedule.</Typography>
            </>
            :
            <>
              <Typography>
                <CheckCircleRounded color="success" sx={{display: 'block', float: 'left', mt: '1px', mr: 0.5}}/>Activated
              </Typography>
              <Typography variant='body2' color='text.secondary' mt={0.5}>{activatedDescription[configType]}</Typography>
            </>
          }
        </Grid>
      </Grid>
    </CardWrapper>
  )
}

DisplayActivationStatus.propTypes = {
  disabled: PropTypes.bool,
  configType: PropTypes.string
};

export default DisplayActivationStatus;
