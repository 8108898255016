import {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Box, Card, Divider, styled} from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';

import LoadError from 'src/components/LoadError';
import Actions from './actions';
import CalendarSkeleton from './calendarSkeleton';


const FullCalendarWrapper = styled(Box)(
  ({ theme }) => `
    padding: ${theme.spacing(3)};

    & .fc-license-message {
      display: none;
    }
    .fc {

      .fc-col-header-cell {
        background: ${theme.colors.alpha.black[5]};
        font-weight: bold;
      }

      .fc-scrollgrid {
        border: 2px solid ${theme.colors.alpha.black[10]};
        border-right-width: 1px;
        border-bottom-width: 1px;
      }

      .fc-cell-shaded,
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[5]};
      }

      .fc-list-event-graphic {
        padding-right: ${theme.spacing(1)};
      }

      .fc-theme-standard td, .fc-theme-standard th,
      .fc-col-header-cell {
        border: 1px solid ${theme.colors.alpha.black[10]};
      }

      .fc-event {
        padding: ${theme.spacing(0.1)} ${theme.spacing(0.3)};
      }

      a.fc-event {
        background-color: ${theme.palette.primary.main} !important;
        text-align: center;
        border-radius: 8px;
      }

      .fc-list-day-side-text {
        font-weight: normal;
        color: ${theme.colors.alpha.black[70]};
      }

      .fc-list-event:hover td,
      td.fc-daygrid-day.fc-day-today {
        background-color: ${theme.colors.primary.lighter};
      }

      td.fc-daygrid-day:hover,
      .fc-highlight {
        background: ${theme.colors.alpha.black[10]};
      }

      .fc-daygrid-dot-event:hover, 
      .fc-daygrid-dot-event.fc-event-mirror {
        background: ${theme.colors.primary.lighter};
      }

      .fc-daygrid-day-number {
        padding: ${theme.spacing(1)};
        font-weight: bold;
      }

      .fc-list-sticky .fc-list-day > * {
        background: #eeeee !important;
      }

      .fc-cell-shaded, 
      .fc-list-day-cushion {
        background: ${theme.colors.alpha.black[3]} !important;
        color: ${theme.colors.alpha.black[100]} !important;
        height: 60px;
        padding-top: 18px;
      }

      &.fc-theme-standard td, 
      &.fc-theme-standard th,
      &.fc-theme-standard .fc-list {
        border-color: ${theme.colors.alpha.black[30]};
      }
      .fc-event-time, .fc-list-event-time, .fc-list-event-dot {
        display: none;
      }
      td.fc-list-event-graphic {
        display: none;
      }
      .fc-list-event-title {
        height: 60px;
        padding-top: 18px;
    }
    .fc-list-event-title {
      cursor: pointer;
    }
  `
);

function Calendar({events, setSelectedEvent, openDrawer, loading, apiError}) {
  const calendarRef = useRef(null);

  const [date, setDate] = useState(new Date());
  const [view, setView] = useState('listYear');

  const handleDateClick = (info) => {
    setSelectedEvent(info);
    openDrawer();
  };

  const handleEventSelect = (arg) => {
    setSelectedEvent({ date: arg.event.start, title: arg.event.title, id: arg.event.id });
    openDrawer();
  };

  const handleDateToday = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.today();
      setDate(calApi.getDate());
    }
  };
  
  const changeView = (changedView) => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.changeView(changedView);
      setView(changedView);
    }
  };
  
  const handleDatePrev = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.prev();
      setDate(calApi.getDate());
    }
  };
  
  const handleDateNext = () => {
    const calItem = calendarRef.current;

    if (calItem) {
      const calApi = calItem.getApi();

      calApi.next();
      setDate(calApi.getDate());
    }
  };

  return (
    <Card>
      <Box p={3}>
        <Actions
          date={date}
          onNext={handleDateNext}
          onPrevious={handleDatePrev}
          onToday={handleDateToday}
          changeView={changeView}
          view={view}
          loading={loading}
          apiError={apiError}
        />
      </Box>
      <Divider/>
      {apiError ? <LoadError sx={{minHeight: '200px'}}/> :
        <>
          {loading && <CalendarSkeleton/>}
          {!loading && 
            <FullCalendarWrapper>
              <FullCalendar
                allDayMaintainDuration
                initialDate={date}
                initialView={view}
                dateClick={handleDateClick}
                droppable
                editable
                eventDisplay="block"
                eventClick={handleEventSelect}
                dayMaxEventRows={4}
                eventResizableFromStart
                events={events}
                headerToolbar={false}
                height={660}
                ref={calendarRef}
                rerenderDelay={10}
                selectable
                stickyHeaderDates={false}
                weekends
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin
                ]}
              />
            </FullCalendarWrapper>
          }
        </>
      }
    </Card>
  );
}

Calendar.propTypes = {
  events: PropTypes.array,
  setSelectedEvent: PropTypes.func,
  openDrawer: PropTypes.func,
  loading: PropTypes.bool,
  apiError: PropTypes.bool
};

export default Calendar;
