import {useMemo} from 'react';
import {Route, Routes} from 'react-router-dom';
import {LoginCallback} from '@okta/okta-react';

import {RequiredAuth} from './RequiredAuth';
import {useAppContext} from 'src/AppContext.js';
import VerifiedDeathsFile from 'src/pages/VerifiedDeathsFile';
import CustomerFile from 'src/pages/CustomerFile';
import MatchResultsFile from 'src/pages/MatchResultsFile';
import MaterialsDemo from 'src/components/MaterialsDemo';
import GettingStarted from 'src/pages/GettingStarted';
import CategoryDefinitions from 'src/pages/CategoryDefinitions';
import NotFound404 from 'src/pages/404';
import ErrorPage500 from 'src/pages/500';
import OnboardingWelcome from 'src/pages/OnboardingWelcome';
import CustomerFileSetup from 'src/pages/CustomerFileSetup';
import VerifiedDeathsFileSetup from 'src/pages/VerifiedDeathsFileSetup';
import MatchResultsFileSetup from 'src/pages/MatchResultsFileSetup';
import InitialLoader from 'src/components/InitialLoader';
import ProcessOverview from 'src/pages/ProcessOverview';
import ListConfigurations from 'src/pages/ListConfigurations';
import TransferLocations from 'src/pages/TransferLocations';
import LoadingIcon from 'src/components/InitialLoader/loadingIcon';
import EncryptionKeys from 'src/pages/EncryptionKeys';
import ActiveListSummary from 'src/pages/ActiveListSummary';
import ActivityLog from 'src/pages/ActivityLog';
import NotAuthorized401 from 'src/pages/401';
import HelpfulGuidance from 'src/pages/HelpfulGuidance';
import HolidayCalendar from 'src/pages/HolidayCalendar';
import EvadatasMatchCategories from 'src/pages/EvadatasMatchCategories';
import ReleaseNotes from 'src/pages/ReleaseNotes';
import CustomerListDataLoads from 'src/pages/CustomerListDataLoads';
import MatchResultsDataLoads from 'src/pages/MatchResultsDataLoads';
import VerifiedDeathsDataLoads from 'src/pages/VerifiedDeathsDataLoads';


const AppRoutes = () => {
  const {carrierConfig, lensRoles} = useAppContext();

  const onboardingStage = useMemo(() => {
    if (carrierConfig?.status !== 'on-boarding' || !carrierConfig?.onBoarding || !lensRoles) {
      return;
    }
    if (!lensRoles?.includes('administrator')) {
      return 'process';
    }
    if (carrierConfig?.onBoarding.customer_file_completed && carrierConfig.onBoarding.death_file_completed && carrierConfig.onBoarding.match_file_completed) {
      return 'match-setup-completed';
    }
    if (carrierConfig?.onBoarding.customer_file_completed && carrierConfig.onBoarding.death_file_completed) {
      return 'vfd-setup-completed';
    }
    if (carrierConfig?.onBoarding.customer_file_completed) {
      return 'cust-setup-completed';
    }
    return 'pending';
  }, [carrierConfig, lensRoles])

  return(
    <Routes>
      <Route path='/' element={<RequiredAuth/>}>
        <Route path='/' element={<InitialLoader/>}>
          {carrierConfig?.status === 'prospect' && 
            <Route path='/' element={<ProcessOverview />} />
          }
          {onboardingStage === 'process' &&
            <Route path='/' element={<ProcessOverview />} />
          }
          {onboardingStage === 'pending' && 
            <Route path='/' element={<OnboardingWelcome />} />
          }
          {onboardingStage === 'cust-setup-completed' && 
            <Route path='/' element={<CustomerFileSetup />} />
          }
          {onboardingStage === 'vfd-setup-completed' && 
            <Route path='/' element={<VerifiedDeathsFileSetup />} />
          }
          {onboardingStage === 'match-setup-completed' && 
            <Route path='/' element={<MatchResultsFileSetup />} />
          }
          {carrierConfig?.status === 'live' && 
            <Route path='/' element={<ActiveListSummary />} />
          }
          <Route path='/process-overview' element={<ProcessOverview />} />
          <Route path='/getting-started' element={<GettingStarted />} />
          <Route path='/helpful-guidance' element={<HelpfulGuidance />} />
          <Route path='/customer-file' element={<CustomerFile />} />
          <Route path='/verified-deaths-file' element={<VerifiedDeathsFile />} />
          <Route path='/match-results-file' element={<MatchResultsFile />} />
          <Route path='/category-definition' element={<CategoryDefinitions />} />
          <Route path='/customer-file-status' element={<CustomerListDataLoads/>} />
          <Route path='/verified-deaths-file-status' element={<VerifiedDeathsDataLoads/>} />
          <Route path='/matches-file' element={<MatchResultsDataLoads/>} />
          <Route path='/onboarding-welcome' element={<OnboardingWelcome />} />
          <Route path='/customer-file-setup' element={<CustomerFileSetup />} />
          <Route path='/verified-deaths-file-setup' element={<VerifiedDeathsFileSetup />} />
          <Route path='/match-results-file-setup' element={<MatchResultsFileSetup />} />
          <Route path='/manage-configurations' element={<ListConfigurations/>} />
          <Route path='/transfer-locations' element={<TransferLocations />} />
          <Route path='/encryption-keys' element={<EncryptionKeys />} />
          <Route path='/active-list-summary' element={<ActiveListSummary />} />   
          <Route path='/activity-log' element={<ActivityLog />}/> 
          <Route path='/holiday-calendar' element={<HolidayCalendar/>}/> 
          <Route path='/evadatas-match-categories' element={<EvadatasMatchCategories />}/>
          <Route path='/release-notes' element={<ReleaseNotes />}/>
        </Route>
      </Route>
      <Route path='/login/callback' element={<LoginCallback loadingElement={<LoadingIcon />}  errorComponent={NotAuthorized401} />} />
      <Route path='*' element={<NotFound404 />} />
      <Route path='500' element={<ErrorPage500 />} />
      <Route path='401' element={<NotAuthorized401 />} />
      <Route path='/demo' element={<MaterialsDemo/>} />
      <Route path='/loading' element={<InitialLoader />} />
    </Routes>
  );
};

export default AppRoutes;
