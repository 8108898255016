import {Chip} from '@mui/material';
import PropTypes from 'prop-types';


const colors = {
  'Processing': 'info',
  'Failed': 'error',
  'Errors Found': 'warning',
  'Successful': 'success',
  'Skipped': 'default'
};

function FileStatusChip({fileStatus}) {
  const color = colors[fileStatus] || 'default';

  return <Chip size="small" color={color} label={fileStatus} variant='outlined'/>;
}

FileStatusChip.propTypes = {
  fileStatus: PropTypes.string
};

export default FileStatusChip;
