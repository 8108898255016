import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {useQuery} from 'src/hooks';
import {useAppContext} from 'src/AppContext.js';
import {getCustomerFileConfigurations, getDeathFileConfigurations, getMatchFileConfigurations, getTransferFrequencies} from 'src/api';
import {switchFreqDisplay} from 'src/utilities/switchFrequencyDisplay';
import EncryptionDetails from './encryptionDetails';
import TransferDetails from './transferDetails';
import TransferTable from './TransferTable';
import EncryptionTable from './EncryptionTable';


function DisplayTransferAndEncryption(props) {
  const {carrierConfig} = useAppContext();

  const [isLoading, setIsLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [detailsDataTwo, setDetailsDataTwo] = useState(null);
  const [transFreq, setTransFreq] = useState(null);
  const [stateRefreshCounter, setStateRefreshCounter] = useState(0);
  const [associatedData, setAssociatedData] = useState(null);
  const [uniqueBucketRolePairs, setUniqueBucketRolePairs] = useState(null);
  const [uniqueKeyGroups, setUniqueKeyGroups] = useState(null);
  const [keyGroup, setKeyGroup] = useState(null);
  const [uniqueSftpMatchLocations, setUniqueSftpMatchLocations] = useState(null);
  const [availableFrequencies, setAvailableFrequencies] = useState([]);
  const [removedFrequencies, setRemovedFrequencies] = useState([]);

  const {data: allCustomerResults, isError: customerError} = useQuery(['customerConfigs', carrierConfig?.carrierId], () => getCustomerFileConfigurations(carrierConfig?.carrierId));
  const {data: allVerifiedDeathsResults, isError: verifiedDeathError} = useQuery(['verifiedDeathConfigs', carrierConfig?.carrierId], () => getDeathFileConfigurations(carrierConfig?.carrierId));
  const {data: allMatchResults, isError: matchError} = useQuery(['matchConfigs', carrierConfig?.carrierId], () => getMatchFileConfigurations(carrierConfig?.carrierId));
  const {data: transferFrequencies, isError: frequencyError} = useQuery(['transferFrequencies', carrierConfig?.carrierId], () => getTransferFrequencies(carrierConfig?.carrierId));

  function setBucketPairs(custConfigs, deathConfigs, matchConfigs) {
    let uniquePairs = [];

    if ((custConfigs?.length > 0) && (matchConfigs?.length > 0) && deathConfigs) {
      let allData = [...custConfigs, ...matchConfigs];
      if (deathConfigs?.length > 0) {
        allData = [...allData, ...deathConfigs];
      }
      allData?.forEach((result) => {
        let bucket = result?.transfer?.awsS3?.s3Bucket;
        let role = result?.transfer?.awsS3?.iamRole;
        let matchedIndex = uniquePairs.findIndex(element => ((element.record.transfer?.awsS3?.s3Bucket === bucket) && (element.record.transfer?.awsS3?.iamRole === role)));
        if ((matchedIndex === -1) || (uniquePairs?.length === 0)) {
          uniquePairs.push({ record: result, associatedData: [] })
        }
        else if (matchedIndex > -1) {
          uniquePairs?.[matchedIndex].associatedData.push(result);
        }
        else {
          uniquePairs.push({ record: result, associatedData: [] })
        }
      })
      setUniqueBucketRolePairs(uniquePairs);
    }
  }

  function setKeyGroups(custConfigs, deathConfigs, matchConfigs) {
    let uniqueKeys = [];
    let allData = [];

    allData = allData.concat(custConfigs, deathConfigs, matchConfigs);
    allData = allData?.filter((data) => data?.transfer?.encryptionEnabled || data?.configurationType === 'matches');
    allData?.forEach((result) => {
      let key = result?.transfer?.awsS3?.kmsArn || result?.transfer?.sftp?.pgp?.id;
      let matchKey = result?.transfer?.awsS3?.evadataMatchLambdaKmsArn || result?.transfer?.sftp?.pgp?.keyId;
      let matchedIndex;
      if (key || matchKey) {
        matchedIndex = key ? uniqueKeys.findIndex(element => ((key === (element.record.transfer?.awsS3?.kmsArn || element.record.transfer?.sftp?.pgp?.id)))) 
          : uniqueKeys.findIndex(element => ((matchKey === (element.record.transfer?.awsS3?.evadataMatchLambdaKmsArn || element.record.transfer?.sftp?.pgp?.keyId))));
        if (((matchedIndex === -1) || (uniqueKeys?.length === 0))) {
          uniqueKeys.push({ record: result, associatedData: [] })
        }
        if (matchedIndex > -1) {
          uniqueKeys?.[matchedIndex].associatedData.push(result);
        }
      }
    })
    setUniqueKeyGroups(uniqueKeys);
  }

  function setSftpMatchLocations(matchConfigs) {
    let uniqueMatchLocations = [];

    if (matchConfigs?.length > 0) {
      
      matchConfigs?.forEach((result) => {
        let host = result?.transfer?.sftp?.host;
        let port = result?.transfer?.sftp?.port;
        let matchedIndex = uniqueMatchLocations.findIndex(element => ((element.record.transfer?.sftp?.host === host) && (element.record.transfer?.sftp?.port === port)));

        if ((matchedIndex === -1) || (uniqueMatchLocations?.length === 0)) {
          uniqueMatchLocations.push({ record: result, allData: [result] })
        }
        else if (matchedIndex > -1) {
          uniqueMatchLocations?.[matchedIndex].allData.push(result);
        }
        else {
          uniqueMatchLocations.push({ record: result, allData: [result] })
        }
      })
      setUniqueSftpMatchLocations(uniqueMatchLocations);
    }
  }

  function viewAwsDetails(result, additionalData) {
    let _combinedData = {"record": result, "associatedData": additionalData};
    setDetailsData(result);
    setAssociatedData([result, ...additionalData]);
    setKeyGroup(_combinedData);
    setTransFreq(switchFreqDisplay(result?.transfer?.frequency));
    setShowDetails(true);
  }

  function viewCustomerDetails() {
    setDetailsData(allCustomerResults?.[0]);
    if (allCustomerResults) {
      setAssociatedData(allCustomerResults);
    }
    setTransFreq(switchFreqDisplay(allCustomerResults?.[0]?.transfer?.frequency));
    setShowDetails(true);
  }

  function viewCustomerDetailsSftp(setSecondaryData, allData, data) {
    if (setSecondaryData === true) {
      setDetailsDataTwo(allVerifiedDeathsResults?.[0]);
    }
    if (setSecondaryData === false) {
      setDetailsDataTwo(null);
    }
    if (allData) {
      setAssociatedData(allData);
    }
    if (!allData) {
      setAssociatedData(null);
    }
    if (data) {
      setDetailsData(data);
    } else {
      setDetailsData(allCustomerResults?.[0]);
    }
    setTransFreq(switchFreqDisplay(allCustomerResults?.[0]?.transfer?.frequency));
    setShowDetails(true);
  }

  function viewVfdDetails() {
    setAssociatedData(allVerifiedDeathsResults);
    setDetailsData(allVerifiedDeathsResults?.[0]);
    setTransFreq(switchFreqDisplay(allVerifiedDeathsResults?.[0]?.transfer?.frequency));
    setShowDetails(true);
  }

  function viewMatchDetails(prefixes, matchConfigs, matchDetail, associatedData) {
    if (matchConfigs) {
      setAssociatedData(matchConfigs);
    } else {
      setAssociatedData([]);
    }

    if (matchDetail) {
      setDetailsData(matchDetail);
    } else {
      setDetailsData(allMatchResults?.[0]);
    }

    if (associatedData) {
      setAssociatedData(associatedData);
    } else {
      setAssociatedData([]);
    }

    setDetailsDataTwo(matchConfigs);
    setTransFreq(switchFreqDisplay(allMatchResults?.[0]?.transfer?.frequency));
    setShowDetails(true);
  }

  function viewDetails(keyGroup) {
    setKeyGroup(keyGroup);
    setShowDetails(true);
  }

  const apiError = customerError || verifiedDeathError || matchError || frequencyError;

  useEffect(() => {
    if (allCustomerResults && allVerifiedDeathsResults && allMatchResults && transferFrequencies) {
      const {scanFrequencies, removed} = transferFrequencies;
      const removedFrequencies = removed || [];

      setBucketPairs(allCustomerResults, allVerifiedDeathsResults, allMatchResults);
      setKeyGroups(allCustomerResults, allVerifiedDeathsResults, allMatchResults);
      setSftpMatchLocations(allMatchResults);
      setAvailableFrequencies(scanFrequencies);
      setRemovedFrequencies(removedFrequencies);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCustomerResults, allVerifiedDeathsResults, allMatchResults, transferFrequencies]);

  function hideDetails() {
    setShowDetails(false);
  }

  function reloadKeys() {
    const counter = (stateRefreshCounter + 1);
    setStateRefreshCounter(counter);
  }

  useEffect(() => {
    if (!uniqueBucketRolePairs) {
      setBucketPairs(allCustomerResults, allVerifiedDeathsResults, allMatchResults);
    }
    if (!uniqueKeyGroups) {
      setKeyGroups(allCustomerResults, allVerifiedDeathsResults, allMatchResults);
    }
    if (!uniqueSftpMatchLocations) {
      setSftpMatchLocations(allMatchResults);
    }
  }, [stateRefreshCounter, uniqueBucketRolePairs, allCustomerResults, allVerifiedDeathsResults, allMatchResults, uniqueKeyGroups, uniqueSftpMatchLocations])

  return (
    <>
      {(!showDetails && props.keyType === "encryption") &&
        <EncryptionTable uniqueKeyGroups={uniqueKeyGroups} viewDetails={viewDetails} viewAwsDetails={viewAwsDetails} isLoading={isLoading} apiError={apiError}/>
      }
      {(!showDetails && props.keyType === "transfer") &&
        <TransferTable key={stateRefreshCounter} allCustomerResults={allCustomerResults} customerResult={allCustomerResults?.[0]} allMatchResults={allMatchResults} uniqueBucketRolePairs={uniqueBucketRolePairs} matchFileResult={allMatchResults?.[0]} uniqueSftpMatchLocations={uniqueSftpMatchLocations} verifiedDeathsResult={allVerifiedDeathsResults?.[0]} viewCustomerDetailsSftp={viewCustomerDetailsSftp} viewVfdDetails={viewVfdDetails} allVerifiedDeathsResults={allVerifiedDeathsResults} viewMatchDetails={viewMatchDetails} viewCustomerDetails={viewCustomerDetails} viewAwsDetails={viewAwsDetails} isLoading={isLoading} apiError={apiError}/>
      }

      {(showDetails && props.keyType === "encryption") && <EncryptionDetails keyGroup={keyGroup} hideDetails={hideDetails} reloadKeys={reloadKeys} />}

      {(showDetails && props.keyType === "transfer") && <TransferDetails key={stateRefreshCounter} hideDetails={hideDetails} data={detailsData} matchConfigs={allMatchResults} customerConfigs={allCustomerResults} transFreq={transFreq} dataTwo={detailsDataTwo} associatedData={associatedData} availableFrequencies={availableFrequencies} removedFrequencies={removedFrequencies} reloadKeys={reloadKeys} />}
    </>
  );
}

DisplayTransferAndEncryption.propTypes = {
  keyType: PropTypes.string.isRequired
};

export default DisplayTransferAndEncryption;