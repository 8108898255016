const activityLogColumns = [
  { 
    field: 'fileName', 
    headerName: 'File Name', 
    flex:1,
    sortable: false,
    renderCell: (params) => {
      if(params.row.fileNameNickname != null){
        return <div className="wrap-cell"><p>{params.row.fileNameNickname}</p><p>{params.value}</p></div>;
      }
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    }
  },
  {
    field: 'sortableTime',
    headerName: 'Time',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.row.time}</p></div>;
    },
  },
  {
    field: 'sources',
    headerName: 'Sources',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    },
  },
  { 
    field: 'categories', 
    headerName: 'Match Categories',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    }
  },
  {
    field: 'enteredBy', 
    headerName: 'Entered By',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return <div className="wrap-cell"><p>{params.value}</p></div>;
    }
  }
]

export default activityLogColumns;
