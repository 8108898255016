import {useState} from 'react';
import {Grid, Alert} from '@mui/material';

import {useQuery} from 'src/hooks';
import {useAppContext} from 'src/AppContext';
import {getHolidayCalendars} from 'src/api';
import PageHeader from './calendarPageHeader';
import Calendar from './Calendar';
import EventDrawer from './eventDrawer';


const transformCalendars = (calendars) => {
  return calendars.map((calendar) => {
    const holidays = calendar?.calendar;
    const result = Object.keys(holidays).map((holidayDate, index) => [
      {
        id: index,
        start: new Date(holidayDate),
        title: holidays[holidayDate]
      }]);
    return {...calendar, calendar: result.flat(1)};
  });
};

function HolidayCalendar() {
  const {carrierConfig} = useAppContext();
  const carrierId = carrierConfig?.carrierId;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  const handleAddClick = () => {
    setSelectedEvent(null);
    setIsDrawerOpen(true);
  };

  const showAlert = (message) => {
    setAlertMessage(message);
    setTimeout(() => setAlertMessage(null), 3000);
  };

  const {data: calendarData, isLoading: calendarLoading, refetch: refetchCalendar, isError: calendarError} = useQuery(['holidayCalendar', carrierId], () => getHolidayCalendars(carrierId), {select: transformCalendars, enabled: Boolean(carrierId)});

  return (
    <>
      <PageHeader handleCreateEvent={handleAddClick} createEventDisabled={calendarLoading || calendarError}/>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          {alertMessage && <Alert sx={{ width: '100%', mb: 2 }}>{alertMessage}</Alert>}
          <Calendar
            events={calendarData?.[0]?.calendar}
            setSelectedEvent={setSelectedEvent}
            openDrawer={() => setIsDrawerOpen(true)}
            loading={calendarLoading}
            apiError={calendarError}
          />
        </Grid>
      </Grid>
      <EventDrawer
        calendarId={calendarData?.[0]?.id}
        calendarName={calendarData?.[0]?.name}
        carrierId={carrierId}
        events={calendarData?.[0]?.calendar}
        event={selectedEvent}
        isOpen={isDrawerOpen}
        close={() => setIsDrawerOpen(false)}
        showAlert={showAlert}
        refetchCalendar={refetchCalendar}
      />
    </>
  );
}

export default HolidayCalendar;
