import { useOktaAuth } from '@okta/okta-react';
import { useQuery as _useQuery} from 'react-query';
import { useNavigate } from 'react-router-dom';

export const useQuery = function(queryKey, queryfn, options) {

  const { authState } = useOktaAuth();
  const {isError, error, data, ...rest} = _useQuery(queryKey, queryfn, {retry: false, ...options});

  const navigate = useNavigate();

  let _isError = isError;
  let _error = error;

  if(data && !isError) {
    const {statusCode, status} = data;

    const _statusCode = statusCode || status;
    
    if(!isNaN(_statusCode)) {
      _isError = (_statusCode > 201 && _statusCode !== 401);
      _error = data;

      if(_statusCode === 401) {
        if(!authState?.isAuthenticated){
          return {isError: false, isLoading: true};
        }
        else{
          navigate('/401');
        }
      }
    }
  }


  return {...rest, data, isError: _isError, error: _error};
};