import {Typography, Box, styled} from '@mui/material';
import {UnpublishedRounded, CheckCircleRounded} from '@mui/icons-material';
import PropTypes from 'prop-types';


const StatusWrapper = styled(Box)(
  () => `
    .key-icons {
      width: 16px;
      float: left;
      margin-right: 4px;
      margin-top: 3px;
    }
  `
);

function ConfigStatus({disabled=false}) {
  return (
    <StatusWrapper>
      <Typography variant="caption">
        {disabled ?
          <>
            <UnpublishedRounded className='key-icons' color='error'/>Deactivated
          </>
          :
          <>
            <CheckCircleRounded className="key-icons" color='success'/>Activated
          </>
        }
      </Typography>
    </StatusWrapper>
  );
}

ConfigStatus.propTypes = {
  disabled: PropTypes.bool
};

export default ConfigStatus;
