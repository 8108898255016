import {useState} from 'react';
import {TableRow, TableCell, IconButton, Collapse} from '@mui/material';
import {ExpandMoreRounded, ExpandLessRounded} from '@mui/icons-material';
import PropTypes from 'prop-types';


function Row({rowData, columns, CollapsibleContent, rowCollapsible}) {
  const [rowOpen, setRowOpen] = useState(false);

  const borderBottom = CollapsibleContent ? 'unset' : null;

  return (
    <>
      <TableRow>
        {CollapsibleContent &&
          <TableCell className='action' sx={{width: '5px', borderBottom}}>
            {rowCollapsible &&
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setRowOpen((open) => !open)}
              >
                {rowOpen ? <ExpandLessRounded/> : <ExpandMoreRounded/>}
              </IconButton>
            }
          </TableCell>
        }
        {columns.map((column) =>
          <TableCell key={column.field} sx={{borderBottom, textAlign: column?.textAlign ? column?.textAlign + ' !important' : '', verticalAlign: column?.verticalAlign ? column?.verticalAlign + ' !important' : ''}}>
            {column.renderCell({value: rowData[column.field], row: rowData})}
          </TableCell>
        )}
      </TableRow>
      {CollapsibleContent &&
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns?.length + 1}>
            <Collapse in={rowOpen} timeout="auto" unmountOnExit>
              <CollapsibleContent rowData={rowData}/>
            </Collapse>
          </TableCell>
        </TableRow>
      }
    </>
  );
}

Row.propTypes = {
  rowData: PropTypes.object,
  columns: PropTypes.array,
  CollapsibleContent: PropTypes.element,
  rowCollapsible: PropTypes.bool
};

export default Row;
