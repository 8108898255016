import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Alert, Button, Grid, styled, Typography, Card, Skeleton, Container} from '@mui/material';
import {ArrowBack, SettingsSuggestRounded} from '@mui/icons-material';

import {getCustomerFileConfigurations, getDeathFileConfigurations, getMatchFileConfigurations, getEmailAddresses, getSftpUsernames, getHolidayCalendars, getTransferFrequencies} from 'src/api';
import PageHeader from 'src/components/PageHeader';
import TestFile from 'src/components/TestFile';
import {useAppContext} from 'src/AppContext.js';
import {useQuery} from 'src/hooks';
import AddCustomerConfiguration from './addCustomerConfiguration';
import AddMatchFileConfiguration from './AddMatchFileConfiguration';
import ManageMatchResults from './manageMatchResults';
import ManageCustVdfLists from './manageCustVdfLists';
import AddDeathConfiguration from './addDeathConfiguration';
import MultiConfigButton from './multiButton';
import SkeletonCard from './skeletonCard';
import ConfigCard from './ConfigCard';


const AlertWrapper = styled(Container)(
  ({ theme }) => `
    width: 300px;
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 340px !important;
    position: fixed;
    z-index: 999999;
    top: 70px;
    box-shadow: 0px 2px 4px ${theme.palette.text.primary}99;
    border-radius: 4px;
    cursor: pointer;
  `
);

const sortCustomerConfigs = (configs) => {
  return configs.sort(function (a, b) {
    return new Date(b.auditDate) - new Date(a.auditDate);
  });
};

function ListConfigurations() {
  const {carrierConfig, lensRoles} = useAppContext();
  const carrierId = carrierConfig?.carrierId;
  const navigate = useNavigate();
  
  const [matchConfigs, setMatchConfigs] = useState([]);
  const [currentCustomerConfig, setCurrentCustomerConfig] = useState(null);
  const [currentMatchConfig, setCurrentMatchConfig] = useState(null);
  const [showAddCustConfig, setShowAddCustConfig] = useState(false);
  const [showAddDeathConfig, setShowAddDeathConfig] = useState(false);
  const [showAddMatchConfig, setShowAddMatchConfig] = useState(false);
  const [showManageCustVdf, setShowManageCustVdf] = useState(false);
  const [showManageMatches, setShowManageMatches] = useState(false);
  const [justCompleted, setJustCompleted] = useState(null);
  const [showCompletedAlert, setShowCompletedAlert] = useState(false);
  const [testFile, setTestFile] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [filePrefix, setFilePrefix] = useState(null);
  const [testCustomerFilePrefix, setTestCustomerFilePrefix] = useState(null);
  const [showTestFileSuccess, setShowTestFileSuccess] = useState(false);

  useEffect(() => {
    if (lensRoles && !lensRoles?.includes('administrator')) {
      navigate('/400');
    }
  }, [lensRoles, navigate])

  const selectMatchConfigs = useCallback((data) => {
    setMatchConfigs(data);
    return data;
  }, [setMatchConfigs])

  const {
    data: customerConfigs,
    isLoading: isCustomerConfigsLoading,
    refetch: refetchCustomerConfigs,
    isError: isCustomerConfigsError
  } = useQuery(['customerConfigs', carrierId], () => getCustomerFileConfigurations(carrierId), {select: sortCustomerConfigs, enabled: Boolean(carrierId)});
  const {
    data: verifiedDeathConfigs,
    isLoading: isVerifiedDeathConfigsLoading,
    refetch: refetchVerifiedDeathConfigs,
    isError: isVerifiedDeathConfigsError
  } = useQuery(['verifiedDeathConfigs', carrierId], () => getDeathFileConfigurations(carrierId), {enabled: Boolean(carrierId)});
  const {
    isLoading: isMatchConfigsLoading,
    refetch: refetchMatchConfigs,
    isError: isMatchConfigsError
  } = useQuery(['matchConfigs', carrierId], () => getMatchFileConfigurations(carrierId), {select: selectMatchConfigs, enabled: Boolean(carrierId)});
  const {
    data: holidayCalendars,
    isLoading: isHolidayCalendarsLoading,
    isError: isHolidayCalendarsError
  } = useQuery(['holidayCalendars', carrierId], () => getHolidayCalendars(carrierId), {enabled: Boolean(carrierId)});
  const {
    data: emails,
    isLoading: isEmailsLoading,
    isError: isEmailsError
  } = useQuery(['emails', carrierId], () => getEmailAddresses(carrierId), {enabled: Boolean(carrierId)});
  const {
    data: sftpUsernames,
    isLoading: isSftpUsernamesLoading,
    isError: isSftpUsernamesError
  } = useQuery(['sftpUsernames', carrierId], () => getSftpUsernames(carrierId), {enabled: Boolean(carrierId)});
  const {
    data: transferFrequencies,
    isLoading: isTransferFrequenciesLoading,
    isError: isTransferFrequenciesError
  } = useQuery(['transferFrequencies', carrierId], () => getTransferFrequencies(carrierId), {enabled: Boolean(carrierId)});

  const customerFilePrefixes = customerConfigs?.map(config => config?.file?.prefix);
  const deathFilePrefixes = verifiedDeathConfigs?.map(config => config?.file?.prefix);
  const matchFilePrefixes = matchConfigs?.map(config => config?.file?.prefix);
  const isLoading = isCustomerConfigsLoading || isVerifiedDeathConfigsLoading || isMatchConfigsLoading || isHolidayCalendarsLoading || isEmailsLoading || isSftpUsernamesLoading || isTransferFrequenciesLoading;
  const isError = isCustomerConfigsError || isVerifiedDeathConfigsError || isMatchConfigsError || isEmailsError || isHolidayCalendarsError || isSftpUsernamesError || isTransferFrequenciesError;

  const goToMatchSetup = (result) => {
    setShowAddCustConfig(false);
    setShowAddMatchConfig(true);
    setCurrentCustomerConfig(result);
    window.scrollTo(0, 0);
  }

  async function finishSetup() {
    await refetchMatchConfigs();
    setShowAddCustConfig(false);
    setShowAddMatchConfig(false);
    window.scrollTo(0, 0);
    setJustCompleted(currentCustomerConfig?.file?.prefix);
  }

  function checkJustCompleted(result) {
    if (result?.file?.prefix === justCompleted) {
      return true;
    }
  }

  function cancel() {
    window.scroll(0,0);
    setShowAddCustConfig(false);
    setShowAddMatchConfig(false);
    setShowAddDeathConfig(false);
    setShowManageMatches(false);
    setShowManageCustVdf(false);
    setJustCompleted(null);
    setTestFile(null);
  }

  function manageMatches(result) {
    window.scroll(0,0);
    setShowManageMatches(true);
    setCurrentMatchConfig(result);
  }

  function manageCustVdf(result) {
    window.scroll(0,0);
    setShowManageCustVdf(true);
    setCurrentCustomerConfig(result);
  }

  function backToAll() {
    setShowAddCustConfig(false);
    setShowAddDeathConfig(false);
    setShowAddMatchConfig(false);
  }

  return (
        <>
          <Grid container>
            {showCompletedAlert &&
              <AlertWrapper>
                <Alert onClick={() => setShowCompletedAlert(false)}>Configuration Added Successfully</Alert>
              </AlertWrapper>
            }
            {showTestFileSuccess &&
              <AlertWrapper>
                <Alert onClick={() => setShowTestFileSuccess(false)}>Match results test file requested.</Alert>
              </AlertWrapper>
            }
            <Grid item sm={9}>
              {!showAddCustConfig && !showAddMatchConfig && !showManageMatches && !showManageCustVdf && !showAddDeathConfig && !testFile &&
                <PageHeader title='List Configurations' subtitle="View of all your organization's list configurations." icon={<SettingsSuggestRounded/>}/>
              }
              {(showAddCustConfig || showAddMatchConfig) &&
                <PageHeader title='Add Configuration' subtitle='Set up new customer list and match workflow.'/>
              }
              {showAddDeathConfig &&
                <PageHeader title='Add Configuration' subtitle='Set up your verified deaths list.'/>
              }
            </Grid>
            <Grid item sm={3} sx={{textAlign: 'right'}}>
              {!showAddCustConfig && !showAddMatchConfig && !showManageMatches && !showManageCustVdf && !showAddDeathConfig && !testFile &&
                <MultiConfigButton setShowAddCustConfig={setShowAddCustConfig} setShowAddDeathConfig={setShowAddDeathConfig} disabled={isLoading || isError}/>
              }
              {(showAddCustConfig || showAddMatchConfig || showAddDeathConfig) &&
                <Button sx={{mt: 2}} onClick={backToAll}><ArrowBack/> Back To All Lists</Button>
              }
            </Grid>
          </Grid>
          {!showAddCustConfig && !showAddMatchConfig && !showManageMatches && !showManageCustVdf && !showAddDeathConfig && !testFile &&
            <>
              <Typography variant='h5' sx={{mb: 4}}>Customer Lists ({isCustomerConfigsLoading ? !isError && <Skeleton sx={{display: 'inline-block'}} width='20px'/> : !isError && customerConfigs?.length})</Typography>
              {isCustomerConfigsLoading || isMatchConfigsLoading || isTransferFrequenciesLoading || isError ?
                <SkeletonCard customer apiError={isError}/>
                :
                customerConfigs?.map((customerConfig) => (
                  <ConfigCard
                    config={customerConfig}
                    associatedMatchConfig={matchConfigs.find((matchConfig) => matchConfig.customerFilePrefix === customerConfig.file.prefix)}
                    key={customerConfig?.file?.prefix}
                    title='Customer List'
                    showMatchFileDropdown
                    frequencies={transferFrequencies}
                    customerConfigs={customerConfigs}
                    matchConfigs={matchConfigs}
                    setMatchConfigs={setMatchConfigs}
                    justCompleted={checkJustCompleted(customerConfig)}
                    setJustCompleted={setJustCompleted}
                    goToMatchSetup={() => goToMatchSetup(customerConfig)}
                    manageMatches={manageMatches}
                    manageCustVdf={manageCustVdf}
                    testFile={setTestFile}
                    setFileType={setFileType}
                    setFilePrefix={setFilePrefix}
                    setCustomerFilePrefix={setTestCustomerFilePrefix}
                    testCustomerFilePrefix={testCustomerFilePrefix}
                    showTestFileSuccess={showTestFileSuccess}
                    setShowTestFileSuccess={setShowTestFileSuccess}
                  />
                ))
              }
              <Typography variant='h5' sx={{mb: 4, mt: 4}}>Verified Deaths ({!verifiedDeathConfigs ? !isError && <Skeleton sx={{display: 'inline-block'}} width='20px'/> : !isError && verifiedDeathConfigs?.length})</Typography>
              {isVerifiedDeathConfigsLoading || isError ?
                <SkeletonCard apiError={isError}/>
                :
                verifiedDeathConfigs?.map((verifiedDeathConfig) => (
                  <ConfigCard
                    key={verifiedDeathConfig?.file?.prefix}
                    title='Verified Deaths List'
                    config={verifiedDeathConfig}
                    manageCustVdf={manageCustVdf}
                    justCompleted={checkJustCompleted(verifiedDeathConfig)}
                    setJustCompleted={setJustCompleted}
                    testFile={setTestFile}
                    setFileType={setFileType}
                    setFilePrefix={setFilePrefix}
                    setCustomerFilePrefix={setTestCustomerFilePrefix}
                  />
                ))
              }
              {verifiedDeathConfigs?.length === 0 &&
                <Card>
                  <Typography sx={{p: 4}}>No lists to display.</Typography>
                </Card>
              }
            </>
          }
          {showAddCustConfig &&
            <AddCustomerConfiguration
              customerResult={customerConfigs[0]}
              availableEmailAddresses={emails}
              customerPrefixes={customerFilePrefixes}
              goToMatchSetup={goToMatchSetup}
              cancel={cancel}
              usernames={sftpUsernames}
              reload={refetchCustomerConfigs}
            />
          }
          {showAddDeathConfig &&
            <AddDeathConfiguration
              availableEmailAddresses={emails}
              setJustCompleted={setJustCompleted}
              customerConfig={customerConfigs[0]}
              reload={refetchVerifiedDeathConfigs}
              cancel={cancel}
              setShowAddDeathConfig={setShowAddDeathConfig}
              usernames={sftpUsernames}
              deathPrefixes={deathFilePrefixes}
            />
          }
          {showAddMatchConfig &&
            <AddMatchFileConfiguration
              customerConfigs={customerConfigs}
              currentCustomerConfig={currentCustomerConfig}
              matchConfigs={matchConfigs}
              matchPrefixes={matchFilePrefixes}
              finishSetup={finishSetup}
              cancel={cancel}
              availableEmailAddresses={emails}
              holidayCalendarId={holidayCalendars?.[0]?.id}
            />
          }
          {showManageMatches &&
            <ManageMatchResults
              data={currentMatchConfig}
              cancel={cancel}
              reload={refetchMatchConfigs}
              frequencies={transferFrequencies}
              customerConfigs={customerConfigs}
              matchConfigs={matchConfigs}
            />
          }
          {showManageCustVdf &&
            <ManageCustVdfLists
              data={currentCustomerConfig}
              cancel={cancel}
              reload={currentCustomerConfig?.configurationType === 'customer_records' ? refetchCustomerConfigs : refetchVerifiedDeathConfigs}
            />
          }
          <TestFile
            type={testFile}
            fileType={fileType}
            filePrefix={filePrefix}
            customerFilePrefix={testCustomerFilePrefix}
            setShowSuccess={setShowTestFileSuccess}
            cancel={cancel}
          />
        </>
  );
}

export default ListConfigurations;
