import React, { useState, useEffect, useRef } from 'react';

import { Box, styled, Tooltip, IconButton, Avatar, Popover, Skeleton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useAppContext } from 'src/AppContext.js';
import LogoutButton from 'src/components/LogoutButton';


const MenuUserBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
`
);

function HeaderUserbox() {
  const [anchorEl, setAnchorEl] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const {oktaUser} = useAppContext();

  const [initials, setInitials] = useState(null); 

  useEffect(() => {    
    if(oktaUser) {
      const _initials = oktaUser.given_name.substring(0, 1) + oktaUser.family_name.substring(0, 1);
      setInitials(_initials);
    }
  }, [oktaUser]);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };
  const handleClose = (event) => {
    event.preventDefault();
    setAnchorEl(null);
    setIsOpen(false);
  };

  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);
  
  return (
    <>
      <Box ref={wrapperRef} sx={{ display: 'flex', alignItems: 'center', textAlign: 'right', float: 'right' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ml: 2}}
            aria-controls={isOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : undefined}
            disabled={!initials}
          >
            {initials ?
              <Avatar sx={{ width: 44, height: 44, fontSize: '20px', fontWeight: 400 }}>{ initials }</Avatar>
              :
              <Skeleton width={44} height={44} variant='circular'/>
            }
            <ArrowDropDownIcon/>
          </IconButton>
        </Tooltip>
      </Box>
      
      
        <Popover
          disableScrollLock
          anchorEl={anchorEl}
          id="account-menu"
          className="account-menu"
          open={isOpen}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: '-3px',
              borderRadius: '4px',
              right: '16px',
              top: '30px',
              width: '160px',
              position: 'absolute',
              left: 'auto'
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        ><MenuUserBox
        sx={{
          minWidth: 210
        }}
        display="flex"
      >
            <LogoutButton />
            </MenuUserBox>
        </Popover>
    </>
  );
}

export default HeaderUserbox;
