import {useState} from 'react';
import {Paper, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, styled} from '@mui/material';
import {times} from 'lodash-es';
import PropTypes from 'prop-types';

import LoadError from 'src/components/LoadError';
import Row from './row';
import SkeletonRow from './skeletonRow';


const TableWrapper = styled(TableContainer)(
  ({theme}) => `
    .MuiTableCell-head {
      text-transform: none;
      font-weight: 700;
      font-size: 15px;
      color: ${theme.palette.text.primary};
    }
    .MuiTableRow-head {
      background: ${theme.palette.background.paper};
      vertical-align: top;
    }
    .MuiTableCell-body {
      // &.action {
      //   vertical-align: middle;
      // }
      vertical-align: baseline;
    }
  `
);

function DataTable({rows, columns, loading, apiError, noRowsText, numSkeletonRows=5, CollapsibleContent=null, getIsRowCollapsible=() => true, paginated=false}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const hasNoRows = rows && rows.length === 0 && !loading;

  const paginatedRows = rows?.filter((r, ind) => {
    return ind >= rowsPerPage * page && ind < rowsPerPage * (page + 1);
  });

  const displayedRows = paginated ? paginatedRows : rows;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  if (hasNoRows) {
    return (
      <Paper sx={{p: 4}}>
        <Typography>{noRowsText}</Typography>
      </Paper>
    );
  }

  return (
    <TableWrapper component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {CollapsibleContent && <TableCell>Action</TableCell>}
            {columns?.map((column) =>
              <TableCell key={column?.field}>{column?.headerName}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {apiError ? <TableRow><TableCell colSpan={columns.length + (CollapsibleContent && 1)}><LoadError/></TableCell></TableRow> :
            <>
              {loading &&
                times(numSkeletonRows, (i) => <SkeletonRow key={i} numCols={columns.length + (CollapsibleContent && 1)}/>)
              }
              {!loading &&
                displayedRows?.map((row) => <Row key={JSON.stringify(row)} rowData={row} columns={columns} CollapsibleContent={CollapsibleContent} rowCollapsible={getIsRowCollapsible(row)}/>)
              }
            </>
          }
        </TableBody>
      </Table>
      {paginated &&
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    </TableWrapper>
  );
}

DataTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  apiError: PropTypes.bool,
  noRowsText: PropTypes.string,
  numSkeletonRows: PropTypes.number,
  CollapsibleContent: PropTypes.element,
  getIsRowCollapsible: PropTypes.func,
  paginated: PropTypes.bool
};

export default DataTable;
