import {Typography, Card, Grid, styled} from '@mui/material';
import PropTypes from 'prop-types';


const CardWrapper = styled(Card)(
  ({ theme }) => `
    .card-header {
      border-bottom: 1px solid ${theme.palette.text.primary}22;
      padding-bottom: 15px;
    }
  `
);

function DateModifiedCard({auditDate, auditUser, isOwnedByLens=false}) {
  return (
    <CardWrapper className="transfer-date-card" sx={{ mb: 3 }}>
      <Grid className="card-header" container spacing={2} sx={{pl: 2, pr: 2, pb: 1, pt: 2}}>
        <Grid item xs={12}>
          <Typography className="transfer-date-added" variant="subtitle1">Date Modified</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{p: 3, pl: 5, mb: 1}}>
        <Grid item xs={12}>
          <Typography variant="body1">
            {auditDate}
          </Typography>
          <Typography variant="body2" color="text.secondary" mt={0.5}>
            By: {auditUser}
          </Typography>
          {isOwnedByLens &&
            <Typography variant="body2" color="text.secondary">
              Owner: LENS
            </Typography>
          }
        </Grid>
      </Grid>
    </CardWrapper>
  );
}

DateModifiedCard.propTypes = {
  auditDate: PropTypes.string,
  auditUser: PropTypes.string,
  isOwnedByLens: PropTypes.bool
};

export default DateModifiedCard;
