import {Typography, Box, Paper, Grid, Divider, TableContainer, Table, TableRow, TableCell} from '@mui/material';
import PropTypes from 'prop-types';


const missingFieldHeaders = ['SSN', 'DOB', 'First Name', 'Last Name', 'City', 'State'];
const missingFields = ['ssn', 'dob', 'first_name', 'last_name', 'city', 'state'];

function CollapsibleContent({rowData}) {
  const {totalRecords, numOfErrors, missingStats} = rowData;

  return (   
    <Box sx={{p: 3}}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>Record Overview</Typography>
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={5} sx={{ minWidth: '250px' }}>
          <Typography variant="subtitle2" color="text.primary">{totalRecords} Total Records</Typography>
          <Typography variant="caption">Number of records provided in this list.</Typography>
        </Grid>
        <Grid item xs={1}>
          <Divider sx={{ height: '115% !important', mt: '-4px', ml: 4 }} orientation="vertical" variant="middle" display="flex" />
        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle2" color="text.primary">{numOfErrors} Errors</Typography>
          <Typography variant="caption">Total number of records with errors found.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="text.primary" sx={{ mt:2, mb: 0.5 }}>Records Missing Matching Criteria</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{border: 1, borderColor: 'lightgray'}}>
            <Table size="small">
              <TableRow>
                {missingFieldHeaders.map((header) => (
                  <TableCell key={header}>
                    <Typography variant="subtitle2" color="text.primary">{header}</Typography>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {missingFields.map((field) => (
                  <TableCell key={field}>
                    { missingStats && missingStats[field]?.count >= 0 ? missingStats[field].count : '-' }
                  </TableCell>
                ))}
              </TableRow>
            </Table>
          </TableContainer>
        </Grid>
        <Typography variant='caption' sx={{mt: 0.5}}>Providing this information in your customer list can provide higher quality match results.</Typography>
      </Grid>
    </Box>
  );
}

CollapsibleContent.propTypes = {
  rowData: PropTypes.object
};

export default CollapsibleContent;
