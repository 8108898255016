import {Grid, Card, styled, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button, Skeleton} from '@mui/material';
import {times} from 'lodash-es';
import PropTypes from 'prop-types';

import LoadError from 'src/components/LoadError';


const CardWrapper = styled(Grid)(
  ({ theme }) => `
    .MuiCard-root {
      padding: 0px !important;
    }
    .lock-icons {
      width: 16px;
      float: left;
      margin-right: 4px;
    }
    .account-id-row {
      border-bottom: 1px solid ${theme.palette.common.black}66;
    }
    .pgp-button {
      margin-left: -10px;
      font-weight:500;
      color: grey;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .copy-click-wrapper {
      float: left;
    }
    th {
      color: ${theme.palette.common.black};
      font-size: 15px;
    }
    tr {
      border-top: 2px solid ${theme.palette.common.black}33;
    }
    tr.MuiTableRow-head {
      border-top: 3px solid ${theme.palette.common.black}33;
      border-bottom: 1px solid ${theme.palette.common.black}90;
    }
  `
);

function SkeletonTable({apiError}) {
  return (
    <CardWrapper>
      <Card sx={{minWidth: 275, textAlign: 'left', mt: 3, mb: 3}}>
        <Grid container>
          <Grid item xs={10} sx={{p: 2}}>
            {!apiError &&
              <>
                <Typography variant='subtitle1'><Skeleton width='400px'/></Typography>
                <Typography variant='body2'><Skeleton width='300px'/></Typography>
              </>
            }
          </Grid>
          <Grid item xs={2} sx={{ p: 2, textAlign: 'right' }}>
            <Button variant='outlined' color='primary' disabled>Manage</Button>
          </Grid>
        </Grid>
        <Table sx={{minWidth: 650}}>
          {apiError ? <LoadError sx={{minHeight: '200px'}}/> :
            <>
              <TableHead>
                <TableRow>
                  {times(3, () => <TableCell sx={{ py: '7px' }}><Skeleton/></TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {times(3, () => <TableCell><Skeleton/></TableCell>)}
                </TableRow>
              </TableBody>
            </>
          }
        </Table>
      </Card>
    </CardWrapper>
  );
}

SkeletonTable.propTypes = {
  apiError: PropTypes.bool
};

export default SkeletonTable;
