import {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useFormik, FormikProvider} from 'formik';
import * as yup from 'yup';
import {Button, FormControlLabel, Radio, RadioGroup, TextField, Typography, Box, Select, MenuItem, InputLabel, FormControl, FormHelperText, Alert, Grid} from '@mui/material';
import {ArrowBack, ErrorOutline} from '@mui/icons-material';
import {Link, useNavigate} from 'react-router-dom';

import {useAppContext} from 'src/AppContext.js';
import {setupCustomerFile, returnLambda, submitIDGenerateLambda} from 'src/api';
import {getCurrentDate} from 'src/utilities/getDate';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import {useCurrentUserEmail} from 'src/utilities/getCurrentUser';
import AwsFields from 'src/components/AwsFields';
import AccountIDSpinner from './accountIdSpinner';
import ProcessingCompleted from './processingCompleted';
import PgpRotationField from 'src/components/FormFields/pgpRotation';
import ErrorAlert from 'src/components/ErrorAlert';
import PrefixField from 'src/components/FormFields/filePrefix';
import SftpUsername from 'src/components/FormFields/sftpUsername';
import SftpSsh from 'src/components/FormFields/sftpSsh';
import TooltipInline from 'src/components/TooltipInline';
import NotificationPreferences from 'src/components/FormFields/notificationPreferences';
import {prefixRestrictionText, prefixRestrictionRegex} from 'src/utilities/filePrefixRestriction';
import LoadError from 'src/components/LoadError';


yup.addMethod(yup.array, 'unique', function (payload, message, mapper = a => a) {
  return this.test('unique', message, function (list) {
    const masterEmailList = list.filter(x => x).concat(payload)
    return masterEmailList.length === new Set(masterEmailList.map(mapper)).size;
  });
});

yup.addMethod(yup.string, 'containsId', function (id, message) {
  return this.test('containsId', message, function (value) {
    if (value && (value.length > 0)) {
      return value.includes(id);
    }
    else {
      return true;
    }
  });
});

const validationSchema = yup.object().shape({
  filePrefix: yup
    .string()
    .matches(prefixRestrictionRegex, prefixRestrictionText)
    .max(24, 'A maximum of 24 characters are allowed')
    .required('File prefix is required'),
  fileType: yup
    .string()
    .required('Field is required'),
  encryptCustFile: yup
    .boolean()
    .when('transMethod', (transMethod) => {
      if (transMethod === 'sftp') {
        return yup.boolean().required()
      }
    }
    ),
  pgpKeyRotation: yup
    .string()
    .when('transMethod', (transMethod) => {
      if (transMethod === 'sftp') {
        return yup.string().required()
      }
    }
    ),
  transMethod: yup
    .string()
    .required(),
  usernameSFTP: yup
    .string()
    .when('transMethod', (transMethod) => {
      if (transMethod === 'sftp') {
        return yup.string().max(100, 'A maximum of 100 characters are allowed').matches(/^[^.@-].*$/, "Username can’t start with hyphen –, @ symbol or period").min(3, 'A minimum of 3 characters are allowed').required('Field is required')
      } else {
        return yup.string()
      }
    }
    ),
  SSHRSAKey: yup
    .string()
    .when('transMethod', (transMethod) => {
      if (transMethod === 'sftp') {
        return yup.string().required('Field is required')
      } else {
        return yup.string()
      }
    }
    ),
  transferFreq: yup
    .string()
    .required(),
  awsAccountID: yup
    .string()
    .when('transMethod', (transMethod) => {
      if (transMethod === 'awsS3') {
        return yup.string().min(12, 'Account ID must be exactly 12 digits long').matches(/^\d+$/, 'Numeric characters only.').required('Field is required')
      } else {
        return yup.string()
      }
    }
    ),
  CustFileArnS3Bucket: yup
    .string()
    .when('transMethod', (transMethod) => {
      if (transMethod === 'awsS3') {
        return yup.string().required('Field is required')
          .matches(/^arn:aws:s3:::/, "Incorrect format entered. ARN should begin with: arn:aws:s3:::")
          .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
      } else {
        return yup.string()
      }
    }
    ),
  CustFileKmsKeyArn: yup
    .string()
    .when('awsAccountID', (awsAccountID) => {
      return yup
        .string()
        .matches(/arn:aws:kms:/, "Incorrect format entered. ARN should begin with: arn:aws:kms:")
        .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
        .containsId(awsAccountID, 'ARN entered is incorrect. ARN should include account ID: arn:aws:kms:<AWSRegion>:<AWSAccountID>')
    }
    ),
  CustFileIamRoleArn: yup
    .string()
    .when(['transMethod', 'awsAccountID'], (transMethod, awsAccountID) => {
      if (transMethod === 'awsS3') {
        return yup.string()
          .matches(/^arn:aws:iam::/, "Incorrect format entered. ARN should begin with: arn:aws:iam::")
          .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
          .containsId(awsAccountID, 'ARN entered is incorrect. ARN should include account ID: arn:aws:iam::<AWSAccountID>')
          .required('Field is required')
      } else {
        return yup.string()
      }
    }
    ),
  existingEmails: yup
    .array()
    .when("emails", (emails) => {
      if (emails.length < 1) {
        return yup.array().min(1, "Must enter email address");
      }
      if (!emails[0]) {
        return yup.array().min(1, "Must enter email address");
      }
      return yup.array();
    }),
  emails: yup
    .array()
    .when("existingEmails", (existingEmails) => {
      if (existingEmails) {
        return yup.array().unique(existingEmails, '').of(yup.string().email())
      }
      else {
        return yup.array().of(yup.string().email())
      }
    }),
}, ['emails', 'existingEmails']);


function CustomerFileSetupForm({refetchConfigs, availableEmailAddresses, apiError}) {
  const { carrierConfig } = useAppContext();
  const {userEmail} = useCurrentUserEmail();

  const setupCustomerFileApi = async function (values, emails) {
    const payload = {
      file: {
        prefix: values.filePrefix,
        type: values.fileType
      },
      transfer: {
        type: values.transMethod,
        frequency: values.transferFreq
      },
      notificationEmails: emails
    };
    if (values.transMethod === 'sftp') {
      payload.transfer.sftp = {
        username: values.usernameSFTP,
        sshKey: values.SSHRSAKey,
      }
      if (values.encryptCustFile === true || values.encryptCustFile === 'true') { // even though yup def is boolean, this can be a string depending on the validation flow
        payload.transfer.sftp.pgpKeyRotationInYears = values.pgpKeyRotation;
      }
    }
    if (values.transMethod === 'awsS3') {
      payload.transfer.awsS3 = {
        s3Bucket: values.CustFileArnS3Bucket,
        iamRole: values.CustFileIamRoleArn
      }
      if (values.CustFileKmsKeyArn.length > 0) {
        payload.transfer.awsS3.kmsArn = values.CustFileKmsKeyArn
      }
    }

    return setupCustomerFile(carrierConfig.carrierId, payload);
  };

  const date = getCurrentDate();

  const inputRef = useRef();
  const sshRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [usernameInUseError, setUsernameInUseError] = useState(false);
  const [sshKeyError, setSshKeyError] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const [badUsernames, setBadUsernames] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const formik = useFormik({
    initialValues: {
      filePrefix: 'customer_file',
      fileType: 'json',
      encryptCustFile: true,
      pgpKeyRotation: '2',
      transMethod: 'sftp',
      usernameSFTP: '',
      SSHRSAKey: '',
      transferFreq: 'Daily',
      awsAccountID: '',
      CustFileArnS3Bucket: '',
      CustFileKmsKeyArn: '',
      CustFileIamRoleArn: '',
      existingEmails: null,
      emails: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        setGenericError(false);
        let _allEmails
        if (values.existingEmails.length > 0 && values.emails.length > 0) {
          _allEmails = values.existingEmails.concat(values.emails.filter(x => x));
        } else if (values.existingEmails.length > 0) {
          _allEmails = values.existingEmails;
        } else {
          _allEmails = values.emails;
        }
        for (let i = 0; i < _allEmails.length; i++) {
          if (_allEmails[i] === '') {
            _allEmails.splice(i, 1);
          }
        }
        const result = await setupCustomerFileApi(values, _allEmails);
        if (result.statusCode === 400) {
          setIsLoading(false);
          if (result.message === 'User already exists') {
            setUsernameInUseError(true);
            setBadUsernames([...badUsernames, formik.values.usernameSFTP]);
            inputRef.current.scrollIntoView();
          }
          else if (result.message === 'Unsupported SSH key format') {
            setSshKeyError(true);
            sshRef.current.scrollIntoView();
          }
          else {
            window.scrollTo(0, 0);
            setGenericError(true);
          }
        } else if (result.statusCode === 500) {
          setIsLoading(false);
          window.scrollTo(0, 0);
          setGenericError(true);
        } else {
          await refetchConfigs();
          setIsLoading(false);
        }
      }
      catch (e) {
        setIsLoading(false);
        setGenericError(true);
      }
    },
  });

  const [currentEmailInit, setCurrentEmailInit] = useState(false);
  const [formInitIsSet, setFormInitIsSet] = useState(false);

  useEffect(() => {
    if (!formik?.values?.existingEmails && (currentEmailInit === false)) {
      if (userEmail) {
        formik.setFieldValue("existingEmails", [userEmail]);
        setCurrentEmailInit(true);
      }
    }
    if (currentEmailInit === true && formInitIsSet === false) {
      setFormInitIsSet(true);
    }
  }, [formik, currentEmailInit, formInitIsSet, userEmail]);

  useEffect(() => {
    if (badUsernames?.includes(formik.values.usernameSFTP)) {
      setUsernameInUseError(true);
    } else {
      setUsernameInUseError(false);
    }
  }, [badUsernames, formik.values.usernameSFTP]);



  const [processingID, setProcessingID] = useState(false);
  const [accountIDProcessed, setAccountIDProcessed] = useState(false);
  const [showAwsSetupFields, setShowAwsSetupFields] = useState(false);

  const finishProcessing = () => {
    setShowAwsSetupFields(true);
    document.body.style.overflow = '';
    window.scroll(0, 1050);
  }

  const [evadataLambdaArn, setEvadataLambdaArn] = useState('');
  const [evadataLambdaRoleArn, setEvadataLambdaRoleArn] = useState('');
  const [accountIdError, setAccountIdError] = useState(false);
  const navigate = useNavigate();

  const submitID = async function () {
    setProcessingID(true);
    window.scrollTo(0, document.body.scrollHeight);
    document.body.style.overflow = 'hidden';
    const payload = {
      accountId: formik.values.awsAccountID,
      copyEnabled: "true"
    }
    const result = await submitIDGenerateLambda(carrierConfig.carrierId, payload);
    if (result) {
      getLambda().catch((error) => {
        navigate('/500?page=customer-file-setup&error=' + error);
      });
    }
  }

  const getLambda = async function () {
    const payload = {
      accountId: formik.values.awsAccountID,
      copyEnabled: "true"
    }
    const lambdaDetails = await returnLambda(carrierConfig.carrierId, payload);

    if (lambdaDetails) {
      if (lambdaDetails.status === 'failed') {
        setAccountIdError(true);
        setAccountIDProcessed(false);
        setProcessingID(false);
      }
      else {
        setEvadataLambdaArn(lambdaDetails.evadataLambdaArn);
        setEvadataLambdaRoleArn(lambdaDetails.evadataLambdaRoleArn);
        setAccountIdError(false);
        setAccountIDProcessed(true);
        setProcessingID(false);
      }
    }
  }

  const validateErrors = () => {
    if (formik.errors) {
      setShowErrorAlert(true);
      window.scroll(0, 0);
    }
  }
  return (
    <>
      {isLoading && <LoadingIcon />}
      {<ErrorAlert formik={formik} showErrorAlert={showErrorAlert} />}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          {apiError ? <LoadError/> :
            <Box sx={{ p: 5 }}>
              <Alert className={genericError ? 'show' : 'hide'} severity="error" sx={{ mb: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
              <Box>
                <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>FILE DETAILS</Typography>
                <PrefixField name="filePrefix" label="File Prefix" formik={formik} helperText={'24 character limit. Exclude file extension.'} />
                <Typography className="file-type-cf" variant="body1" color="text.secondary">File Type</Typography>
                <RadioGroup
                  id="fileType"
                  name="fileType"
                  className="file-type-cf-radio"
                  value={formik.values.fileType}
                  onChange={formik.handleChange}
                  sx={{ mb: 3 }}
                >
                  <FormControlLabel value="json" control={<Radio />} label="JSON" />
                  <FormControlLabel value="csv" control={<Radio />} label="CSV" />
                </RadioGroup>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ display: 'inline-flex' }} variant="body1" color="text.secondary">File Name Example:  </Typography><TooltipInline placement="right" display='inline-flex' tip="Name your file as shown. Including the date (optional) the file is sent to LENS helps when transferring files daily, weekly or monthly." />
                </Box>
                <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{formik.values.filePrefix}-{date}.{formik.values.fileType}
                </Typography>

                <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>Prefix and file type are used for LENS to identify your list.</Typography>

                <Typography variant="subtitle1" color="text.primary">Choose transfer frequency schedule.</Typography>

                <FormControl sx={{ mt: 2, mb: 2 }}>
                  <InputLabel htmlFor="transferFreq">Transfer Frequency</InputLabel>
                  <Select
                    id="transferFreq"
                    name="transferFreq"
                    value={formik.values.transferFreq}
                    onChange={formik.handleChange}
                    label="File Transfer Frequency"
                    sx={{ width: '400px' }}
                  >
                    <MenuItem selected value="Daily">Daily (Monday-Friday)&nbsp;<span className="recommended">&nbsp;Recommended</span></MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                    <MenuItem value="Once">Once (One time submission)</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>Schedule informs LENS on when to expect your list. This can be changed when your LENS account is moved to Live.</Typography>

                <hr />

                <Typography className="trans-location-cf" variant="h6" color="text.secondary" sx={{ mb: 4, mt: 4 }}>TRANSFER LOCATION & ENCRYPTION</Typography>

                <Typography className="trans-location-method-cf" variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 4 }}>Choose transfer location method.</Typography>

                <FormControl className="trans-method-select-cf" sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    id="transMethod"
                    name="transMethod"
                    value={formik.values.transMethod}
                    onChange={formik.handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value={'sftp'}>SFTP (Secure File Transfer Protocol)</MenuItem>
                    <MenuItem value={'awsS3'}>AWS S3 (Simple Storage Service)</MenuItem>
                  </Select>
                </FormControl>


                {formik.values.transMethod === 'sftp' &&
                  <>
                    <Typography variant="body2" color="text.primary" sx={{ mb: 4, mt: 1 }}>LENS SFTP server hostname will be provided on the summary page.</Typography>

                    <Typography ref={inputRef} variant="subtitle1" color="text.primary" sx={{ mb: 2, mt: 4 }}>Provide credentials to use when accessing LENS SFTP to transfer your Customer list.</Typography>

                    <SftpUsername formik={formik} />

                    {usernameInUseError && <FormHelperText sx={{ mt: '-20px', mb: 4 }} error={true}><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} /> Username entered is already in use, try a different name.</FormHelperText>}

                    <SftpSsh formik={formik} mb={3} />

                    {sshKeyError && <FormHelperText sx={{ mt: '-20px', mb: 4 }} error={true}><ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} /> Unsupported ssh key format. RSA, ECDSA and ED25519 are supported formats.</FormHelperText>}

                    <Typography variant="h6" color="text.primary" sx={{ mb: 2, mt: 4 }}>Select File Encryption Preference.</Typography>

                    <RadioGroup
                      id="encryptCustFile"
                      name="encryptCustFile"
                      value={formik.values.encryptCustFile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{ mb: 3 }}
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Encrypt list with public PGP key created by LENS (Public key will be available to copy or download on summary page)." />

                      {(formik.values.encryptCustFile === 'true' || formik.values.encryptCustFile === true) &&
                        <PgpRotationField name="pgpKeyRotation" label="PGP Key Rotation" formik={formik} />
                      }

                      <FormControlLabel value={false} control={<Radio />} label="Do not encrypt list with PGP key." />
                    </RadioGroup></>
                }

                {formik.values.transMethod === 'awsS3' && !showAwsSetupFields ?
                  <>
                    <Typography className="trans-location-method-cf" variant="subtitle1" color="text.primary" sx={{ mb: 1, mt: 4 }}>For security reasons, enter your organization’s AWS Account ID.</Typography>

                    <Alert className={accountIdError ? 'show' : 'hide'} severity="error" sx={{ mb: 2 }}>The AWS Account ID entered is invalid. Please review the ID entered, or contact LENS support to troubleshoot this issue.</Alert>

                    <TextField
                      fullWidth
                      id="awsAccountID"
                      name="awsAccountID"
                      label="AWS Account ID"
                      value={formik.values.awsAccountID}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.awsAccountID && Boolean(formik.errors.awsAccountID)}
                      helperText={(formik.errors.awsAccountID && formik.touched.awsAccountID) ? formik.errors.awsAccountID : 'Numeric characters only.'}
                      inputProps={{ maxLength: 12 }}
                      sx={{ mb: 3 }}

                    />
                    <Grid container spacing={0} sx={{ m: 0, p: 0 }}>
                      <Grid item xs>
                        <Button onClick={submitID} size="small" sx={{ mb: 3 }} variant="outlined" disabled={(formik.errors.awsAccountID || processingID)}>
                          Submit ID & GENERATE LAMBDA
                        </Button>
                      </Grid>
                      {processingID && <AccountIDSpinner />}
                      {(accountIDProcessed && !showAwsSetupFields) && <ProcessingCompleted finishProcessing={finishProcessing} />}
                    </Grid>
                  </>
                  : ''}

                {formik.values.transMethod === 'awsS3' && showAwsSetupFields ?
                  <>
                    <AwsFields
                      instructionText={'Provide output values from the Cloud Formation Template for LENS to access your Customer list.'}
                      showLambdaArn
                      accountId={formik.values.awsAccountID}
                      evadataLambdaArn={evadataLambdaArn}
                      evadataLambdaRoleArn={evadataLambdaRoleArn}
                      cfTemplateFile="carrier-aws-customer-files-option.yml"
                      cfTemplateFileName={"lens-carrier-aws-" + formik.values.filePrefix + "-files-option.yml"}
                      cfTemplateLabel="Customer File Template" />

                    <TextField
                      fullWidth
                      id="CustFileArnS3Bucket"
                      name="CustFileArnS3Bucket"
                      label="S3 Bucket ARN"
                      value={formik.values.CustFileArnS3Bucket}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.CustFileArnS3Bucket && Boolean(formik.errors.CustFileArnS3Bucket)}
                      helperText={formik.touched.CustFileArnS3Bucket ? formik.errors.CustFileArnS3Bucket : 'ARN should begin with: arn:aws:s3:::'}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      id="CustFileKmsKeyArn"
                      name="CustFileKmsKeyArn"
                      label="KMS Key ARN (Provide if encrypting file)"
                      value={formik.values.CustFileKmsKeyArn}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.CustFileKmsKeyArn && Boolean(formik.errors.CustFileKmsKeyArn)}
                      helperText={formik.errors.CustFileKmsKeyArn || 'ARN should begin with: arn:aws:kms:'}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      id="CustFileIamRoleArn"
                      name="CustFileIamRoleArn"
                      label="IAM Role ARN"
                      value={formik.values.CustFileIamRoleArn}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.CustFileIamRoleArn && Boolean(formik.errors.CustFileIamRoleArn)}
                      helperText={formik.touched.CustFileIamRoleArn ? formik.errors.CustFileIamRoleArn : 'ARN should begin with: arn:aws:iam::'}
                      sx={{ mb: 3 }}
                    />

                    <Typography variant="body2" sx={{ mb: 4 }}>LENS uses the provided Role to retrieve your list from the S3 bucket shown. If encrypting list, the KMS Key ARN must be provided for LENS to decrypt.</Typography>
                  </>

                : ''}
                <hr />
                <NotificationPreferences formik={formik} availableEmailAddresses={availableEmailAddresses} existingEmails='existingEmails' newEmails='emails'/>
              </Box>
            </Box>
          }
          <hr />
          <Grid container sx={{ px: 5, pb: 4 }}>
            <Grid item sm={6}>
              <Button LinkComponent={Link} to="/onboarding-welcome" sx={{ mt: 3 }} variant="text" startIcon={<ArrowBack />} disabled={!availableEmailAddresses || apiError}>
                Back
              </Button>

            </Grid>
            <Grid item sm={6} sx={{ textAlign: 'right' }}>
              <Button onClick={validateErrors} type="submit" sx={{ mt: 3 }} variant="outlined" disabled={!availableEmailAddresses || apiError}>
                SAVE & View Summary
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </>
  );
}

CustomerFileSetupForm.propTypes = {
  refetchConfigs: PropTypes.func.isRequired,
  availableEmailAddresses: PropTypes.array,
  apiError: PropTypes.bool
};

export default CustomerFileSetupForm;
