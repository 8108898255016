import {useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {Alert, Container, Typography, Grid, styled, IconButton, Button} from '@mui/material';
import {CompareRounded} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

import {getAllDataLoadsHistory} from 'src/api/index.js';
import {useAppContext} from 'src/AppContext.js';
import EmptyState from 'src/components/EmptyState';
import PageHeader from 'src/components/PageHeader';
import {ReactComponent as EmptyStateSvg} from 'src/images/EmptyStateCompareArrows.svg'
import {useQuery} from 'src/hooks';
import getDateBasedOnUserTimezone from 'src/utilities/getDateBasedOnUserTimezone';
import getColumns from './columns';
import DataTable from 'src/components/DataTable';
import CollapsibleContent from './collapsibleContent';


const AlertWrapper = styled(Container)(
  () => `
  width: 100%;
  max-width: 100% !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0px !important;
  margin-right: 25px !important;
  position: fixed;
  z-index: 999999;
  top: 70px;
    .completed-alert {
      width: calc(100% - 340px);
      border-radius: 4px;
    }
    .alert-close {
      color: #fff;
      font-size: 13px;
    }
    .close-icon {
      margin-left: 3px;
      font-size: 20px;
    }
    @media screen and (max-width: 1279px) {
      .completed-alert {
        width: calc(100% - 50px);
        border-radius: 4px;
      }
    }
    `
);

function MatchResultsDataLoads() {
  const {carrierConfig} = useAppContext();
  const carrierId = carrierConfig?.carrierId;
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(true);
  const [isDownloadError, setIsDownloadError] = useState(false);

  const cutoffTime = getDateBasedOnUserTimezone({hour: 7}, {zone: 'America/Chicago'}, 'h a ZZZZ');
  const deliveryTime = getDateBasedOnUserTimezone({hour: 10}, {zone: 'America/Chicago'}, 'h a ZZZZ');

  const setup = searchParams.get('setup');

  function handleDownloadError() {
    setIsDownloadError(true);
  }

  function clearDownloadError() {
    setIsDownloadError(false);
  }

  function setClosed() {
    setOpen(false);
  }

  const {data: dataLoads, isLoading, isError} = useQuery(['matchFileDataLoads'], () => getAllDataLoadsHistory(carrierId, 'match-files'), {enabled: Boolean(carrierId)});

  function emptyStateText() {
    return (
      <>If you haven’t already submitted your files, get started.<br />
        Check back again later for file status.
      </>
    )
  }

  return (
    <>
      {setup === 'completed' && open && !isLoading &&
        <AlertWrapper>
          <Alert
            className='completed-alert'
            variant='filled'
            severity='success'
            action={
              <IconButton className='alert-close'
                aria-label='close'
                color='inherit'
                size='small'
                onClick={setClosed}
              >
                CLOSE <CloseIcon className='close-icon' fontSize='inherit'/>
              </IconButton>
            }>
              File Setup Complete<br/>
              Start using LENS by sending us your Customer File.
          </Alert>
        </AlertWrapper>
      }
      <PageHeader title='Match Results' subtitle='All recent and historical Match Results.' icon={<CompareRounded/>}/>
      {!isError &&
        (!isLoading && dataLoads?.length === 0) ?
          <EmptyState headerText='Were ready to find matches.' bodyText={emptyStateText()} icon={<EmptyStateSvg/>}/>
          :
          <>
            <Typography className='hide' variant='h5' sx={{mb: 3}}>Recent Matches</Typography>
            <Typography variant='h5' sx={{mb: 2}}>Match Results from Submitted Lists</Typography>
            <Typography variant='body1' sx={{mb: 4}}>
              Submissions before {cutoffTime} are processed in matching that day M-F. Results returned are based on your configurations. For daily or one time deliveries, match results appear the following day {deliveryTime}. To request an alternate delivery time, please contact your Evadata representative.
            </Typography>
            <Alert className={isDownloadError ? 'show' : 'hide'} sx={{mb: 2, mr: 3}} onClick={clearDownloadError} severity='error'>An error was encountered when retrieving the file, try downloading it again.</Alert>
            <DataTable
              rows={dataLoads}
              columns={getColumns(handleDownloadError)}
              loading={isLoading || !carrierId}
              apiError={isError}
              CollapsibleContent={CollapsibleContent}
              getIsRowCollapsible={() => true}
              paginated
            />
            <Grid container sx={{mt: 3}}>
              <Grid item xs={12} sm={9}>
                <Typography variant="subtitle2" color="text.primary" sx={{ mb: 3 }}>Match Results Notification
                  <Typography variant="body2" color="text.primary" sx={{ mb: 3 }}>Once the file status is successful, locate the match results from your organization’s transfer location. </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} sx={{textAlign: {sm: 'right', xs: 'left'}}}>
                <Button LinkComponent={Link} to="/transfer-locations"  variant="text">View Transfer Locations</Button>
              </Grid>
            </Grid>
          </>
      }
    </>
  )
}

export default MatchResultsDataLoads;
