import {useEffect, useState, useRef} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import {toRelativeUrl} from '@okta/okta-auth-js';
import {Outlet} from 'react-router-dom';

import Layout from 'src/components/Layout';
import LoadingIcon from 'src/components/InitialLoader/loadingIcon';
import LoadError from 'src/components/LoadError';


// See https://github.com/okta/okta-react/blob/master/samples/routing/react-router-dom-v6/src/components/SecureRoute.tsx
// Uses react-router-dom 6.x
export const RequiredAuth = () => {
  const {oktaAuth, authState} = useOktaAuth();
  const pendingLogin = useRef(false);
  const [loading, setLoading] = useState(authState?.isAuthenticated === false);
  const [handleLoginError, setHandleLoginError] = useState(null);

  useEffect(() => {
    const handleLogin = async function() {
      if (pendingLogin.current) {
        return;
      }

      pendingLogin.current = true;

      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      await oktaAuth.signInWithRedirect();
    };

    if (!authState) {
      return;
    }

    if (authState.isAuthenticated) {
      pendingLogin.current = false;
      setLoading(false);
      return;
    }

    // Start login if app has decided it is not logged in and there is no pending signin
    if (!authState.isAuthenticated) {
      setLoading(true);
      handleLogin().catch((err) => {
        setHandleLoginError(err);
      });
    }
  }, [authState, oktaAuth]);

  if (handleLoginError) {
    return <LoadError/>;
  }

  if (authState === null) {
    return;
  }

  return (
    <>
      {loading ? <LoadingIcon/> : <Layout><Outlet/></Layout>}
    </>
  );
};

