import {Typography, Box, Tooltip} from '@mui/material';
import {InfoRounded} from '@mui/icons-material';
import PropTypes from 'prop-types';

import DisplayQuarterlyDates from 'src/components/DisplayQuarterlyDates';
import {months} from 'src/utilities/months';
import {switchFreqDisplay} from 'src/utilities/switchFrequencyDisplay';
import NotificationEmails from '../notificationEmails';


function MatchResultsDetails({auditDate, auditUser, frequency, frequencyDay, reportDates, holidayCalendarId, annualDmf, notificationEmails}) {
  return (
    <>
      <Typography variant='subtitle1' mb={1}>
        Match Results Details
      </Typography>
      <Typography variant='caption'>
        Added {auditDate} by {auditUser}
      </Typography>
      <Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', mt: 0.5, mb: 0}}>
        <Typography variant='subtitle1'>
          Transfer Schedule: <Typography sx={{textTransform: 'capitalize'}} display='inline'>{switchFreqDisplay(frequency)} {frequencyDay}</Typography>
        </Typography>
        <Tooltip placement='right' arrow sx={{ml: 1}} title='Transfer schedule is synonymous with scanning frequency (daily, weekly, monthly, quarterly, or once). This sets the frequency of scanning/match results delivery to your organization.'>
          <InfoRounded color='primary'/>
        </Tooltip>
      </Box>
      {frequency === 'quarterly' &&
        <DisplayQuarterlyDates reportDates={reportDates}/>
      }
      {frequency === 'daily' &&
        <Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', mt: 0.5, mb: 0}}>
          <Typography variant='subtitle1'>
            Observe Company Holidays: <Typography display='inline'>{holidayCalendarId ? 'Yes' : 'No'}</Typography>
          </Typography>
          {holidayCalendarId &&
            <Tooltip placement='right' arrow sx={{ml: 1}} title='Match jobs scheduled to deliver to your organization Daily will skip holidays specified in your company’s holiday calendar, and will resume the following day (Mon.-Fri.).'>
              <InfoRounded color='primary'/>
            </Tooltip>
          }
        </Box>
      }
      {annualDmf &&
        <Typography variant='subtitle1' mt={0.5} mb={0}>
          Annual DMF Delivery Date: <Typography display='inline'>{months[Number(annualDmf?.split('/')[0]) - 1]} {Number(annualDmf?.split('/')[1])}</Typography>
        </Typography>
      }
      <NotificationEmails emails={notificationEmails}/>
    </>
  );
}

MatchResultsDetails.propTypes = {
  auditDate: PropTypes.string,
  auditUser: PropTypes.string,
  frequency: PropTypes.string,
  frequencyDay: PropTypes.string,
  reportDates: PropTypes.array,
  holidayCalendarId: PropTypes.string,
  annualDmf: PropTypes.string,
  notificationEmails: PropTypes.array
};

export default MatchResultsDetails;
