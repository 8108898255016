import {useRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useFormik, FormikProvider} from 'formik';
import * as yup from 'yup';
import {Button, Grid, FormControlLabel, Radio, RadioGroup, Typography, Box, Alert, Paper, TextField} from '@mui/material';

import {useAppContext} from 'src/AppContext.js';
import {setupDeathFile} from 'src/api';
import NotificationPreferences from 'src/components/FormFields/notificationPreferences';
import {getCurrentDate} from 'src/utilities/getDate';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import {useCurrentUserEmail} from 'src/utilities/getCurrentUser';
import PgpRotationField from 'src/components/FormFields/pgpRotation';
import ErrorAlert from 'src/components/ErrorAlert';
import PrefixField from 'src/components/FormFields/filePrefix';
import FileTypeField from 'src/components/FormFields/fileType';
import FileNameExample from 'src/components/FormFields/fileNameExample';
import TransferFreqDisplayOnly from 'src/components/FormFields/transferFreqDisplayOnly';
import TransferLocationMethodDisplayOnly from 'src/components/FormFields/transferLocationMethodDisplayOnly';
import SftpServerDisplayOnly from 'src/components/FormFields/sftpServerDisplayOnly';
import SftpCredentials from 'src/components/FormFields/sftpCredentials';
import AwsFields from 'src/components/AwsFields';
import {prefixRestrictionText, prefixRestrictionRegex} from 'src/utilities/filePrefixRestriction';
import LoadError from 'src/components/LoadError';


yup.addMethod(yup.array, 'uniqueVFDemail', function (payload, message, mapper = a => a) {
  return this.test('uniqueVFDemail', message, function (list) {
    const masterEmailList = list.concat(payload)
    return masterEmailList.length === new Set(masterEmailList.map(mapper)).size;
  });
});

const validationSchema = yup.object().shape({
  newVfdPrefix: yup
    .string()
    .max(24, 'A maximum of 24 characters are allowed')
    .matches(prefixRestrictionRegex, prefixRestrictionText)
    .required('Field is required')
    .when(['prefixError', 'exactPrefixError'], (prefixError, exactPrefixError) =>{
      if(prefixError || exactPrefixError){
        return yup
          .string()
          .matches(prefixRestrictionRegex, prefixRestrictionText)
          .max(0,'')
          .required('File prefix is required')
      }
    }),
  newVfdFileType: yup
    .string()
    .required(''),
  encryptNewDeathFile: yup
    .string()
    .required('Field is required'),
  newVfdPgpKeyRotation: yup
    .string()
    .when('newVfdTransMethod', (newVfdTransMethod) => {
      if (newVfdTransMethod === 'sftp') {
        return yup.string().required()
      } else {
        return yup.string()
      }
    }
    ),
    newVfdTransMethod: yup
    .string()
    .required(),
  newVfdUseExistingUser: yup
    .boolean()
    .required('Field is required'),
  newVfdUsernameSFTP: yup
    .string()
    .max(100, 'A maximum of 100 characters are allowed')
    .matches(/^[^.@-].*$/, "Username can’t start with hyphen –, @ symbol or period")
    .min(3, 'A minimum of 3 characters are allowed')
    .when(["newVfdTransMethod"], (newVfdTransMethod) => {
      if (newVfdTransMethod === 'sftp') {
        return yup.string().required('Field is required')
      } else {
        return yup.string()
      }
    }),
  newVfdSshRsaKey: yup
    .string()
    .when(["newVfdTransMethod", "newVfdUseExistingUser"], (newVfdTransMethod, newVfdUseExistingUser) => {
      if (newVfdTransMethod === 'sftp' && !newVfdUseExistingUser) {
        return yup.string().required('Field is required')
      } else {
        return yup.string()
      }
    }),
  newVfdExistingEmails: yup
    .array()
    .when("newVfdNewEmails", (newVfdNewEmails) => {
      if (newVfdNewEmails.length < 1) {
        return yup.array().min(1, "Must enter email address")
      }
      if (!newVfdNewEmails[0]) {
        return yup.array().min(1, "Must enter email address")
      }
      return yup.array()
    }),
  newVfdNewEmails: yup
    .array()
    .when("newVfdExistingEmails", (newVfdExistingEmails) => {
      if (newVfdExistingEmails) {
        return yup.array().uniqueVFDemail(newVfdExistingEmails, '').of(yup.string().email())
      }
      else {
        return yup.array().of(yup.string().email())
      }
    }),
    newVfdFileArnS3Bucket: yup
    .string()
    .when('newVfdTransMethod', (newVfdTransMethod) => {
      if (newVfdTransMethod === 'awsS3') {
        return yup.string().required('This field is required.')
          .matches(/^arn:aws:s3:::/, "Incorrect format entered. ARN should begin with: arn:aws:s3:::")
          .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
      } else {
        return yup.string()
      }
    }
    ),
    newVfdFileIamRoleArn: yup
    .string()
    .when(['newVfdTransMethod','accountId'], (newVfdTransMethod, accountId) => {
      if (newVfdTransMethod === 'awsS3') {
        return yup.string().required('This field is required.')
        .matches(/^arn:aws:iam::/, "Incorrect format entered. ARN should begin with: arn:aws:iam::")
        .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
        .containsId(accountId, 'ARN entered is incorrect. ARN should include account ID: arn:aws:iam::<AWSAccountID>')
      } else {
        return yup.string()
      }
    }
    ),
    newVfdFileKmsKeyArn: yup
    .string()
    .when('accountId', (accountId) => {
      return yup
        .string()
        .matches(/arn:aws:kms:/, "Incorrect format entered. ARN should begin with: arn:aws:kms:")
        .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
        .containsId(accountId, 'ARN entered is incorrect. ARN should include account ID: arn:aws:kms:<AWSRegion>:<AWSAccountID>')
    }
    ),
}, ['newVfdNewEmails', 'newVfdExistingEmails']);

function AddDeathConfiguration({customerConfig, availableEmailAddresses, setShowAddDeathConfig, reload, cancel, usernames, setJustCompleted, deathPrefixes}) {
  const { carrierConfig } = useAppContext();
  const {userEmail, isError} = useCurrentUserEmail();

  const setupVerifiedDeathFileApi = async function (values, emails) {
    const payload = {
      file: {
        prefix: values.newVfdPrefix,
        type: values.newVfdFileType
      },
      transfer: {
        type: values.newVfdTransMethod,
        frequency: 'daily'
      },
      notificationEmails: emails
    };
    if (customerConfig?.transfer?.type === 'sftp') {
      payload.transfer.sftp = {
        username: values.newVfdUsernameSFTP,
        sshKey: values.newVfdSshRsaKey,
      }
      if (values.newVfdUseExistingUser === true || values.newVfdUseExistingUser === 'true') {
        payload.transfer.sftp.username = customerConfig?.transfer?.sftp?.username;
        payload.transfer.sftp.sshKey = undefined;
      }
      if (values.encryptNewDeathFile === true || values.encryptNewDeathFile === 'true') { // even though yup def is boolean, this can be a string depending on the validation flow
        payload.transfer.sftp.pgpKeyRotationInYears = values.newVfdPgpKeyRotation;
      } else if (values.encryptNewDeathFile === false || values.encryptNewDeathFile === 'false') {
        // do nothing
      } else {
        payload.pgpId = values.encryptNewDeathFile
      }
    }
    if (customerConfig?.transfer?.type === 'awsS3') {
      payload.transfer.awsS3 = {
        s3Bucket: values.newVfdFileArnS3Bucket,
        iamRole: values.newVfdFileIamRoleArn
      }
      if (values.newVfdFileKmsKeyArn) {
        payload.transfer.awsS3.kmsArn = values.newVfdFileKmsKeyArn
      }
    }
    return setupDeathFile(carrierConfig.carrierId, payload);
  };

  const date = getCurrentDate();

  const inputRef = useRef();
  const sshRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [usernameInUseError, setUsernameInUseError] = useState(false);
  const [sshKeyError, setSshKeyError] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const [badUsers, setBadUsers] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const formik = useFormik({
    initialValues: {
      newVfdPrefix: '',
      newVfdFileType: 'json',
      newVfdPgpKeyRotation: '2',
      newVfdTransMethod: customerConfig?.transfer?.type,
      encryptNewDeathFile: true,
      newVfdUseExistingUser: false,
      newVfdUsernameSFTP: '',
      newVfdSshRsaKey: '',
      accountId: customerConfig?.transfer?.awsS3?.carrierIntegrationAccountId,
      newVfdExistingEmails: [],
      newVfdNewEmails: [],
      newVfdFileArnS3Bucket: '',
      newVfdFileKmsKeyArn: '',
      newVfdFileIamRoleArn: '',
      prefixError: false,
      exactPrefixError: false
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (values.prefixError || values.exactPrefixError) {
          window.scrollTo(0, 0);
          setIsLoading(false);
        }
        else {
          setIsLoading(true);
          let _allEmails
          if (values.newVfdExistingEmails.length > 0 && values.newVfdNewEmails.length > 0) {
            _allEmails = values.newVfdExistingEmails.concat(values.newVfdNewEmails.filter(x => x));
          } else if (values.newVfdExistingEmails.length > 0) {
            _allEmails = values.newVfdExistingEmails;
          } else {
            _allEmails = values.newVfdNewEmails;
          }
          for (let i = 0; i < _allEmails.length; i++) {
            if (_allEmails[i] === '') {
              _allEmails.splice(i, 1);
            }
          }
          const result = await setupVerifiedDeathFileApi(values, _allEmails);
          if (result.statusCode === 400) {
            setIsLoading(false);
            if (result.message === 'User already exists') {
              setUsernameInUseError(true);
              setBadUsers([...badUsers, formik.values.newVfdUsernameSFTP]);
              inputRef.current.scrollIntoView();
            } else if (result.message === 'Unsupported SSH key format') {
              setSshKeyError(true);
              sshRef.current.scrollIntoView();
            } else {
              window.scrollTo(0, 0);
              setGeneralError(true);
            }
          } else if (result.statusCode === 500) {
            setIsLoading(false);
            window.scrollTo(0, 0);
            setGeneralError(true);
          } else {
            setJustCompleted(values?.newVfdPrefix);
            await reload();
            setShowAddDeathConfig(false);
          }
        }
      }
      catch (e) {
        setIsLoading(false);
        throw (e);
      }
    },
  });

  const [userEmailInit, setUserEmailInit] = useState(false);
  const [formInitIsSet, setFormInitIsSet] = useState(false);

  useEffect(() => {
    if ((formik.values.newVfdExistingEmails.length === 0) && (userEmailInit === false)) {
      if (userEmail) {
        formik.setFieldValue("newVfdExistingEmails", [userEmail]);
        setUserEmailInit(true);
      }
      if (userEmailInit === true && formInitIsSet === false) {
        setFormInitIsSet(true);
      }
    }
  }, [formik, userEmailInit, userEmail, formInitIsSet]);

  useEffect(() => {
    if (badUsers?.includes(formik.values.newVfdUsernameSFTP)) {
      setUsernameInUseError(true);
    } else {
      setUsernameInUseError(false);
    }
  }, [badUsers, formik.values.newVfdUsernameSFTP]);

  const validateErrors = () => {
    if (formik.errors) {
      setShowErrorAlert(true);
      window.scroll(0, 0);
    }
  }

  useEffect(() => {
    if (deathPrefixes) {
      const _matchedPrefixes = deathPrefixes.filter(checkPrefixes);
      function checkPrefixes(prefix) {
        return formik.values.newVfdPrefix.startsWith(prefix) || prefix.startsWith(formik.values.newVfdPrefix);
      }
      if (_matchedPrefixes.length > 0) {
        if(_matchedPrefixes[0] === formik.values.newVfdPrefix){
          formik.values.exactPrefixError = true;
        }
        else{
          formik.values.prefixError = true;
          formik.values.exactPrefixError = false;
      }
      } else {
        formik.values.prefixError = false;
        formik.values.exactPrefixError = false; 
      }
    }
  }, [deathPrefixes, formik.values])

  return (
    <>
      {isLoading && <LoadingIcon />}
      {<ErrorAlert formik={formik} showErrorAlert={showErrorAlert}/>}
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
        <Paper sx={{ maxWidth: '720px', margin: '30px auto 0' }}>
          <Box sx={{ textAlign: 'center', pt: 3, pb: 4, backgroundColor: 'background.default' }}>
            <Typography variant="h5" color="primary">Verified Deaths List Setup</Typography>
          </Box>
            <Box>
              {isError ? <LoadError sx={{minHeight: '500px'}}/> :
                <Box sx={{ p: 5 }}>
                  <Alert className={generalError ? 'show' : 'hide'} severity="error" sx={{ mb: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
                  
                    <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>FILE DETAILS</Typography>

                    <PrefixField name="newVfdPrefix" label="File Prefix" formik={formik} prefixError={formik.values.prefixError} exactPrefixError={formik.values.exactPrefixError} isManageConfiguration helperText={'Uniquely name files. Names that build off each other (customer, customer1) are not allowed. 24 character limit. Exclude file extension.'}/>

                    <FileTypeField name="newVfdFileType" formik={formik} />

                    <FileNameExample prefix={formik.values.newVfdPrefix} date={date} fileType={formik.values.newVfdFileType} toolTip="Name your file as shown. Including the date (optional) the file is sent to LENS helps when transferring files daily." />

                    <TransferFreqDisplayOnly frequency="Daily (Monday-Friday)" />

                    <hr />

                    <Typography className="trans-location-vdf" variant="subtitle1" color="text.secondary" sx={{ mb: 4, mt: 4 }}>TRANSFER LOCATION & ENCRYPTION</Typography>

                    {customerConfig?.transfer?.type === 'sftp' && <TransferLocationMethodDisplayOnly transferType={customerConfig?.transfer?.type} />}

                    {customerConfig?.transfer?.type === 'sftp' &&
                      <>
                        <SftpServerDisplayOnly sftpServer={customerConfig?.transfer?.sftp?.sftpServer} subdirectory="/death-files" />
                        
                        <Typography ref={inputRef} variant="subtitle1" color="text.primary" sx={{ mb: 2 }}>How would you like to access LENS virtual server instance to transfer your Verified Deaths list?</Typography>

                        <SftpCredentials useExistingUser="newVfdUseExistingUser" newUsername="newVfdUsernameSFTP" newSSHRsaKey="newVfdSshRsaKey" formik={formik} usernameInUseError={usernameInUseError} usernames={usernames} sshRef={sshRef} sshKeyError={sshKeyError} />

                        <Typography variant="subtitle1" color="text.primary" sx={{ mb: 2 }}>Select File Encryption Preference.</Typography>

                        <RadioGroup
                          id="encryptNewDeathFile"
                          name="encryptNewDeathFile"
                          value={formik.values.encryptNewDeathFile}
                          onChange={formik.handleChange}
                          sx={{ mb: 3 }}
                        >
                          <FormControlLabel value={true} control={<Radio />} label="Encrypt list with public PGP key created by LENS (Public key will be available to copy or download after adding new configuration)." />

                          {(formik.values.encryptNewDeathFile === 'true' || formik.values.encryptNewDeathFile === true) &&
                            <PgpRotationField name="newVfdPgpKeyRotation" label="PGP Key Rotation" formik={formik}/>
                          }

                          <FormControlLabel value={false} control={<Radio />} label="Do not encrypt list with PGP key." />
                        </RadioGroup>
                      </>
                    }
                    {customerConfig?.transfer?.type === 'awsS3' &&  <>
                    <AwsFields 
                    showTooltip
                    showLambdaArn
                    instructionText={"Provide S3 Bucket, KMS Key and IAM Role ARNs for LENS to access your Verified Deaths List."}
                    accountId={formik.values.accountId} 
                    evadataLambdaArn={customerConfig?.transfer?.awsS3?.evadataLambdaArn} 
                    evadataLambdaRoleArn={customerConfig?.transfer?.awsS3?.evadataLambdaRoleArn} 
                    cfTemplateFile="carrier-aws-vdf-option.yml" 
                    cfTemplateFileName={"lens-carrier-aws-" + formik.values.newVfdPrefix+ "-files-option.yml"} 
                    cfTemplateLabel="Verified Deaths Template" />
                    <TextField
                    fullWidth
                    id="newVfdFileArnS3Bucket"
                    name="newVfdFileArnS3Bucket"
                    label="S3 Bucket ARN"
                    value={formik.values.newVfdFileArnS3Bucket}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.newVfdFileArnS3Bucket && Boolean(formik.errors.newVfdFileArnS3Bucket)}
                    helperText={(formik.touched.newVfdFileArnS3Bucket && Boolean(formik.errors.newVfdFileArnS3Bucket)) ? formik.errors.newVfdFileArnS3Bucket : 'ARN should begin with: arn:aws:s3:::'}
                    sx={{ mb: '16px' }}
                  />

                  <TextField
                    fullWidth
                    id="newVfdFileKmsKeyArn"
                    name="newVfdFileKmsKeyArn"
                    label="KMS Key ARN (Provide if encrypting file)"
                    value={formik.values.newVfdFileKmsKeyArn}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.newVfdFileKmsKeyArn && Boolean(formik.errors.newVfdFileKmsKeyArn)}
                    helperText={(formik.touched.newVfdFileKmsKeyArn && Boolean(formik.errors.newVfdFileKmsKeyArn)) ? formik.errors.newVfdFileKmsKeyArn : 'ARN should begin with: arn:aws:kms:'}
                    sx={{ mb: '16px' }}
                  />

                  <TextField
                    fullWidth
                    id="newVfdFileIamRoleArn"
                    name="newVfdFileIamRoleArn"
                    label="IAM Role ARN"
                    value={formik.values.newVfdFileIamRoleArn}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.newVfdFileIamRoleArn && Boolean(formik.errors.newVfdFileIamRoleArn)}
                    helperText={(formik.touched.newVfdFileIamRoleArn && Boolean(formik.errors.newVfdFileIamRoleArn)) ? formik.errors.newVfdFileIamRoleArn : 'ARN should begin with: arn:aws:iam::'}
                    sx={{ mb: '16px' }}
                  />
                  <Typography variant="body2" sx={{ mb: 4 }}>LENS uses the provided Role to retrieve your list from the S3 bucket shown. If encrypting list, the KMS Key ARN must be provided for LENS to decrypt.</Typography></>}
                  <hr />
                  <NotificationPreferences formik={formik} availableEmailAddresses={availableEmailAddresses} existingEmails="newVfdExistingEmails" newEmails="newVfdNewEmails" />
                </Box>
              }
              <hr />
              <Box sx={{ pb: 5, pr: 5, pl: 5, backgroundColor: 'background.paper' }}>
                <Grid container>
                  <Grid item sm={6}>
                    <Button onClick={cancel} sx={{ mt: 3 }} variant="outlined">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item sm={6} sx={{ textAlign: 'right' }}>
                    <Button onClick={validateErrors} type="submit" sx={{mt: 3}} variant="contained" disabled={isError}>
                      Add Configuration
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </form>
      </FormikProvider>
    </>
  );
}

AddDeathConfiguration.propTypes = {
  customerConfig: PropTypes.object,
  availableEmailAddresses: PropTypes.array,
  setShowAddDeathConfig: PropTypes.func, 
  reload: PropTypes.func, 
  cancel: PropTypes.func, 
  usernames: PropTypes.array, 
  setJustCompleted: PropTypes.func, 
  deathPrefixes: PropTypes.array
};

export default AddDeathConfiguration;
