import {Link} from 'react-router-dom';
import {Typography, Divider, Grid} from '@mui/material';
import PropTypes from 'prop-types';


function CollapsibleContent({rowData}) {
  if (rowData.fileStatus === 'Skipped') {
    return <Typography variant='body2' ml={1} mb={4} mt={0}>{rowData?.note}. View <Link to={'/holiday-calendar'}>Holiday Calendar</Link></Typography>;
  }

  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Match Records Overview:
      </Typography>
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={3} sx={{ minWidth: '250px' }}>
          <Typography variant="subtitle2" color="text.primary">{rowData?.newMatches} New Matches (Net New)</Typography>
          <Typography variant="caption">Number of customers who matched for the very first time.</Typography>
        </Grid>
        <Grid item xs={1}><Divider sx={{height: '90% !important', mt: '-4px', ml: 4}} orientation="vertical" variant="middle" display="flex" /></Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" color="text.primary">{rowData?.updatedMatches} Updated Matches</Typography>
          <Typography variant="caption">Number of customers who have an additional source match and/or an update to the death record they previously matched against.</Typography>
        </Grid>
        <Grid item xs={1}><Divider sx={{height: '90% !important', mt: '-4px', ml: 4}} orientation="vertical" variant="middle" display="flex" /></Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" color="text.primary">{rowData?.deletedMatches} Deleted Matches</Typography>
          <Typography variant="caption">Number of customers who matched before but the death record provided now has a delete request and we're passing that on for your information.</Typography>
        </Grid>
      </Grid>
    </>
  );
}

CollapsibleContent.propTypes = {
  rowData: PropTypes.object
};

export default CollapsibleContent;
