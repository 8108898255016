import {Skeleton, Card, CardContent, Box, Divider, Button, Typography, Switch, Grid, styled} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import PropTypes from 'prop-types';
import LoadError from 'src/components/LoadError';


const CardWrapper = styled(Box)(
  ({theme}) => `
    .MuiCard-root {
      padding: 0px !important;
      margin-bottom: 27px;
    }
    .child-accordion .MuiCard-root {
      margin-bottom: 0px;
    }
    .pgp-button {
      font-weight:500;
      &.show-hide{
        text-transform: uppercase;
        padding-left: 0px;
      }
      &:hover {
        background-color: transparent;
      }
    }
    .date-line {
      background-color: ${theme.palette.background.default};
      border-top: 1px solid ${theme.palette.text.primary}22;
    }
  `
);

function SkeletonCard({customer, match, apiError}) {
  return (
    <CardWrapper>
      <Card variant={match ? 'outlined' : 'elevation'} sx={{minWidth: 275, textAlign: 'left', boxShadow: match ? 'none' : ''}}>
        <Grid container alignItems='center'>
          <Grid item xs={7} display='inline-flex'>
            {match &&
              <>
                <Switch sx={{ml: 2, mt: 2, mb: 1}} checked disabled/>
                <Divider sx={{height: '33px !important', mt: 2, ml: 2, mr: 0}} orientation="vertical" variant="middle" flexItem/>
              </>
            }
            <Typography variant='subtitle1' sx={{mb: 2, pt: 2, px: 2}} gutterBottom>
              {!apiError && <Skeleton width='220px'/>}
            </Typography>
          </Grid>
          <Grid item xs={5} sx={{textAlign: 'right', pr: 2}}>
            <Button sx={{my: '12px'}} variant='outlined' disabled>Test File</Button>
            <Button sx={{my: '12px'}} variant='contained' disabled>Manage</Button>
          </Grid>
        </Grid>
        <Grid container className='date-line' justifyContent='space-between'>
          <Grid item xs={6} sx={{px: 2, py: 0.5}}>
            <Typography variant='caption' display='inline-flex'>{!apiError && <Skeleton width='90px'/>}</Typography>
          </Grid>
          <Grid item xs={6} sx={{px: 2, py: 0.5, textAlign: 'right'}}>
            <Typography variant='caption' display='inline-flex'>{!apiError && <Skeleton sx={{ml: 1}} width='350px'/>}</Typography>
          </Grid>
        </Grid>
        <CardContent sx={{padding: '8px 16px 0px'}}>
          <Grid container justifyContent='right'>
            <Typography variant='caption'>{!apiError && <Skeleton width='150px'/>}</Typography>
          </Grid>
          {apiError ? <LoadError sx={{minHeight: '150px'}}/> :
            <Grid container sx={{pl: {xs: 0, sm: 0}, pt: 2}}>
              <Grid item xs={12} sm={3} sx={{textAlign: {xs: 'left', sm: 'right'}, pr: 4, mb: {xs: 0, sm: 5}}}>
                <Typography variant='subtitle1' color='text.secondary'>Prefix Name:</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Typography variant='subtitle1'><Skeleton width='220px'/></Typography>
                <Typography variant='body1' sx={{mb: 2}}><Skeleton width='300px'/></Typography>
                <Button className='pgp-button show-hide' variant='text' size='medium' disabled>Show More <ExpandMore/></Button>
              </Grid>
            </Grid>
          }
        </CardContent>
        {customer && !apiError &&
          <Box m={2}>
            <SkeletonCard match/>
          </Box>
        }
      </Card>
    </CardWrapper>
  );
}

SkeletonCard.propTypes = {
  customer: PropTypes.bool,
  match: PropTypes.bool,
  apiError: PropTypes.bool
};

export default SkeletonCard;
