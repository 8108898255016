import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Grid, FormControlLabel, Typography, Box, FormHelperText, Checkbox, FormControl, Alert, MenuItem, InputLabel, Select, Paper, styled, Tooltip} from '@mui/material';
import {InfoRounded} from '@mui/icons-material';
import {Field, useFormik, FormikProvider} from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import {useAppContext} from 'src/AppContext.js';
import {setupMatchFile, getTransferFrequencies} from 'src/api';
import {getCurrentDate} from 'src/utilities/getDate';
import {useCurrentUserEmail} from 'src/utilities/getCurrentUser';
import LoadingIcon from 'src/components/Loading/loadingIcon';
import ErrorAlert from 'src/components/ErrorAlert';
import SelectCategories from 'src/components/SelectCategories';
import PrefixField from 'src/components/FormFields/filePrefix';
import FileTypeField from 'src/components/FormFields/fileType';
import {prefixRestrictionText, prefixRestrictionRegex} from 'src/utilities/filePrefixRestriction';
import {QuarterlyDates, quarterlyValidationSchema, quarterlyInitialValues} from 'src/components/FormFields/quarterlyDates';
import {combineMonthsDays, combineMonthDay} from 'src/utilities/combineMonthDay';
import TransferFrequencySelect from 'src/components/TransferFrequencySelect';
import {AnnualDmfScanSetup, annualDmfSchema, annualDmfInitialValues} from 'src/components/AnnualDmfScanSetup';
import SourceSelection from './sourceSelection';
import TransferLocation from './transferLocation';
import NotificationPreferences from 'src/components/FormFields/notificationPreferences';
import LoadError from 'src/components/LoadError';


const containsPrivate = (string) => /^((?!PRIVATE).)*$/s.test(string);

const FormWrapper = styled(Box)(
  ({ theme }) => `
    .clear-icon {
      cursor: pointer;
    }
    .ssh-link {
      color: ${theme.palette.primary.main}
    }
    .recommended {
      font-size: 14px; 
      color: #666;
    }
    .username {
      width: 90%;
    }
    .MuiTypography-h6 {
      font-weight: 700;
    }
  `
);

function AddMatchFileConfiguration({customerConfigs, matchConfigs, availableEmailAddresses, matchPrefixes, finishSetup, currentCustomerConfig, cancel, holidayCalendarId}) {
  const { carrierConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [generalizedError, setGeneralizedError] = useState(false);
  const date = getCurrentDate();
  const {userEmail, isError} = useCurrentUserEmail();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [availableFrequencies, setAvailableFrequencies] = useState([]);
  const [weakPgpKey, setWeakPgpKey] = useState(false);
  const [quarterlyErrors, setQuarterlyErrors] = useState([false, false, false, false]);

  const validationSchema = yup.object().shape({
    newMatchPrefix: yup
      .string('24 character limit. Exclude file extension.')
      .matches(prefixRestrictionRegex, prefixRestrictionText)
      .max(24, 'A maximum of 24 characters are allowed')
      .required('File prefix is required')
      .when(['prefixError', 'exactPrefixError'], (prefixError, exactPrefixError) => {
        if (prefixError || exactPrefixError) {
          return yup
            .string()
            .matches(prefixRestrictionRegex, prefixRestrictionText)
            .max(0, '')
            .required('File prefix is required')
        }
      }),
    newMatchFileType: yup
      .string()
      .required('This field is required'),
    newMatchFrequency: yup
      .string()
      .required('This field is required'),
    newMatchFrequencyDay: yup
      .string(),
    newMatchFrequencyMonth: yup
      .string(),
    ...quarterlyValidationSchema(true, 'newMatchFrequency', quarterlyErrors),
    assignHolidayCalendar: yup
      .boolean(),
    ...annualDmfSchema,
    sources: yup
      .array()
      .min(1, 'Field is required'),
    newMatchSelectedCategories: yup
      .array()
      .min(1),
    newMatchTransMethod: yup
      .string()
      .required(),
    sftpAddress: yup
      .string()
      .when('newMatchTransMethod', (newMatchTransMethod) => {
        if (newMatchTransMethod === 'sftp') {
          return yup.string()
            .matches(/^(?!https:\/\/).*$/, "Do not include https:// at the beginning of the SFTP address.")
            .matches(/^(?!http:\/\/).*$/, "Do not include http:// at the beginning of the SFTP address.")
            .matches(/^(?!^\d+$).*$/, "Incorrect format entered.") //do not allow hostname that is all numbers
            .matches(/^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))*$/, "Incorrect format entered.")
            .required('This field is required.')
        } else {
          return yup.string()
        }
      }
      ),
    portNumber: yup
      .string()
      .matches(/^\d+$/, "Numeric only")
      .max(5)
      .when('newMatchTransMethod', (newMatchTransMethod) => {
        if (newMatchTransMethod === 'sftp') {
          return yup.string().required('This field is required')
        } else {
          return yup.string()
        }
      }
      ),
    subdirectory: yup
      .string(),
    useSshKey: yup
      .boolean()
      .when('newMatchTransMethod', (newMatchTransMethod) => {
        if (newMatchTransMethod === 'sftp') {
          return yup.boolean().required()
        } else {
          return yup.boolean()
        }
      }
      ),
    usernameSFTP: yup
      .string()
      .max(100, 'A maximum of 100 characters are allowed')
      .matches(/^[^.@-].*$/, "Username can’t start with hyphen –, @ symbol or period")
      .min(3)
      .when('newMatchTransMethod', (newMatchTransMethod) => {
        if (newMatchTransMethod === 'sftp') {
          return yup.string().required('This field is required')
        } else {
          return yup.string()
        }
      }
      ),
    password: yup
      .string()
      .when(['useSshKey', 'newMatchTransMethod'], (useSshKey, newMatchTransMethod) => {
        if (!useSshKey && newMatchTransMethod) {
          return yup.string().required('This field is required')
        } else {
          return yup.string()
        }
      }),
    pgpKey: yup
      .string()
      .max(6000, 'Public key exceeds the character limit (maximum is 6000 characters). Provide only one public key.')
      .matches(/BEGIN PGP PUBLIC/, 'PGP key is invalid. Include the “BEGIN PGP PUBLIC” and “END PGP PUBLIC” text when entering key.')
      .matches(/END PGP PUBLIC/, 'PGP key is invalid. Include the “BEGIN PGP PUBLIC” and “END PGP PUBLIC” text when entering key.')
      .test(
        'PGP key is invalid. Provide only the public key.',
        'PGP key is invalid. Provide only the public key.',
        (value) => containsPrivate(value)
      )
      .when('newMatchTransMethod', (newMatchTransMethod) => {
        if (newMatchTransMethod === 'sftp') {
          return yup.string().required('This field is required')
        } else {
          return yup.string()
        }
      }
      ),
    s3BucketArn: yup
      .string()
      .when('newMatchTransMethod', (newMatchTransMethod) => {
        if (newMatchTransMethod === 'awsS3') {
          return yup.string().required('This field is required')
            .matches(/^arn:aws:s3:::/, "Incorrect format entered. ARN should begin with: arn:aws:s3:::")
            .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
        } else {
          return yup.string()
        }
      }
      ),
    iamRoleArn: yup
      .string()
      .when(['newMatchTransMethod', 'awsAccountId'], (newMatchTransMethod, awsAccountId) => {
        if (newMatchTransMethod === 'awsS3') {
          return yup.string()
            .matches(/^arn:aws:iam::/, "Incorrect format entered. ARN should begin with: arn:aws:iam::")
            .matches(/^\S*$/, "Incorrect format entered. ARN entered should not include spaces.")
            .containsId(awsAccountId, 'ARN entered is incorrect. ARN should include account ID: arn:aws:iam:<AWSAccountID>')
            .required('This field is required')
        } else {
          return yup.string()
        }
      }
      ),
    newMatchExistingEmails: yup
      .array()
      .when("newMatchEmails", (newMatchEmails) => {
        if (newMatchEmails.length < 1) {
          return yup.array().min(1, "");
        }
        if (!newMatchEmails[0]) {
          return yup.array().min(1, "");
        }
        return yup.array();
      }),
    newMatchEmails: yup
      .array()
      .when("newMatchExistingEmails", (newMatchExistingEmails) => {
        if (newMatchExistingEmails) {
          return yup.array().uniqueMF(newMatchExistingEmails, '').of(yup.string().email())
        }
        else {
          return yup.array().of(yup.string().email())
        }
      })
  }, ['newMatchEmails', 'newMatchExistingEmails']);

  const categoriesRef = useRef(null);
  const transType = matchConfigs?.[0]?.transfer?.type;
  const awsAccountID = matchConfigs?.[0]?.transfer?.awsS3?.carrierIntegrationAccountId;
  const KmsKeyArn = matchConfigs?.[0]?.transfer?.awsS3?.evadataMatchLambdaKmsArn;

  const setupMatchFileApi = async function (values, emails) {
    const payload = {
      file: {
        prefix: values.newMatchPrefix,
        type: values.newMatchFileType
      },
      customerFilePrefix: currentCustomerConfig?.file?.prefix,
      transfer: {
        type: values.newMatchTransMethod,
        frequency: values.newMatchFrequency,
      },
      sources: values.sources,
      notificationEmails: emails,
      categories: values.newMatchSelectedCategories
    };
    if (values.newMatchTransMethod === 'sftp') {
      payload.transfer.sftp = {
        username: values.usernameSFTP.trim(),
        host: values.sftpAddress.trim(),
        port: values.portNumber,
        pgpPublicKey: values.pgpKey
      }
    }
    if (values.newMatchTransMethod === 'awsS3') {
      payload.transfer.awsS3 = {
        s3Bucket: values.s3BucketArn,
        iamRole: values.iamRoleArn
      }

    }
    if (values?.subdirectory?.length > 0) {
      if (values.newMatchTransMethod === 'sftp') {
        payload.transfer.sftp.path = values.subdirectory;
      }
      else {
        payload.transfer.awsS3.path = values.subdirectory;
      }
    }

    if (values.newMatchFrequency === 'Daily' && values.assignHolidayCalendar) {
      payload.holidayCalendarId = holidayCalendarId;
    }

    if (values.newMatchFrequency === 'Weekly') {
      payload.transfer.frequencyDay = values.newMatchFrequencyDay;
    }

    if (values.newMatchFrequency === 'Monthly') {
      payload.transfer.frequencyDay = values.newMatchFrequencyMonth;
    }

    if (values.newMatchFrequency === 'Quarterly') {
      const quarterlyMonths = [values?.q1Month, values?.q2Month, values?.q3Month, values?.q4Month];
      const quarterlyDays = [values?.q1Day, values?.q2Day, values?.q3Day, values?.q4Day];
      const newReportDates = combineMonthsDays(quarterlyMonths, quarterlyDays);
      payload.transfer.reportDates = newReportDates;
    }

    if (values.enableAnnualDmf === 'true') {
      const _date = combineMonthDay(values.annualDmfMonth, values.annualDmfDay, true);
      payload.transfer.annualDmf = _date;
    }

    if (values.useSshKey === false || values.useSshKey === 'false') { // even though yup def is boolean, this can be a string depending on the validation flow
      payload.transfer.sftp.password = values.password;
    }

    return setupMatchFile(carrierConfig.carrierId, payload);
  };

  const formik = useFormik({
    initialValues: {
      newMatchPrefix: '',
      newMatchFileType: 'json',
      sources: [],
      pgpKey: '',
      sftpAddress: '',
      portNumber: 22,
      subdirectory: '',
      usernameSFTP: '',
      password: '',
      newMatchFrequency: '',
      newMatchFrequencyDay: 'Wednesday',
      newMatchFrequencyMonth: 'First Day',
      ...annualDmfInitialValues,
      accountId: '',
      newMatchExistingEmails: null,
      newMatchEmails: [],
      useSshKey: true,
      iamRoleArn: '',
      s3BucketArn: '',
      newMatchTransMethod: transType,
      newMatchFileKmsKeyArn: KmsKeyArn,
      awsAccountId: awsAccountID,
      prefixError: false,
      exactPrefixError: false,
      assignHolidayCalendar: false,
      ...quarterlyInitialValues
    },
    validationSchema: validationSchema,
    onSubmit: async (formValues) => {
      setIsLoading(true);
      setGeneralizedError(false);
      setWeakPgpKey(false);
      setShowErrorAlert(false);

      const _allEmails = formValues.newMatchExistingEmails.concat(formValues.newMatchEmails.filter(x => x));
      const _result = await setupMatchFileApi(formValues, _allEmails);

      if (_result.statusCode === 400 || _result.statusCode === 500) {
        setIsLoading(false);
        window.scrollTo(0, 0);

        if (_result.message === "The key is too weak. Please use a RSA, ECDSA or ED25519 key that is at least 2048 bits and try again.") {
          formik.errors.pgpKey = _result.message;
          setShowErrorAlert(true);
          setWeakPgpKey(true);
        }
        else {
          setGeneralizedError(true);
        }
      } else {
        await finishSetup();
      }
    },
  });

  const [emailInit, setEmailInit] = useState(false);
  const [matchFormInitIsSet, setMatchFormInitIsSet] = useState(false);

  useEffect(() => {
    if (!formik?.values?.newMatchExistingEmails && (emailInit === false)) {
      if (userEmail) {
        formik.setFieldValue("newMatchExistingEmails", [userEmail]);
        setEmailInit(true);
      }
      if (emailInit === true && matchFormInitIsSet === false) {
        setMatchFormInitIsSet(true);
      }
    }
  }, [formik, emailInit, userEmail, matchFormInitIsSet]);

  useEffect(() => {
    if (matchPrefixes) {
      const _matchedPrefixes = matchPrefixes.filter(checkPrefixes);
      function checkPrefixes(prefix) {
        return formik.values.newMatchPrefix.startsWith(prefix) || prefix.startsWith(formik.values.newMatchPrefix);
      }
      if (_matchedPrefixes.length > 0) {
        if (_matchedPrefixes[0] === formik.values.newMatchPrefix) {
          formik.values.exactPrefixError = true;
        }
        else {
          formik.values.prefixError = true;
          formik.values.exactPrefixError = false;
        }
      } else {
        formik.values.prefixError = false;
        formik.values.exactPrefixError = false;
      }
    }
  }, [matchPrefixes, formik.values])



  useEffect(() => {
    async function getAvailableFrequencies(carrierId) {
      const { scanFrequencies } = await getTransferFrequencies(carrierId);
      setAvailableFrequencies(scanFrequencies);
    }

    getAvailableFrequencies(carrierConfig?.carrierId);
    
  }, [carrierConfig.carrierId])

  const validate = () => {
    if (formik.errors) {
      setFormTouched(true);
      setShowErrorAlert(true);
      window.scroll(0, 0);
    }
  }

  function updateSelectedCategories(allSelected) {
    formik.setFieldValue("newMatchSelectedCategories", allSelected);
  }

  return (
    <>
      {<ErrorAlert formik={formik} showErrorAlert={showErrorAlert} />}
      {isLoading && <LoadingIcon />}
      <FormWrapper>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Paper sx={{ maxWidth: '720px', margin: '30px auto 0' }}>
              <Box sx={{ textAlign: 'center', pt: 3, pb: 4, backgroundColor: 'background.default' }}>
                <Alert className={generalizedError ? 'show' : 'hide'} severity="error" sx={{ mb: 2 }}>We're having trouble submitting your request. Please try again, or contact LENS support if the issue persists.</Alert>
                <Typography variant="overline" color="text.primary">Step 2 of 2</Typography>
                <Typography variant="h5" color="primary">Match Results Setup</Typography>
              </Box>
              {isError ? <LoadError sx={{minHeight: '500px'}}/> :
                <Box sx={{ p: 5, backgroundColor: 'background.paper' }}>
                  <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>FILE DETAILS</Typography>
                  <PrefixField name="newMatchPrefix" label="File Prefix" formik={formik} prefixError={formik.values.prefixError} exactPrefixError={formik.values.exactPrefixError} showDateExtension isManageConfiguration helperText={'Uniquely name files. Names that build off each other (customer, customer1) are not allowed. 24 character limit. Exclude file extension.'} />
                  <FileTypeField formik={formik} name='newMatchFileType' formValue2='xlsx' formLabel2='XLSX' formValue3='both' formLabel3='Both JSON & XLSX' />
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 0 }}>File Name Example:</Typography>
                  {formik.values.newMatchFileType === 'both' ?
                    <>
                      <Typography variant="body2" color="text.primary">{formik.values.newMatchPrefix}-{date}.json</Typography>
                      <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>{formik.values.newMatchPrefix}-{date}.xlsx</Typography>
                    </>
                    :
                    <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>{formik.values.newMatchPrefix}-{date}.{formik.values.newMatchFileType}</Typography>
                  }
                  <Typography variant="body2" color="text.primary" sx={{ mb: 3 }}>Prefix and file type are used by LENS to create your matches.</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 1, mt: 3 }}>
                    <Typography variant="subtitle1" color="text.primary" sx={{ mt: 1 }}>Choose transfer frequency schedule.</Typography>
                    <Tooltip placement="right" arrow sx={{ ml: 1 }} title='Transfer schedule is synonymous with scanning frequency (daily, weekly, monthly, quarterly, or once). This sets the frequency of scanning/match results delivery to your organization.'><InfoRounded color="primary" /></Tooltip>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <TransferFrequencySelect formik={formik} availableFrequencies={availableFrequencies} formInputName='newMatchFrequency' />
                    </Grid>
                    {formik.values.newMatchFrequency === 'Daily' ?
                      <Grid item xs={12}>
                        <FormControl>
                          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 2, mt: 0, ml: 1 }}>
                            <Field as={FormControlLabel} type="checkbox" id='assignHolidayCalendar' name='assignHolidayCalendar' label='Observe company holidays' control={<Checkbox />} />
                            <Tooltip placement="right" arrow sx={{ ml: -1 }} title='Match jobs scheduled to deliver to your organization Daily will skip holidays specified in your company’s holiday calendar, and will resume the following day (Mon.-Fri.).'><InfoRounded color="primary" /></Tooltip>
                          </Box>
                        </FormControl>
                        <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>Visit <Link to='/holiday-calendar'>Holiday Calendar</Link> page to manage holidays.</Typography>
                      </Grid> : ''}

                    <Grid item xs={12} sm={4}>
                      {formik.values.newMatchFrequency === 'Weekly' ?
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                          <InputLabel htmlFor="newMatchFrequencyDay">Day of the Week</InputLabel>
                          <Select
                            id="newMatchFrequencyDay"
                            name="newMatchFrequencyDay"
                            value={formik.values.newMatchFrequencyDay}
                            onChange={formik.handleChange}
                            label="File Transfer Frequency"
                            sx={{ width: '100%' }}
                          >
                            <MenuItem value="Monday">Monday</MenuItem>
                            <MenuItem value="Tuesday">Tuesday</MenuItem>
                            <MenuItem selected value="Wednesday">Wednesday</MenuItem>
                            <MenuItem value="Thursday">Thursday</MenuItem>
                            <MenuItem value="Friday">Friday</MenuItem>
                          </Select>
                          <FormHelperText>File sent on this day every week.</FormHelperText>
                        </FormControl> : ''}

                      {formik.values.newMatchFrequency === 'Monthly' ?
                        <FormControl sx={{ mt: 2, width: '100%' }}>
                          <InputLabel htmlFor="newMatchFrequencyMonth">Day of the Month</InputLabel>
                          <Select
                            id="newMatchFrequencyMonth"
                            name="newMatchFrequencyMonth"
                            value={formik.values.newMatchFrequencyMonth}
                            onChange={formik.handleChange}
                            label="File Transfer Frequency"
                            sx={{ width: '100%' }}
                          >
                            <MenuItem selected value="First Day">First Day</MenuItem>
                            <MenuItem value="15th">15th</MenuItem>
                            <MenuItem value="Last Day">Last Day</MenuItem>
                          </Select>
                          <FormHelperText>File sent on this day every month.</FormHelperText>
                        </FormControl> : ''}
                      {formik.values.newMatchFrequency === 'Once' ? <Typography sx={{ mt: { sm: 3, xs: 1 }, mb: 5 }} variant="body2" >File sent once, 1-2 days after recieving your customer file.</Typography> : ''}
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      {formik.values.newMatchFrequency === 'Quarterly' &&
                        <Box mb={3}>
                          <QuarterlyDates formik={formik} quarterlyErrors={quarterlyErrors} setQuarterlyErrors={setQuarterlyErrors} />
                        </Box>
                      }
                    </Grid>
                  </Grid>
                  {availableFrequencies?.includes('dmf-annual') && 
                    <Box mb={4}>
                      <AnnualDmfScanSetup formik={formik} filePrefix={formik.values.newMatchPrefix} fileType={formik.values.newMatchFileType} matchConfigs={matchConfigs} customerConfigs={customerConfigs} />
                    </Box>
                  }

                  <hr/>
                  <SourceSelection carrierId={carrierConfig?.carrierId} formik={formik} field='sources'/>
                  <hr/>
                  <Typography ref={categoriesRef} variant="h6" color="text.secondary" sx={{ mb: 4, mt: 4 }}>MATCH CATEGORIES</Typography>
                  <SelectCategories updateSelectedCategories={updateSelectedCategories} formTouchedProp={formTouched}/>
                  <hr/>
                  <TransferLocation
                    formik={formik}
                    sftpAddressField='sftpAddress'
                    portNumberField='portNumber'
                    subdirectoryField='subdirectory'
                    sftpUsernameField='usernameSFTP'
                    useSshKeyField='useSshKey'
                    passwordField='password'
                    pgpKeyField='pgpKey'
                    s3BucketArnField='s3BucketArn'
                    iamRoleArnField='iamRoleArn'
                    filePrefixField='newMatchPrefix'
                    matchConfig={matchConfigs[0]}
                    currentCustomerConfig={currentCustomerConfig}
                    transferType={transType}
                    weakPgpKey={weakPgpKey}
                  />
                  <hr/>
                  <NotificationPreferences formik={formik} availableEmailAddresses={availableEmailAddresses} existingEmails='newMatchExistingEmails' newEmails='newMatchEmails'/>
                </Box>
              }
              <hr />
              <Box sx={{ pb: 5, pr: 5, pl: 5, backgroundColor: 'background.paper' }}>
                <Grid container>
                  <Grid item sm={6}>
                    <Button onClick={cancel} sx={{ mt: 3 }} variant="outlined">
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item sm={6} sx={{ textAlign: 'right' }}>
                    <Button onClick={validate} type="submit" sx={{ mt: 3 }} variant="contained" disabled={isError}>
                      Add Configuration
                    </Button>
                  </Grid>
                  {formik.values.errors}
                </Grid>
              </Box>
            </Paper>
          </form>
        </FormikProvider>
      </FormWrapper>
    </>
  )
}

AddMatchFileConfiguration.propTypes = {
  matchConfigs: PropTypes.array,
  customerConfigs: PropTypes.array,
  availableEmailAddresses: PropTypes.array,
  matchPrefixes: PropTypes.array,
  finishSetup: PropTypes.func,
  currentCustomerConfig: PropTypes.object,
  cancel: PropTypes.func,
  holidayCalendarId: PropTypes.string
};

export default AddMatchFileConfiguration;
