import {useState} from 'react';
import {Grid, Typography, Button} from '@mui/material';
import {ExpandMore, ExpandLess} from '@mui/icons-material';
import PropTypes from 'prop-types';


function NotificationEmails({emails}) {
  const [showEmails, setShowEmails] = useState(emails?.length === 1);

  return (
    <Grid container sx={{pt: 2}}>
      <Grid item xs={12} sm={3} sx={{textAlign: {xs: 'left', sm: 'right'}, pr: {xs: 0, sm: 4}}}>
        <Typography variant='subtitle1' color='text.secondary'>Notifications:</Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Typography variant='body2' sx={{mt: '5px'}}>Email(s) receiving notifications related to this list.</Typography>
        {emails?.length > 1 &&
          <Button className='pgp-button show-hide' variant='text' size='medium' onClick={() => setShowEmails(!showEmails)}>
            {showEmails ? <>Hide Email Addresses <ExpandLess /></> : <>Show Email Addresses <ExpandMore /></>}
          </Button>
        }
        {showEmails && emails?.map((email) => <Typography variant='subtitle1' key={email}>{email}</Typography>)}
      </Grid>
    </Grid>
  );
}

NotificationEmails.propTypes = {
  emails: PropTypes.array
};

export default NotificationEmails;
