import {Grid, Typography, Chip, styled, Button, Box} from '@mui/material';
import {ArrowForward, FileDownloadRounded} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import CopyButton from 'src/components/CopyButton';
import TooltipInline from 'src/components/TooltipInline';
import AnnualDmfDisplay from 'src/components/AnnualDmfDisplay';
import DisplayQuarterlyDates from 'src/components/DisplayQuarterlyDates';
import {sourceDisplayMap} from 'src/utilities/mapSourceDisplay';
import {downloadTxtFile} from 'src/utilities/downloadTextFile';
import NotificationEmails from '../notificationEmails';
import AwsDetails from '../awsDetails';


const LinkWrapper = styled(Typography)(
  ({ theme }) => `
    a:-webkit-any-link {
      color: ${theme.colors.primary.main}
    }
  `
);

function MatchConfigDetails({config, matchConfigs, customerConfigs, frequencies}) {
  function downloadSshKey() {
    downloadTxtFile(config?.transfer?.sftp?.ssh?.publicKey, 'ssh-' + config?.file?.prefix + '.txt')
  }

  return (
    <>
      <Grid container sx={{ pt: 0 }}>
        <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: 4 }}>
          <Typography variant="subtitle1" color='text.secondary'>Sources:</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography variant="subtitle1" color="text.primary" sx={{ mt: '2px', mb: 1 }}>{config?.sources?.length === 0 ? 'There are currently no sources assigned. Manage file to add sources.' : config?.sources?.map((source) => sourceDisplayMap[source]).join(', ')}</Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>This workflow will match your customer records against these sources.</Typography>
        </Grid>
        <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: 4 }}>
          <Typography variant="subtitle1" color='text.secondary'>Match Categories:</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container>
            <Typography variant="subtitle1" color="text.primary" sx={{ mt: '2px', mb: 1 }}>
              These categories will appear in your match results:
            </Typography>
            <LinkWrapper variant="body2" mt={0.7} ml={3}><Link target="_blank" to="/evadatas-match-categories">View Match Category Definitions</Link></LinkWrapper>
          </Grid>
          <Typography variant="body2" sx={{ mb: 2 }}>
            {config?.categories?.map((cat) => <Chip key={cat} sx={{mr: 1, pl: '3px', pr: '3px', mb: '3px'}} size="small" label={cat}/>)} 
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: { xs: 0, sm: 4 } }}>
          <Typography variant="subtitle1" color='text.secondary'>Transfer Location:</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>{config?.transfer?.type === 'sftp' ? 'SFTP' : 'AWS S3'}<Button component={Link} variant="text" sx={{ float: 'right' }} to="/transfer-locations" endIcon={<ArrowForward />}>VIEW ALL LOCATIONS</Button></Typography>
          {config?.transfer?.type === 'sftp' &&
            <>
              <Typography color="text.secondary" variant="overline">PROVIDED BY MY ORGANIZATION</Typography>
              <Typography sx={{mb: 0}}><strong>SFTP Server Hostname:</strong>
                {config?.transfer?.sftp?.host}
              </Typography>
              <Typography sx={{mb: 1, mt: 1}}><strong>Port:</strong> {config?.transfer?.sftp?.port}</Typography>
              {config?.transfer?.sftp?.path && config?.transfer?.sftp?.path !== '/' &&
                <Typography sx={{mb: 1, mt: 1}}><strong>Subdirectory Path:</strong> {config?.transfer?.sftp?.path}</Typography>
              }
              <Typography sx={{mb: 1}}><strong>Username:</strong> {config?.transfer?.sftp?.username}</Typography>
              {!config?.transfer?.sftp?.ssh?.publicKey ?
                <Typography sx={{mb: 1}}><strong>Password: </strong>For security reasons, the password is hidden.</Typography>
                :
                <>
                  <Typography color="text.secondary" variant="overline">PROVIDED BY LENS</Typography>
                  <Typography sx={{mb: 1, mt: -1}}><strong>SSH-RSA key: </strong>
                    <CopyButton className="ssh-copy-button" display="inline" paddingLeft={2} buttonText="Copy Public SSH-RSA Key" toolTipText="Copy Public SSH-RSA Key" textToCopy={config?.transfer?.sftp?.ssh?.publicKey}></CopyButton>
                    <Button className="pgp-button" onClick={downloadSshKey} sx={{ marginTop: '-1px', marginRight: '-10px' }}>Download SSH-RSA Key<FileDownloadRounded sx={{ ml: 1 }} /></Button>
                  </Typography>
                </>
              }
            </>
          }
          {config?.transfer?.type === 'awsS3' &&
            <AwsDetails aws={config?.transfer?.awsS3}/>
          }
          <Typography sx={{mb: 1}}><strong>Transfer Schedule: </strong>
            {config?.transfer?.frequency === 'daily' ?
              <Box sx={{ mb: 4, textTransform: 'capitalize', display: 'inline' }}>Daily (Monday-Friday)</Box>
              :
              <Box sx={{ mb: 4, textTransform: 'capitalize', display: 'inline' }}>{config?.transfer?.frequency}{config?.transfer?.frequencyDay && ` (${config?.transfer?.frequencyDay})`}</Box>
            }
            <TooltipInline placement="right" display='inline-flex' mt={-1.5} tip='Transfer schedule is synonymous with scanning frequency (daily, weekly, monthly, quarterly or once). This sets the frequency of scanning/match results delivery to your organization.'/>
            <br/>
            {config?.transfer?.reportDates &&
              <DisplayQuarterlyDates reportDates={config?.transfer?.reportDates} />
            }
          </Typography>
          {config?.transfer?.type === 'sftp' &&
            <Typography variant="body2">At this frequency, LENS uses the credentials above to place matches in your SFTP.</Typography>
          }
          {config?.transfer?.type === 'awsS3' &&
            <Typography variant="body2">At the transfer frequency, LENS will place your matches in the S3 Bucket.</Typography>
          }
          {config?.transfer?.frequency === 'daily' &&
            <>
              <Typography sx={{mb: 1, mt: 1}}><strong>Observe Company Holidays: </strong>
                <Box sx={{ mb: 4, textTransform: 'capitalize', display: 'inline' }}>
                  {config?.holidayCalendarId ? 'Yes' : 'No'}{config?.holidayCalendarId && <TooltipInline placement="right" display='inline-flex' mt={-1.5} tip='Match jobs scheduled to deliver to your organization Daily will skip holidays specified in your company’s holiday calendar, and will resume the following day (Mon.-Fri.).'></TooltipInline>}
                </Box>
              </Typography>
              <Typography variant="body2" color="text.primary" sx={{ mb: 1.5 }}>Visit <Link to='/holiday-calendar'>Holiday Calendar</Link> page to manage holidays.</Typography>
            </>
          }
          <AnnualDmfDisplay spacing={0.5} variant='list' frequencies={frequencies} matchConfig={config} matchConfigs={matchConfigs} customerConfigs={customerConfigs}/>
        </Grid>
      </Grid>
      <Grid container sx={{ pt: 2 }}>
        <Grid item xs={12} sm={3} sx={{ textAlign: { xs: 'left', sm: 'right' }, pr: { xs: 0, sm: 4 } }}>
          <Typography variant="subtitle1" color='text.secondary'>Encryption Key:</Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>{config?.transfer?.type === 'sftp' ? 'PGP' : 'AWS KMS'}<Button component={Link} variant="text" sx={{ float: 'right' }} to="/encryption-keys" endIcon={<ArrowForward />}>VIEW ALL KEYS</Button></Typography>
          {config?.transfer?.type === 'sftp' &&
            <>
              <Typography color="text.secondary" variant="overline">PROVIDED BY MY ORGANIZATION</Typography>
              <Typography sx={{mb: 1}}><strong>PGP Key ID: </strong>{config?.transfer?.sftp?.pgp?.keyId}</Typography>
              <Typography sx={{mb: 0}}><strong>Key Expiration:</strong> {config?.sftp?.pgp?.keyExpiration}</Typography>
            </>
          }
          {config?.transfer?.type === 'awsS3' &&
            <>
              <Typography color="text.secondary" variant="overline">PROVIDED BY LENS</Typography>
              <Typography sx={{mb: 1}}><strong>KMS Key ARN: </strong><CopyButton display="inline" toolTipText="Copy KMS Arn" textToCopy={config?.transfer?.awsS3?.evadataMatchLambdaKmsArn} /> {config?.transfer?.awsS3?.evadataMatchLambdaKmsArn}</Typography>
              <Typography variant="body2">Use this KMS Key ARN to decrypt your matches.</Typography>
            </>
          }
        </Grid>
        {config?.transfer?.type === 'sftp' &&
          <Grid item xs={8} sx={{ pl: { xs: 0, sm: '25.4%' }, pt: 1, display: { xs: 'none', sm: 'block' } }}>
            <Typography variant="body2" sx={{ marginLeft: { sm: '-5px', xs: 0 } }}>
              LENS encrypts matches with the public PGP key provided. Use private key to decrypt matches.
            </Typography>
          </Grid>
        }
        <NotificationEmails emails={config?.notificationEmails}/>
      </Grid>
    </>
  );
}

MatchConfigDetails.propTypes = {
  config: PropTypes.object,
  matchConfigs: PropTypes.array,
  customerConfigs: PropTypes.array,
  frequencies: PropTypes.object
};

export default MatchConfigDetails;
