import {Typography} from '@mui/material';

import ErrorFileDownload from 'src/components/ErrorFileDownload';
import FileStatusChip from 'src/components/FileStatusChip';


function getColumns(handleDownloadError) {
  return [
    { 
      field: 'fileName', 
      headerName: 'File Name', 
      renderCell: (params) => {
        return (
          <>
            {params?.row?.nickname && <Typography variant='body2'>{params.row.nickname}</Typography>}
            <Typography variant={params?.row?.nickname ? 'caption' : 'body2'}>{params.value}</Typography>
          </>
        );
      }
    },
    {
      field: 'id',
      headerName: 'Dataload ID',
      renderCell: (params) => {
        return <Typography variant='body2'>{params.value}</Typography>;
      },
    },
    {
      field: 'submitted',
      headerName: 'Submitted',
      renderCell: (params) => {
        return <Typography variant='body2'>{params.value}</Typography>;
      },
    },
    { 
      field: 'completed', 
      headerName: 'Completed',
      renderCell: (params) => {
        return <Typography variant='body2'>{params.value}</Typography>;
      } 
    },
    {
      field: 'totalRecords', 
      headerName: 'Total Records', 
      sortingOrder: ['desc', 'asc'],
      flex: 0.8,
      renderCell: (params) => {
        return <Typography variant='body2'>{params.value}</Typography>;
      }
    },
    {
      field: 'fileStatus',
      headerName: 'File Status',
      renderCell: (params) => {
        return <FileStatusChip fileStatus={params?.value}/>;
      },
    },
    { 
      field: 'numOfErrors', 
      headerName: '# of Errors',
      renderCell: (params) => {
        return <Typography variant='body2'>{params?.value}</Typography>;
      }
    },
    {
      field: 'downloadFile',
      headerName: 'Download',
      verticalAlign: 'middle',
      textAlign: 'center',
      renderCell: (params) => {
        const {row} = params;
        const displayDownload = parseInt(row.numOfErrors) > 0 || row.fileStatus === 'Failed';
  
        return displayDownload && <ErrorFileDownload handleDownloadError={handleDownloadError} fileType={row.fileType} dataLoadId={row.id}/>;
      },
    }
  ];
}

export default getColumns;
