import {useState, useMemo, createContext} from 'react';
import PropTypes from 'prop-types';


export const AppContext = createContext(null);

export const AppProvider = ({children}) => {
  const [carrierConfig, setCarrierConfig] = useState(null);
  const [lensRoles, setLensRoles] = useState(null);
  const [oktaUser, setOktaUser] = useState(null);
  const [customerFileConfigs, setCustomerFileConfigs] = useState([]);
  const [deathFileConfigs, setDeathFileConfigs] = useState([]);
  const [matchesFileConfigs, setMatchesFileConfigs] = useState([]);

  const provider = useMemo(() => ({
    carrierConfig, 
    setCarrierConfig,
    lensRoles,
    setLensRoles,
    customerFileConfigs,
    setCustomerFileConfigs,
    deathFileConfigs,
    setDeathFileConfigs,
    matchesFileConfigs,
    setMatchesFileConfigs,
    oktaUser,
    setOktaUser
  }), [carrierConfig, customerFileConfigs, deathFileConfigs, matchesFileConfigs, lensRoles, oktaUser]);
  
  return (
    <AppContext.Provider value={provider}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.object
};
