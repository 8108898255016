import {Typography} from '@mui/material';

import FileStatusChip from 'src/components/FileStatusChip';
import ErrorFileDownload from 'src/components/ErrorFileDownload';


function getColumns(handleDownloadError) {
  return [
    {
      field: 'fileName', 
      headerName: 'File Name', 
      renderCell: (params) => {
        return (
          <>
            {params?.row?.nickname && <Typography variant='body2'>{params.row.nickname}</Typography>}
            <Typography variant={params?.row?.nickname ? 'caption' : 'body2'}>{params.value}</Typography>
          </>
        )
      },
    },
    {
      field: 'id',
      headerName: 'Dataload ID',
      renderCell: (params) => {
        return <Typography variant='body2'>{params.value}</Typography>;
      },
    },
    {
      field: 'completed',
      headerName: 'Results Sent',
      renderCell: (params) => {
        return <Typography variant='body2'>{params.row.fileStatus === 'Skipped' ? '-' : params.value}</Typography>;
      },
    },
    {
      field: 'totalMatches', 
      headerName: 'Match Records', 
      renderCell: (params) => {
        return <Typography variant='body2'>{params.value === undefined ? '-' : params.value}</Typography>;
      }
    },
    {
      field: 'fileStatus',
      headerName: 'File Status',
      renderCell: (params) => {
        return <FileStatusChip fileStatus={params.value}/>;
      }
    },
    {
      field: 'downloadFile',
      headerName: 'Download',
      verticalAlign: 'middle',
      textAlign: 'center',
      renderCell: (params) => {
        const displayDownload = parseInt(params?.row?.numOfErrors) > 0 || params?.row?.fileStatus === 'Failed';

        return displayDownload && <ErrorFileDownload handleDownloadError={handleDownloadError} fileType='match-files' dataLoadId={params?.row?.id}/>;
      }
    }
  ];
}

export default getColumns;
