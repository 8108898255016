import {VerifiedRounded} from '@mui/icons-material';

import {useQuery} from 'src/hooks';
import {getAllDataLoadsHistory} from 'src/api';
import {useAppContext} from 'src/AppContext';
import PageHeader from 'src/components/PageHeader';
import DataLoadsBodyContent from 'src/components/DataLoadsBodyContent';


function VerifiedDeathsDataLoads() {
  const {carrierConfig} = useAppContext();
  const carrierId = carrierConfig?.carrierId;

  const {data, isLoading, isError} = useQuery('verifiedDeathsDataLoads', () => getAllDataLoadsHistory(carrierId, 'death-files'), {enabled: Boolean(carrierId)});
  
  return (
    <>
      <PageHeader title='Verified Deaths' subtitle='All recent and historical Verified Deaths data loads.' icon={<VerifiedRounded/>}/>
      <DataLoadsBodyContent data={data} isLoading={isLoading || !carrierId} isError={isError}/>
    </>
  );
}

export default VerifiedDeathsDataLoads;
