import {Typography, Box} from '@mui/material';
import PropTypes from 'prop-types';

import {switchFreqDisplay} from 'src/utilities/switchFrequencyDisplay';
import NotificationEmails from '../notificationEmails';


function CustomerListDetails({auditDate, auditUser, frequency, frequencyDay, notificationEmails}) {
  return (
    <>
      <Typography variant='subtitle1' mb={1}>
        Customer List Details
      </Typography>
      <Typography variant='caption'>
        Added {auditDate} by {auditUser}
      </Typography>
      <Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', mt: 0.5, mb: 0}}>
        <Typography variant='subtitle1'>
          Transfer Schedule: <Typography sx={{textTransform: 'capitalize'}} display='inline'>{switchFreqDisplay(frequency)} {frequencyDay}</Typography>
        </Typography>
      </Box>
      <NotificationEmails emails={notificationEmails}/>
    </>
  );
}

CustomerListDetails.propTypes = {
  auditDate: PropTypes.string,
  auditUser: PropTypes.string,
  frequency: PropTypes.string,
  frequencyDay: PropTypes.string,
  notificationEmails: PropTypes.array
};

export default CustomerListDetails;
