import {useState, useEffect} from 'react';
import {Typography, Grid, TextField, styled, Box, RadioGroup, FormControlLabel, Radio, FormControl, OutlinedInput, InputAdornment, FormHelperText, IconButton} from '@mui/material';
import {Visibility, VisibilityOff, ErrorOutline} from '@mui/icons-material';
import PropTypes from 'prop-types';

import SubdirectoryField from 'src/components/FormFields/subdirectory';
import CopyButton from 'src/components/CopyButton';
import AwsFields from 'src/components/AwsFields';


const MatchPasswordWrapper = styled(Box)(
  () => `
    .MuiInputBase-root legend span {
      margin-top: -7px;
      display: block;
      opacity: 1;
    }
  `
);

function TransferLocation({formik, sftpAddressField, portNumberField, subdirectoryField, sftpUsernameField, useSshKeyField, passwordField, pgpKeyField, s3BucketArnField, iamRoleArnField, filePrefixField, matchConfig, currentCustomerConfig, transferType, weakPgpKey}) {
  const [showPassword, setShowPassword] = useState();
  const [pgpKeyInUse, setPGPKeyInUse] = useState(false);

  const showBucketHelperText = formik.touched[s3BucketArnField] && Boolean(formik.errors[s3BucketArnField]);
  const showIamHelperText = formik.touched[iamRoleArnField] && Boolean(formik.errors[iamRoleArnField]);

  useEffect(() => {
    if (currentCustomerConfig?.transfer?.sftp?.pgp?.publicKey && formik.values[pgpKeyField].length > 0) {
      if (formik.values[pgpKeyField].replace(/(\r\n|\n|\r)/gm, "").replace(/ /g, '') === currentCustomerConfig.transfer.sftp.pgp.publicKey.replace(/(\r\n|\n|\r)/gm, "").replace(/ /g, '')) {
        setPGPKeyInUse(true);
      }
      else {
        setPGPKeyInUse(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomerConfig, formik.values[pgpKeyField], formik.errors]);

  return (
    <>
      <Typography variant='h6' color='text.secondary' sx={{mb: 4, mt: 4}}>TRANSFER LOCATION & ENCRYPTION</Typography>
      {transferType === 'sftp' &&
        <>
          <Typography variant='subtitle1' color='text.primary' sx={{mb: 2, mt: 4}}>Provide credentials for LENS to use when accessing your SFTP to transfer matches.</Typography>
          <Grid container>
            <Grid item xs={9}>
              <TextField
                fullWidth
                id={sftpAddressField}
                name={sftpAddressField}
                label="SFTP Server Hostname"
                value={formik.values[sftpAddressField]}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched[sftpAddressField] && Boolean(formik.errors[sftpAddressField])}
                helperText={formik.touched[sftpAddressField] ? formik.errors[sftpAddressField] : 'Do not include https:// or subdirectory path when entering server hostname.'}
                sx={{ mb: 3, pr: 2 }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id={portNumberField}
                name={portNumberField}
                label="Port"
                value={formik.values[portNumberField]}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched[portNumberField] && Boolean(formik.errors[portNumberField])}
                helperText={formik.touched[portNumberField] ? formik.errors[portNumberField] : '5 character limit. Numeric only.'}
                sx={{ mb: 3 }}
                inputProps={{ maxLength: 5 }}
              />
            </Grid>
          </Grid>
          <SubdirectoryField formik={formik} label='Subdirectory Path (Optional)' name={subdirectoryField}/>
          <TextField
            fullWidth
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            type='text'
            id={sftpUsernameField}
            name={sftpUsernameField}
            label="SFTP Username"
            value={formik.values[sftpUsernameField]}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched[sftpUsernameField] && Boolean(formik.errors[sftpUsernameField])}
            helperText={formik.touched[sftpUsernameField] ? formik.errors[sftpUsernameField] : 'LENS will use the username to access your SFTP.'}
            sx={{ mb: 3 }}
          />
          <Typography variant="subtitle1" color="text.primary" sx={{ mb: 2 }}>How should LENS authenticate to access your virtual server instance?</Typography>
          <RadioGroup
            id={useSshKeyField}
            name={useSshKeyField}
            value={formik.values[useSshKeyField]}
            onChange={formik.handleChange}
            sx={{ mb: 3 }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Authenticate by SSH-RSA key"/>
            {(formik.values[useSshKeyField] === 'true' || formik.values[useSshKeyField] === true) &&
              <Typography variant="body2" color="text.primary" sx={{ mb: 1, mt: 1, ml: 4 }}>LENS will generate this key and use it to access your SFTP. The public key will be available after saving.</Typography>
            }
            <FormControlLabel value={false} control={<Radio />} label="Authenticate by Password"/>
          </RadioGroup>
          {formik.values[useSshKeyField] === 'false' &&
            <MatchPasswordWrapper>
              <FormControl>
                <OutlinedInput
                  fullWidth
                  id={passwordField}
                  name={passwordField}
                  label="Password"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[passwordField]}
                  error={formik.touched[passwordField] && Boolean(formik.errors[passwordField])}
                  helpertext={formik.touched[passwordField] ? formik.errors[passwordField] : ''}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{mb: 0, ml: 4, width: '595px'}}
                />
              </FormControl>
              <FormHelperText sx={{ml: 4}} error id="password-error">
                {formik.touched[passwordField] ? formik.errors[passwordField] : ''}
              </FormHelperText>
              <Typography variant="body2" sx={{ml: 4, mt: 2}}>For security reasons, the password will be hidden after saving.</Typography>
            </MatchPasswordWrapper>
          }
          <Typography variant="subtitle1" color="text.primary" sx={{mb: 2, mt: 4}}>Create and provide LENS a public PGP key to encrypt your matches.</Typography>
          <TextField
            autoComplete='new-password'
            fullWidth
            multiline
            rows={6}
            id={pgpKeyField}
            name={pgpKeyField}
            label="Public PGP Key"
            value={formik.values[pgpKeyField]}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched[pgpKeyField] && Boolean(formik.errors[pgpKeyField])}
            helperText={formik.touched[pgpKeyField] ? '' : 'Key entered must be a minimum of 2048 bits.'}
            sx={{ mb: 3 }}
          />
          {(pgpKeyInUse || weakPgpKey) &&
            <FormHelperText sx={{ mt: '-20px', mb: 4, ml: -1 }} error={true}>
              <ErrorOutline sx={{ display: 'block', float: 'left', fontSize: '15px', mr: '4px', mt: '2px' }} />
              {pgpKeyInUse ?
                'Public key entered is already in use. Provide your own public key, not the key LENS generated.' :
                'The key is too weak. Please use a RSA, ECDSA or ED25519 key that is at least 2048 bits and try again.'
              }
            </FormHelperText>
          }
          <Typography variant="body2" color="text.primary" sx={{ mb: 4 }}>The public key provided is used by LENS to encrypt your matches.</Typography>
        </>
      }
      {transferType === 'awsS3' &&
        <>
          <AwsFields
            instructionText={'Provide S3 Bucket and IAM Role ARNs for LENS to send matches.'}
            isMatchFileForm
            showTransferLocationMethod={true}
            showTooltip
            accountId={matchConfig?.transfer?.awsS3?.carrierIntegrationAccountId}
            evadataLambdaArn={matchConfig?.transfer?.awsS3?.evadataMatchLambdaArn}
            evadataLambdaRoleArn={matchConfig?.transfer?.awsS3?.evadataMatchLambdaRoleArn}
            cfTemplateFile="carrier-aws-match-files-option.yml"
            cfTemplateFileName={`lens-carrier-aws-${formik.values[filePrefixField]}-files-option.yml`}
            cfTemplateLabel="Matches Template"
          />
          <TextField
            fullWidth
            id={s3BucketArnField}
            name={s3BucketArnField}
            label="S3 Bucket ARN"
            value={formik.values[s3BucketArnField]}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={showBucketHelperText}
            helperText={showBucketHelperText ? (formik.errors[s3BucketArnField]) : 'ARN should begin with: arn:aws:s3:::'}
            sx={{ mb: '16px' }}
          />
          <SubdirectoryField formik={formik} label='Folder Path (Optional)' name={subdirectoryField}/>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>KMS Key ARN <Typography display="inline" variant="body2">(Provided by LENS):</Typography></Typography>
          <Grid container>
            <Grid item xs={1}>
              <CopyButton toolTipText="Copy ARN" textToCopy={matchConfig?.transfer?.awsS3?.evadataMatchLambdaKmsArn}/>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>{matchConfig?.transfer?.awsS3?.evadataMatchLambdaKmsArn}</Typography>
            </Grid>
            <Typography variant="body2">Use the KMS Key ARN to decrypt your matches.</Typography>
          </Grid>
          <TextField
            fullWidth
            id={iamRoleArnField}
            name={iamRoleArnField}
            label="IAM Role ARN"
            value={formik.values[iamRoleArnField]}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={showIamHelperText}
            helperText={showIamHelperText ? formik.errors[iamRoleArnField] : 'ARN should begin with: arn:aws:iam::'}
            sx={{mt: '16px', mb: 3}}
          />
          <Typography variant='body2' sx={{mb: 4}}>LENS uses the provided Role to transfer matches to the S3 bucket shown.</Typography>
        </>
      }
    </>
  );
}

TransferLocation.propTypes = {
  formik: PropTypes.object,
  sftpAddressField: PropTypes.string,
  portNumberField: PropTypes.string,
  subdirectoryField: PropTypes.string,
  sftpUsernameField: PropTypes.string,
  useSshKeyField: PropTypes.string,
  passwordField: PropTypes.string,
  pgpKeyField: PropTypes.string,
  s3BucketArnField: PropTypes.string,
  iamRoleArnField: PropTypes.string,
  filePrefixField: PropTypes.string,
  matchConfig: PropTypes.object,
  currentCustomerConfig: PropTypes.object,
  transferType: PropTypes.string,
  weakPgpKey: PropTypes.bool
};

export default TransferLocation;
