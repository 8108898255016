import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Typography, styled, Box, Paper} from '@mui/material';

import {useQuery} from 'src/hooks';
import {getEmailAddresses, getMatchFileConfigurations, getCustomerFileConfigurations, getLambdaStatus, getDeathFileConfigurations, getHolidayCalendars, getTransferFrequencies} from 'src/api';
import {useAppContext} from 'src/AppContext.js';
import OnboardingStepper from 'src/components/Stepper';
import MatchResultsFileSetupForm from './matchResultsFileSetupForm';
import MatchResultsFileSetupSummary from './MatchResultsFileSetupSummary';


const FormWrapper = styled(Box)(
  ({ theme }) => `
    .clear-icon {
      cursor: pointer;
    }
    .ssh-link {
      color: ${theme.palette.primary.main}
    }
    .recommended {
      font-size: 14px; 
      color: #666;
    }
    .MuiTypography-h6 {
      font-weight: 700;
    }
    .sources {
      text-transform: uppercase;
    }
  `
);

function MatchResultsFileSetup() {
  const { carrierConfig } = useAppContext();
  const navigate = useNavigate();
  const { lensRoles } = useAppContext();
  const [showCompletedAlert, setShowCompletedAlert] = useState(false);

  const showSummary = carrierConfig?.onBoarding?.match_file_completed;

  const {data: customerConfig, isError: customerConfigsError} = useQuery(['getCustomerConfigs', carrierConfig?.carrierId], () => getCustomerFileConfigurations(carrierConfig?.carrierId), {select: (configs) => configs?.[0]});
  const {data: deathConfig, isError: deathConfigsError} = useQuery(['getDeathConfigs', carrierConfig?.carrierId], () => getDeathFileConfigurations(carrierConfig?.carrierId), {select: (configs) => configs?.[0]});
  const {data: config, isError: configsError, refetch: refetchConfigs} = useQuery(['getMatchConfigs', carrierConfig?.carrierId], () => getMatchFileConfigurations(carrierConfig?.carrierId), {select: (configs) => configs?.[0]});
  const {data: availableEmailAddresses, isError: emailsError} = useQuery(['getEmails', carrierConfig?.carrierId], () => getEmailAddresses(carrierConfig?.carrierId));
  const {data: holidayCalendars, isError: holidayCalendarsError} = useQuery(['getHolidayCalendars', carrierConfig?.carrierId], () => getHolidayCalendars(carrierConfig?.carrierId));
  const {data: transferFrequencies, isError: frequenciesError} = useQuery(['getTransferFrequencies', carrierConfig?.carrierId], () => getTransferFrequencies(carrierConfig?.carrierId));
  const {data: evadataMatchLambdaRoleArn, isError: lambdaRoleError} = useQuery(['getLambdaRole', carrierConfig?.carrierId], () => getLambdaStatus(carrierConfig?.carrierId, {accountId: customerConfig?.transfer?.awsS3?.carrierIntegrationAccountId, copyEnabled: "true"}), {enabled: Boolean(customerConfig?.transfer?.awsS3?.carrierIntegrationAccountId), select: (lambdaConfig) => lambdaConfig.status === 'complete' ? lambdaConfig.evadataMatchLambdaRoleArn : null})

  const holidayCalendarId = holidayCalendars?.[0]?.id;
  const availableFrequencies = transferFrequencies?.scanFrequencies;
  const apiError = customerConfigsError || deathConfigsError || configsError || emailsError || holidayCalendarsError || frequenciesError || lambdaRoleError;

  useEffect(() => {
    if (lensRoles && !lensRoles?.includes('administrator')) {
      navigate('/400');
    }
  }, [lensRoles, navigate])

  return (
    <FormWrapper>
      <OnboardingStepper step={2} customerResult={customerConfig} deathResult={deathConfig} matchResult={config} />
      <Paper sx={{ maxWidth: '720px', margin: '30px auto 0' }}>
        <Box sx={{ textAlign: 'center', pt: 3, pb: 4, backgroundColor: 'background.default' }}>
          <Typography variant="overline" color="text.primary">Step 3</Typography>
          <Typography variant="h5" color="primary.text">{config ? 'Summary of Match Results' : 'Match Results Setup'}</Typography>
        </Box>
        {showSummary || config ?
          <MatchResultsFileSetupSummary
            file={config?.file}
            transfer={config?.transfer}
            holidayCalendarId={config?.holidayCalendarId}
            notificationEmails={config?.notificationEmails}
            pgp={config?.pgp}
            maxMatches={config?.maxMatches}
            sources={config?.sources}
            accountId={customerConfig?.transfer?.awsS3?.carrierIntegrationAccountId}
            categories={config?.categories}
            availableFrequencies={availableFrequencies}
            showCompletedAlert={showCompletedAlert}
            apiError={apiError}
          />
          :
          <MatchResultsFileSetupForm
            refetch={refetchConfigs}
            availableEmailAddresses={availableEmailAddresses}
            customerFilePrefix={customerConfig?.file?.prefix}
            customerFilePgp={customerConfig?.pgp?.publicKey}
            transferType={customerConfig?.transfer?.type}
            accountId={customerConfig?.transfer?.awsS3?.carrierIntegrationAccountId}
            evadataMatchLambdaRoleArn={evadataMatchLambdaRoleArn}
            holidayCalendarId={holidayCalendarId}
            availableFrequencies={availableFrequencies}
            setShowCompletedAlert={setShowCompletedAlert}
            apiError={apiError}
          />
        }
      </Paper>
    </FormWrapper>
  );
}

export default MatchResultsFileSetup;
