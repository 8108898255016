import {Typography} from '@mui/material';
import PropTypes from 'prop-types';


function PrefixName({filePrefix, fileType}) {
  return (
    <>
      <Typography variant="subtitle1">{filePrefix}</Typography>
      {fileType !== 'both' ?
        <Typography sx={{mb: 2}}>{filePrefix}_XXXXXXX.{fileType}</Typography>
        :
        <>
          <Typography>{filePrefix}_XXXXXXX.json</Typography>
          <Typography sx={{mb: 2}}>{filePrefix}_XXXXXXX.xlsx</Typography>
        </>
      }
    </>
  );
}

PrefixName.propTypes = {
  filePrefix: PropTypes.string,
  fileType: PropTypes.string
};

export default PrefixName;
