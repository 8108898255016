import {useState} from 'react';
import {Box, Typography, Button, styled} from "@mui/material";
import {ExpandMore, ExpandLess} from '@mui/icons-material';
import PropTypes from 'prop-types';


const EmailAccordionWrapper = styled(Box)(
  () => `
    button {
      font-weight: 500;
      text-transform: uppercase;
      padding-left: 0px;
      &:hover {
        background-color: transparent;
      }
    }
  `
);

function NotificationEmails({emails}) {
  const [showEmails, setShowEmails] = useState(false);
  
  return (
    <>
      <EmailAccordionWrapper>
        <Button onClick={() => setShowEmails((show) => !show)}>Notification Emails {showEmails ? <ExpandLess/> : <ExpandMore/>}</Button>
      </EmailAccordionWrapper>
      {showEmails &&
        emails.map((email) =>
          <Typography variant='subtitle1' key={email}>{email}</Typography>
        )
      }
    </>
  );
}

NotificationEmails.propTypes = {
  emails: PropTypes.array
};

export default NotificationEmails;
