import {PeopleRounded} from '@mui/icons-material';

import {useQuery} from 'src/hooks';
import {getAllDataLoadsHistory} from 'src/api';
import {useAppContext} from 'src/AppContext';
import PageHeader from 'src/components/PageHeader';
import DataLoadsBodyContent from 'src/components/DataLoadsBodyContent';
import CollapsibleContent from './collapsibleContent';


function CustomerListDataLoads() {
  const {carrierConfig} = useAppContext();
  const carrierId = carrierConfig?.carrierId;

  const {data, isLoading, isError} = useQuery('customerFileDataLoads', () => getAllDataLoadsHistory(carrierId, 'customer-files'), {enabled: Boolean(carrierId)});
  
  return (
    <>
      <PageHeader title='Customer Lists' subtitle='All recent and historical Customer List data loads.' icon={<PeopleRounded/>}/>
      <DataLoadsBodyContent data={data} isLoading={isLoading || !carrierId} isError={isError} CollapsibleContent={CollapsibleContent}/>
    </>
  );
}

export default CustomerListDataLoads;
