import PropTypes from 'prop-types';
import {FieldArray} from 'formik';
import {Button, TextField, Grid, FormHelperText} from '@mui/material';
import {AddRounded, CloseRounded} from '@mui/icons-material';


function EmailMultiEntry({formik, fieldName, availableEmailAddresses}) {
  return (
    <FieldArray
      name={fieldName}
      render={arrayHelpers => (
        <>
          {formik?.values[fieldName]?.map((_email, index) => (
            <Grid key={`container-${index}`} container>
              <Grid key={`container-item-${index}`} item sm={10}>
                <TextField
                  fullWidth
                  key={`email-entry-${index}`}
                  id={`email-${_email}`}
                  name={`${fieldName}[${index}]`}
                  label="Email Address"
                  value={formik.values[fieldName][index]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={(formik.touched[fieldName] && formik.touched[fieldName].length > 0 && formik.touched[fieldName][index]) && Boolean(formik?.errors?.[fieldName]?.[index])}
                  sx={{ mb: 3 }}
                />
                <FormHelperText error={true} sx={{ mt: '-20px', mb: 3 }}>
                  {formik?.values[fieldName]?.filter(x => x === formik?.values[fieldName][index])?.length + availableEmailAddresses?.filter(x => x === formik?.values[fieldName][index])?.length > 1 && formik?.values[fieldName][index]?.length > 0  ? 'Email entered already exists. Try a different email. ' : ''}
                  {formik?.errors?.[fieldName]?.[index] ? 'Must be a valid email' : ''}
                </FormHelperText>
              </Grid>
              <Grid item sm={2}>
                <Button key={`delete-button-${_email}`} variant="text" onClick={() => arrayHelpers.remove(index)} >Remove <CloseRounded key={`close-rounded-${index}`} /></Button>
              </Grid>
            </Grid>
          ))}
          <Button variant="text" key="add" onClick={() => arrayHelpers.push('')} disabled={!availableEmailAddresses}><AddRounded /> Add New Email</Button>
        </>
      )}
    />
  );
}

EmailMultiEntry.propTypes = {
  formik: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  availableEmailAddresses: PropTypes.array
};

export default EmailMultiEntry;
